import { React, useState } from "react";
import {
  useGetAllDapartmentQuery,
  useGetWorkLocationsQuery,
  useGetAllDesignationsQuery,
  useGetAllHolidayCalenderQuery,
  useGetAllEmployeeTypesQuery,
  useGetLatestIdQuery,
} from "../../api/addEmployeeApi";
import TextField from "../../Components/customComponents/customTextField";
import CustomRadioSelect from "../../Components/customComponents/customRadioSelection";
import Select from "../../Components/customComponents/customSelect";
import { useSelector, useDispatch } from "react-redux";
import { updateEmployeeDetails,updateErrorState } from "../../store/reducers/addEmployeeSlice";
import { updateErrorMessage } from "../../store/reducers/errorMessageSlice";
import { validateMobileNumber } from "../../utils";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "../../css/countrycodedropdown.css";


const options = [{value:"MALE",label: "Male"} , {value:"FEMALE",label:"Female"}, {value:"DO_NOT_WANT_TO_DISCLOSE",label:"Do not want to disclose"}];

const EmployeePosition = ({isInvite}) => {
  const empState = useSelector((state) => state.addEmployee.employeeDetails);
  const errorState = useSelector((state)=>state.addEmployee.errorState);
  const feildDisabled = useSelector((state) => state.addEmployee.feildsDisabled);
  const errorMessageState = useSelector((state)=>state.errorMessage.errorMessage);
  const dispatch = useDispatch();
  const [selectedOption1, setSelectedOption1] = useState("");
  const options1 = [
    { value: "option1", label: "Option 1" },
    { value: "option2", label: "Option 2" },
    { value: "option3", label: "Option 3" },
  ];
  const maritalStatusOptions = [
    { value: "SINGLE", label: "SINGLE" },
    { value: "MARRIED", label: "MARRIED" },
    { value: "DIVORCED", label: "DIVORCED" },
    { value: "SEPARATED", label: "SEPARATED" },
    { value: "WIDOWED", label: "WIDOWED" },
  ];

  const {
    data: empLatestId,
    isLoading: isLoadingEmpLatestId,
    isSuccess: isSuccessEmpLatestId,
    isError: isErrorEmpLatestId,
  } = useGetLatestIdQuery();
  const {
    data: allDeapartments,
    isLoading: isLoadingAllDepartments,
    isSuccess: isSuccessAllDepartments,
    isError: isErrorAllDepartments,
  } = useGetAllDapartmentQuery();
  const {
    data: workLocations,
    isLoading: isLoadingWorkLocations,
    isSuccess: isSuccessWorkLocation,
    isError: isErrorWorkLocations,
  } = useGetWorkLocationsQuery();
  const {
    data: allDesignation,
    isLoading: isLoadingAllDesignation,
    isSuccess: isSuccessAllDesignation,
    isError: isErrorAllDesignation,
  } = useGetAllDesignationsQuery();
  const {
    data: allHolidayCalender,
    isLoading: isLoadingAllHolidayCalender,
    isSuccess: isSuccessAllHolidayCalender,
    isError: isErrorAllHolidayCalender,
  } = useGetAllHolidayCalenderQuery();

  const {
    data: allEmployeeTypes,
    isLoading: isLoadingAllEmployeeTypes,
    isSuccess: isSuccessAllEmployeeTypes,
    isError: isErrorAllEmployeeTypes,
  } = useGetAllEmployeeTypesQuery();

  const allDeapartmentsOptions = allDeapartments
    ? allDeapartments
        .filter((department) => department.isActive)
        .map((item) => {
          return { value: item._id, label: item.departmentName };
        })
    : [];

  const designationOptions = allDesignation
    ? Object.entries(
        allDesignation[empState.professionalDetails.department] || {}
      ).map((item) => {
        return { value: item[1], label: item[0] };
      })
    : [];

  const allWorkLocationOptions = workLocations
    ? workLocations
        .filter((location) => location.isActive)
        .map((item) => {
          return { value: item._id, label: item.location };
        })
    : [];

  const holidayCalenderOptions = allHolidayCalender
    ? Object.entries(allHolidayCalender || {}).map((item) => {
        return { value: item[1], label: item[0] };
      })
    : [];

  const handleSelectChange = (e) => {
    dispatch(
      updateEmployeeDetails({ value: e.target.value, path: e.target.name })
    );
    setSelectedOption1(e.target.value);
  };

  const handlePhoneInputChange = (value, name) => {
    const phoneNumberPattern = /^\d{8,15}$/
    let isValid = phoneNumberPattern.test(value);
    dispatch(
      updateEmployeeDetails({
        value: value,
        path: `employeeDetails.personalDetails.${name}`
      })
    )
  };

  const handlePhoneInputBlurEvent = (value, name) => {
    dispatch(
      updateEmployeeDetails({
        value: value,
        path: `employeeDetails.personalDetails.${name}`,
      })
    );
    const validMobile = validateMobileNumber(value);
    if (name == "primaryContactNo") {
      dispatch(
        updateErrorState({ activeTab: 1, field: "primaryContactNumber" })
      );
    }

    if (name == "emergencyContactNo") {
      dispatch(
        updateErrorState({ activeTab: 1, field: "emergencyContactNumber" })
      );
    }
  };
  const handleChange = (e) => {
    if (
      e.target.name == "employeeDetails.personalDetails.dob" ||
      e.target.name == "employeeDetails.professionalDetails.dateofJoining" ||
      e.target.name == "employeeDetails.professionalDetails.confirmationDate"
    ) {
      dispatch(
        updateEmployeeDetails({
          value: new Date(e.target.value).toISOString(),
          path: e.target.name,
        })
      );
      // dispatch(
      //   updateEmployeeDetails({
      //     value: empLatestId.employeeId,
      //     path: "employeeDetails.professionalDetails.employeeId",
      //   })
      // );
    } else if (
      e.target.name == "employeeDetails.personalDetails.aadharNumber"
    ) {
      if (/^\d+$/.test(e.target.value) && e.target.value.length < 13) {
        dispatch(
          updateEmployeeDetails({ value: e.target.value, path: e.target.name })
        );
      }
      if (e.target.value.length == 0) {
        dispatch(
          updateEmployeeDetails({ value: e.target.value, path: e.target.name })
        );
      }
    } else {
      dispatch(
        updateEmployeeDetails({ value: e.target.value, path: e.target.name })
      );
    }
  };

  const handleOptionSelect = (e) => {
    if (e.target.value == "OTHER") {
      dispatch(
        updateEmployeeDetails({
          value: "DO_NOT_WANT_TO_DISCLOSE",
          path: e.target.name,
        })
      );
    } else {
      dispatch(
        updateEmployeeDetails({ value: e.target.value, path: e.target.name })
      );
    }

    // setSelectedOption(e.target.value);
  };

  return (
    <>
      <h1 className="text-2xl mb-10">Step 2:PERSONAL DETAILS</h1>
      <div className="flex flex-col justify-center items-end w-[40%]">
        <TextField
          name="employeeDetails.personalDetails.aadharNumber"
          value={empState.personalDetails.aadharNumber}
          label="Aadhar Number"
          type="text"
          onChange={handleChange}
          isMandatory={true}
          disabled={feildDisabled}
          error={errorState.step1.aadhar ? errorState.step1.aadhar : ""}
          onBlur={() => {
            dispatch(updateErrorState({ activeTab: 1, field: "aadhar" }));
          }}
          className = {`${empState.personalDetails.aadharNumber === "" && errorMessageState === true  && "border border-red-500"}`}
        />
        <TextField
          name="employeeDetails.personalDetails.panNumber"
          value={empState.personalDetails.panNumber}
          label="PAN Number"
          type="text"
          onChange={handleChange}
          error={errorState.step1.pan ? errorState.step1.pan : ""}
          isMandatory={true}
          disabled={feildDisabled}
          onBlur={() => {
            dispatch(updateErrorState({ activeTab: 1, field: "pan" }));
          }}
          className = {`${empState.personalDetails.panNumber === "" && errorMessageState === true  && "border border-red-500"}`}
        />
        <TextField
          name="employeeDetails.personalDetails.email"
          label="Email"
          type="email"
          onChange={handleChange}
          value={empState.personalDetails.email}
          isMandatory={true}
          disabled={isInvite}
          error={
            errorState.step1.personalEmail ? errorState.step1.personalEmail : ""
          }
          onBlur={() => {
            dispatch(updateErrorState({ activeTab: 1, field: "email" }));
          }}
          className={`${
            empState.personalDetails.email === "" &&
            errorMessageState === true &&
            "border border-red-500"
          }`}
        />
        <TextField
          name="employeeDetails.personalDetails.dob"
          label="Date of Birth"
          type="date"
          onChange={handleChange}
          disabled={feildDisabled}
          isMandatory={true}
          value={
            empState.personalDetails.dob
              ? empState.personalDetails.dob.split("T")[0]
              : ""
          }
          max={new Date().toISOString().split("T")[0]}
        />
        <Select
          name="employeeDetails.personalDetails.gender"
          label="Gender"
          options={options}
          onChange={handleOptionSelect}
          disabled={feildDisabled}
          value={empState.personalDetails.gender}
          isMandatory={true}
          className={`${
            empState.personalDetails.gender === "" &&
            errorMessageState === true &&
            "border border-red-500"
          }`}
        />
        {/* <TextField
          name="employeeDetails.personalDetails.primaryContactNo"
          label="Mobile Number"
          type="tel"
          onChange={handleChange}
          value={empState.personalDetails.primaryContactNo}
          isMandatory={true}
        /> */}
        <div className="flex ">
          <p className="text-sm font-medium text-gray-700 mr-5 my-auto">
            Mobile Number<span className="text-red-500 text-xl">*</span>
          </p>
          <div>
            <CustomPhoneInput
              name="primaryContactNo"
              value={empState.personalDetails.primaryContactNo}
              onPhoneInputChange={handlePhoneInputChange}
              phoneBlurEvent={handlePhoneInputBlurEvent}
              customPlaceholder="Mobile Number"
              disabled={feildDisabled}
              className={`${
                empState.personalDetails.primaryContactNo === "" &&
                errorMessageState === true &&
                "border border-red-500"
              }`}
            />
            {errorState.step1.primaryContactNumber && (
              <p className="text-red-500 text-sm italic">
                {errorState.step1.primaryContactNumber}
              </p>
            )}
          </div>
        </div>
        <TextField
          name="employeeDetails.personalDetails.emergencyContactName"
          label="Emergency Contact Name"
          type="text"
          onChange={handleChange}
          value={empState.personalDetails.emergencyContactName}
          isMandatory={true}
          error={
            errorState.step1.emergencyContactName
              ? errorState.step1.emergencyContactName
              : ""
          }
          disabled={feildDisabled}
          onBlur={() => {
            dispatch(
              updateErrorState({ activeTab: 1, field: "emergencyContactName" })
            );
          }}
          className={`${
            empState.personalDetails.emergencyContactName === "" &&
            errorMessageState === true &&
            "border border-red-500"
          }`}
        />
        {/* <TextField
          name="employeeDetails.personalDetails.emergencyContactNo"
          label="Emergency Contact Number"
          type="tel"
          onChange={handleChange}
          value={empState.personalDetails.emergencyContactNo}
          isMandatory={true}
        /> */}
        <div className="flex">
          <p className="text-sm font-medium text-gray-700 mr-5 my-auto">
            Emergency Contact Number{""}
            <span className="text-red-500 text-xl">*</span>
          </p>
          <div>
            <CustomPhoneInput
              name="emergencyContactNo"
              value={empState.personalDetails.emergencyContactNo}
              onPhoneInputChange={handlePhoneInputChange}
              customPlaceholder="Emergency Contact Number"
              phoneBlurEvent={handlePhoneInputBlurEvent}
              disabled={feildDisabled}
              className={`${
                empState.personalDetails.emergencyContactNo === "" &&
                errorMessageState === true &&
                "border border-red-500"
              }`}
            />
            {errorState.step1.emergencyContactNumber && (
              <p className="text-red-500 text-sm italic">
                {errorState.step1.emergencyContactNumber}
              </p>
            )}
          </div>
        </div>
        <TextField
          name="employeeDetails.personalDetails.fatherName"
          label="Father's Name"
          type="text"
          onChange={handleChange}
          disabled={feildDisabled}
          value={empState.personalDetails.fatherName}
          isMandatory={true}
          className={`${
            empState.personalDetails.fatherName === "" &&
            errorMessageState === true &&
            "border border-red-500"
          }`}
        />
         <Select
          label="Marital Status"
          name="employeeDetails.personalDetails.maritalStatus"
          options={maritalStatusOptions}
          value={empState.personalDetails.maritalStatus}
          onChange={handleSelectChange}
          disabled={feildDisabled}
          isMandatory={true}
          className={`${
            empState.personalDetails.maritalStatus === "" &&
            errorMessageState === true &&
            "border border-red-500"
          }`}
        />
        <TextField
          name="employeeDetails.personalDetails.spouseName"
          label="Spouse Name"
          type="text"
          onChange={handleChange}
          disabled={feildDisabled}
          value={empState.personalDetails.spouseName}
        />
      </div>
    </>
  );
};

export default EmployeePosition;

export const CustomPhoneInput = ({
  name,
  value,
  onPhoneInputChange,
  customPlaceholder,
  phoneBlurEvent,
  disabled,
  className
}) => {
  return (
    <PhoneInput
      inputClass="border border-gray-300 rounded-2 p-2 focus:outline-none focus:border-blue-500"
      country={"in"}
      value={value}
      onChange={(e) => onPhoneInputChange(e, name)}
      onBlur={(e)=>phoneBlurEvent(e.target.value,name)}
      placeholder={customPlaceholder}
      className={`my-3 ${className}`}
      containerStyle={{width:"15rem"}}
      inputStyle={{height:40}}
      dropdownStyle={{width:"15rem"}}
      disabled={disabled}
    />
  );
}