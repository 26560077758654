import React, { useEffect,useState,useRef } from 'react'
import { TextField, Autocomplete, Alert, Snackbar, Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';
import { useGetEmployeesListQuery } from '../api/getEmployeeList';
import { cloneDeep } from 'lodash';
import { useLazyCreateProfileQuery } from '../api/profileApi';
import { useGetAllDapartmentQuery, useGetAllDesignationsIdQuery, useGetAllHolidayCalenderQuery, useGetWorkLocationsQuery, useGetAllDesignationsQuery } from '../api/addEmployeeApi';
import EditIcon from "@mui/icons-material/Edit";
import editIcon from "../Icons/edit-employee.svg"
import { genericHeaders, serviceUrl } from '../utils/apiConfig';
import dayjs from 'dayjs';
import axios from 'axios';
import { CameraIcon,TrashIcon } from '@heroicons/react/24/outline';
import avatar from "../assets/avatar-default.svg"
import { get_file_from_s3, upload_file_to_s3 } from "../utils";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "../css/countrycodedropdown.css";
import { DocumentIcon,PlusIcon } from "@heroicons/react/24/solid"
import CustomText from "../Components/customComponents/customTextField";
import { useParams,useNavigate } from 'react-router-dom';
import { getToken } from '../services/Token';
import Breadcrumbs from './EmployeePunchTracking/BreadCrumbs';
import DocsEdit from './DocsUpload/DocsEdit';
import { toast } from 'react-toastify';

const EditEmployee = () => {
  const [id,setId] = useState("");
  const [employeeList,setEmployeeList] = useState([]); 
  const [employee,setEmployee] = useState();
  const [formData,setFormData] = useState({});
  const [department,setDepartment] = useState([]);
  const [designations,setDesignations] = useState([]);
  const [designationsId, setDesignationsId] = useState([]);
  const [location,setLocation] = useState([]);
  const avatarRef = useRef(null);
  const [holidayCalender,setHolidayCalender] = useState([]);
  const [snack, setSnack] = useState({
    open:false,
    isError:false
  });
  const [profileURL,setProfileURL] = useState('');
  const [intialEmployeeData,setIntialEmployeeData] = useState();
  const [edit,setEdit] = useState({
    employeeInfo : false,
    personalInfo : false,
    joiningInfo : false,
    currentPosition : false,
    workExperience : false,
    educationInfo : false,
    bankDetails : false,
    presentAddress : false,
    permanentAddress : false,
    emergencyContact : false,
    backgroundCheck : false,
    otherDocs : false
  });
  const adminID = getToken("Id");

  const { data: employeeListData, isSuccess: employeeListSuccess } = useGetEmployeesListQuery();
  const { data: allDeapartments, isSuccess: isSuccessAllDepartments} = useGetAllDapartmentQuery();
  const { data: workLocations, isSuccess: isSuccessWorkLocation} = useGetWorkLocationsQuery();
  const { data: allDesignation,isSuccess:isSuccessAllDesignation} = useGetAllDesignationsQuery();
  const { data: allHolidayCalender,isSuccess: isSuccessAllHolidayCalender} = useGetAllHolidayCalenderQuery();
  const { data : designationIdData,isSuccess : isSuccessDesignationsId } = useGetAllDesignationsIdQuery();
  const [ createProfile , employeeData] = useLazyCreateProfileQuery();
  
  const employeeNames = employeeList.map((employee) => employee.ismanager!=="null" && employee.name);
  let { employeeId } = useParams();
  let navigate = useNavigate();

  useEffect( () => {
    if (employeeListSuccess) {
      let newData = cloneDeep(employeeListData);
      newData = newData.filter((item) => item.id !== "")
      setEmployeeList(newData);
    }
    if(isSuccessAllDepartments){
      let newData = cloneDeep(allDeapartments);
      setDepartment(newData);
    }
    if(isSuccessWorkLocation){
      let newData = cloneDeep(workLocations);
      setLocation(newData);
    }
    if(isSuccessAllDesignation){
      let newData = cloneDeep(allDesignation);
      setDesignations(newData);
    }
    if(isSuccessAllHolidayCalender){
      let newData = cloneDeep(allHolidayCalender);
      setHolidayCalender(newData);
    }
    if(isSuccessDesignationsId){
      let newData = cloneDeep(designationIdData);
      setDesignationsId(newData);
    }
  },[employeeListData,allDeapartments,allDesignation,workLocations,allHolidayCalender,designationIdData]);
  useEffect(() => {
    setId(employeeId || '');
  }, [employeeId]);
  useEffect(() => {
    if (id !== "") { 
      let emp = id
      createProfile({ employeeId: emp });
      navigate(`/edit/${emp}`)
    }
  },[id])
  useEffect( () =>  {
    if(employeeData.isSuccess){
      let empData = cloneDeep(employeeData.data);
      setEmployee(empData);
      setIntialEmployeeData(empData);
    }
  },[employeeData])
  useEffect( () => {
    if(employee && department && location && designations && holidayCalender){ 
      let emp = cloneDeep(employee); 
      let newData = matchData(emp,department,location,designationsId,holidayCalender,employeeList);
      setFormData(newData);
    }
    const fetchProfileUrl = async () => {
      if(employee?.profilePhoto?.resourceName.trim() !== "" ){
        setProfileURL(employee?.profilePhoto?.resourceName.trim())
      }
      else if (employee?.profilePhoto?.s3Document?.fileName) {
        const url = await get_file_from_s3(employee.profilePhoto.s3Document.fileName);
        setProfileURL(url);
      }else{
        setProfileURL('');
      }
    } 
    fetchProfileUrl();

  },[department,location,designations,holidayCalender,employee,employeeList]);

    function matchData(item,departmentData,workLocationData,designationData,calendar,employeesData) {
      const matchedDepartment = departmentData.find(
        (dept) => dept._id === item?.professionalDetails?.department || ""
      );
      if (matchedDepartment) {
        item.professionalDetails.departmentName = matchedDepartment.departmentName;
      }
      const matchedWorkLocation = workLocationData.find(
        (loc) => loc._id === item?.professionalDetails?.workLocation || ""
      );
      if (matchedWorkLocation) {
        item.professionalDetails.locationName = matchedWorkLocation.location;
      }
      const matchedDesignation = designationData.find(
        (desig) => desig._id === item?.professionalDetails?.currentDesignation || ""
      );
      if (matchedDesignation) {
        item.professionalDetails.designation = matchedDesignation.designation;
      }
      const matchedManager = employeesData.find(
        (manager) => manager.id === item?.professionalDetails?.managerId  || ""
      );
      if (matchedManager) {
        item.professionalDetails.managerId = matchedManager.name;
      }else {
        item.professionalDetails.managerId = "Manager Not Found"
      }
      for (const key in calendar) {
        if (key === item?.professionalDetails?.holidayCalender || "") {
          item.professionalDetails.calender = calendar[key];
          break;
        }
      }
      return item;  
    }
    const handle_file_upload = async (new_file) => {
      const file_to_upload = new_file;

      const maxSizeInBytes = 10 * 1024 * 1024; // max 10mb

      if (file_to_upload?.size > maxSizeInBytes) {
        return
      }

      let s3Data;
      try {
        s3Data = await upload_file_to_s3(file_to_upload);
      } catch (err) {
        handleErorrSnackOpen();
        console.error(err);
      }
      let document = {
        fileName: file_to_upload.name,
        name: "Profile Photo", // You can set the name as needed
        resourceName: "", 
        uploadDate: new Date().toISOString(),
        comment: "",
        extension: file_to_upload.name.split(".").pop(),
        s3Document: s3Data?.data?.data,
      };
      return document;
    };
    const handleAvatar = () => {
      avatarRef.current.click();
    };
    const handleProfileChange = async (evt) => {
      const file = evt.target.files[0];
      if (file) {
        if (file.name.split(".").pop().toLowerCase() === "png" || file.name.split(".").pop().toLowerCase() === "jpeg" || file.name.split(".").pop().toLowerCase() === "jpg") {
          let profile = await handle_file_upload(file);
          let newData = cloneDeep(employee);
          newData.profilePhoto = profile;
          setEmployee(newData);
          handleSave(newData);
        }
      }
    };
    const handleProfileDelete = async () => {
      let newData = cloneDeep(employee);
      if (Object.keys(newData.profilePhoto.s3Document).length !== 0) {
        newData.profilePhoto = {
          fileName: "",
          name: "",
          resourceName: "",
          uploadDate: "",
          comment: "",
          extension: "",
          s3Document:{}
        };
        setEmployee(newData);
        handleSave(newData);
      }
    };
     
    const handleErorrSnackOpen = () => {
      setSnack({
        open:true,
        isError:true
      })
    }
    const handleSnackOpen = () => {
      setSnack({
        open:true,
        isError:false
      });
    };
    const handleSnackClose = () => {
      setSnack({
        open:false,
        isError:false
      });
    };  
    const handleSave = async (data) => {
      let payload;
      if(data){
        payload = data;
      }else{
        payload = {...employee}
      }
      try{
        let response = await axios.put(`${serviceUrl}/api/employee/updateEmployee?employeeId=${id}`,payload,{headers : genericHeaders()})
        if(response.status === 200){
          handleSnackOpen();
          setEdit({
            employeeInfo : false,
            personalInfo : false,
            joiningInfo : false,
            currentPosition : false,
            workExperience : false,
            educationInfo : false,
            bankDetails : false,
            presentAddress : false,
            permanentAddress : false,
            emergencyContact : false,
            backgroundCheck : false,
            otherDocs : false
          })
          setIntialEmployeeData(payload);
        }
      }catch(err){
        console.error(err);
      }
    }

    const handleDownload = async (file) => {
      let s3Url;
      if(file.resourceName.trim() !== "" ){
        s3Url = file.resourceName.trim();
      }
      else if (file.s3Document?.fileName) {
        const res = await get_file_from_s3(file?.s3Document?.fileName);
        s3Url = res;
      }
      
      if(!s3Url){
        toast.error('Failed to fetch the file');
        return //skip the download functionality if there is s3url is falsy
      }
      
      try {
        const response = await fetch(s3Url);
        const blob = await response.blob();

        const downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(blob);

        const urlParts = s3Url.split("/");
        const fullFileName = urlParts[urlParts.length - 1]; //need to change as per filename
        const [filename] = fullFileName.split("?");

        downloadLink.setAttribute("download", filename);
        document.body.appendChild(downloadLink);
        downloadLink.click();

        document.body.removeChild(downloadLink);
      } catch (error) {
        console.error("Error downloading file:", error);
      }
    };

    const handleAutocompleteChange = (event, newValue) => {
      const selectedOption = employeeList.find((option) => option.name === newValue);
      if (selectedOption) {
        setId(selectedOption.id);
      } else {
        employeeId = "";
        setId("");
        setEmployee();
      }
    };

    const handleAddWork = () => {
      let newData = cloneDeep({...employee});
      let workExp = {
        "companyName": "",
        "designation": "",
        "startDate": "",
        "endDate": "",
        "documents": []
      }
      newData.workExperience.push(workExp);
      setEmployee(newData);
    }
    const paths = [
      { name: "Employee Tracking", url: "/employeetracking" },
      { name: "Employee Details", url: `/edit/${employeeId}` }
    ];
    
    const breadCrumb = localStorage.getItem('breadCrumb');

  return (
    <div className="mt-24 mb-10 flex flex-col w-full space-y-5">
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={snack.open}
        autoHideDuration={3000}
        onClose={handleSnackClose}
      >
        <Alert severity={snack.isError ? 'error' : 'success'} variant="filled" onClose={handleSnackClose}>
          {snack.isError ? 'Failed to update' : 'Updated Successfully'}
        </Alert>
      </Snackbar>
      {!breadCrumb || breadCrumb === 'false' ?
       (<div className="w-[95%] bg-white justify-between flex rounded mx-auto">
        <div className="p-3">
          <h2 className="font-semibold text-2xl my-2">
            Start searching to see specific employee details here
          </h2>
          <p className="text-lg mt-5 font-semibold">Search Employee</p>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={employeeNames}
            size="small"
            value={
              employeeList.find((employee) => employee.id === id)?.name || ""
            }
            onChange={handleAutocompleteChange}
            className="w-72"
            renderInput={(params) => <TextField {...params} />}
          />
        </div>
        <div className="mx-5 w-44">
          <img src={editIcon} alt="edit employee icon" />
        </div>
      </div>) : <></>}
      {breadCrumb == "true" && <Breadcrumbs paths={paths} /> }
      <div>
        {employee ? (
          <div className="flex flex-col space-y-5">
            <div className="w-[95%] bg-white rounded-xl mx-auto">
              <div className="bg-blue-100 flex justify-between rounded-t-xl p-5">
                <section className="flex">
                  <div className="bg-white rounded-lg h-20 w-20">
                    <input
                      type="file"
                      id="pro-img"
                      ref={avatarRef}
                      onChange={handleProfileChange}
                      className="hidden"
                    />
                    {employee?.profilePhoto?.s3Document?.fileName ? (
                      <img
                        src={profileURL}
                        className="h-20 w-20"
                        alt='profile'
                      />
                    ) : (
                      <img src={avatar} className="h-20 w-20" alt='profile'/>
                    )}
                  </div>
                  <div className="mx-5 my-auto text-xl">
                    <p>{employee?.firstName || ""}</p>
                    <p>#{employee?.professionalDetails?.employeeId || ""}</p>
                  </div>
                </section>
                <section className="flex flex-col">
                  <div className="flex space-x-5 my-auto text-lg">
                    <button
                      onClick={handleAvatar}
                      className="flex cursor-pointer px-3 py-2 bg-blue-300 rounded-xl"
                    >
                      {<CameraIcon className="w-7 h-7 mr-2" />}Update Photo
                    </button>
                    <button
                      onClick={handleProfileDelete}
                      className="flex cursor-pointer px-3 py-2 bg-blue-300 rounded-xl"
                    >
                      {<TrashIcon className="w-6 h-6 mr-2" />}Delete
                    </button>
                  </div>
                  <div className="text-xs text-red-600 mt-2">
                    <p>Note: Only JPEG, JPG, and PNG formats are supported</p>
                  </div>
                </section>
              </div>
              <div className="p-3">
                <h3 className="text-xl my-2 p-2 border-l-2 border-purple-300 font-semibold">
                  Employee Information{' '}
                  <button
                    className={`mx-2 cursor-pointer ${
                      edit.employeeInfo ? "hidden" : ""
                    } `}
                    onClick={() =>
                      setEdit((prevEdit) => ({
                        ...prevEdit,
                        employeeInfo: true,
                      }))
                    }
                  >
                    {<EditIcon />}
                  </button>
                </h3>
                {edit.employeeInfo ? (
                  <EmployeeInformation
                    data={employee}
                    setEmployee={setEmployee}
                    setEdit={setEdit}
                    save={() => handleSave()}
                    oldData={intialEmployeeData}
                  />
                ) : (
                  <div className="grid grid-cols-4 p-2 gap-2">
                    <div>
                      <h4 className="text-slate-600">Title</h4>
                      <p className="my-1 text-lg">
                        {employee?.personalDetails?.title || "-"}
                      </p>
                    </div>
                    <div>
                      <h4 className="text-slate-600">First Name</h4>
                      <p className="my-1 text-lg">{employee?.firstName || "-"}</p>
                    </div>
                    <div>
                      <h4 className="text-slate-600">LastName</h4>
                      <p className="my-1 text-lg">{employee?.lastName || "-"}</p>
                    </div>
                    <div>
                      <h4 className="text-slate-600">Gender</h4>
                      <p className="my-1 text-lg">
                      {employee?.personalDetails?.gender === "DO_NOT_WANT_TO_DISCLOSE" ? "Do not want to disclose" : (employee?.personalDetails?.gender || "-")}
                      </p>
                    </div>
                    <div>
                      <h4 className="text-slate-600">Mobile</h4>
                      <p className="my-1 text-lg">
                        {employee?.personalDetails?.primaryContactNo || "-"}
                      </p>
                    </div>
                    <div>
                      <h4 className="text-slate-600">Email</h4>
                      <p className="my-1 text-lg">
                        {employee?.professionalDetails?.companyEmail || "-"}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="w-[95%] bg-white rounded-xl mx-auto p-3">
              <div>
                <h3 className="text-xl my-2 p-2 border-l-2 border-purple-300 font-semibold">
                  Personal Information{' '}
                  <button
                    className={`mx-2 cursor-pointer ${
                      edit.personalInfo ? "hidden" : ""
                    }`}
                    onClick={() =>
                      setEdit((prevEdit) => ({
                        ...prevEdit,
                        personalInfo: true,
                      }))
                    }
                  >
                    {<EditIcon />}
                  </button>
                </h3>
                {edit.personalInfo ? (
                  <PersonalInformation
                    data={employee}
                    setEmployee={setEmployee}
                    setEdit={setEdit}
                    save={() => handleSave()}
                    oldData={intialEmployeeData}
                  />
                ) : (
                  <div className="grid grid-cols-4 p-2 gap-2">
                    <div>
                      <h4 className="text-slate-600">DOB</h4>
                      <p className="my-1 text-lg">
                        {employee?.personalDetails?.dob
                          ? dayjs(employee?.personalDetails?.dob).format(
                              "DD/MM/YYYY"
                            )
                          : "-"}
                      </p>
                    </div>
                    <div>
                      <h4 className="text-slate-600">Blood Group</h4>
                      <p className="my-1 text-lg">
                        {employee?.personalDetails?.bloodGroup || "-"}
                      </p>
                    </div>
                    <div>
                      <h4 className="text-slate-600">Father Name</h4>
                      <p className="my-1 text-lg">
                        {employee?.personalDetails?.fatherName || "-"}
                      </p>
                    </div>
                    <div>
                      <h4 className="text-slate-600">Marital Status</h4>
                      <p className="my-1 text-lg">
                        {employee?.personalDetails?.maritalStatus || "-"}
                      </p>
                    </div>
                    <div>
                      <h4 className="text-slate-600">Spouse Name</h4>
                      <p className="my-1 text-lg">
                        {employee?.personalDetails?.spouseName || "-"}
                      </p>
                    </div>
                    <div>
                      <h4 className="text-slate-600">Nationality</h4>
                      <p className="my-1 text-lg">
                        {employee?.personalDetails?.nationality || "-"}
                      </p>
                    </div>
                    <div>
                      <h4 className="text-slate-600">International Employee</h4>
                      <p className="my-1 text-lg">
                        {employee?.personalDetails?.internationalEmployee !==
                        undefined
                          ? employee?.personalDetails?.internationalEmployee
                            ? "Yes"
                            : "No"
                          : "-"}
                      </p>
                    </div>
                    <div>
                      <h4 className="text-slate-600">Physically Challenged</h4>
                      <p className="my-1 text-lg">
                        {employee?.personalDetails?.physicallyDisabled !==
                        undefined
                          ? employee.personalDetails.physicallyDisabled
                            ? "Yes"
                            : "No"
                          : "-"}
                      </p>
                    </div>
                    <div>
                      <h4 className="text-slate-600">Personal Email</h4>
                      <p className="my-1 text-lg">
                        {employee?.personalDetails?.email || "-"}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="w-[95%] bg-white rounded-xl mx-auto p-3">
              <div>
                <h3 className="text-xl my-2 p-2 border-l-2 border-purple-300 font-semibold">
                  Joining Details{' '}
                  <button
                    className={`mx-2 cursor-pointer ${
                      edit.joiningInfo ? "hidden" : ""
                    }`}
                    onClick={() =>
                      setEdit((prevEdit) => ({
                        ...prevEdit,
                        joiningInfo: true,
                      }))
                    }
                  >
                    {<EditIcon />}
                  </button>
                </h3>
                {edit.joiningInfo ? (
                  <JoiningInformation
                    data={employee}
                    setEmployee={setEmployee}
                    setEdit={setEdit}
                    save={() => handleSave()}
                    oldData={intialEmployeeData}
                  />
                ) : (
                  <div className="grid grid-cols-4 p-2 gap-2">
                    <div>
                      <h4 className="text-slate-600">Joined On</h4>
                      <p className="my-1 text-lg">
                        {employee?.professionalDetails?.dateofJoining
                          ? dayjs(
                              employee?.professionalDetails?.dateofJoining
                            ).format("DD MMM YYYY")
                          : "-"}
                      </p>
                    </div>
                    <div>
                      <h4 className="text-slate-600">Confirmed Date</h4>
                      <p className="my-1 text-lg">
                        {employee?.professionalDetails?.confirmationDate
                          ? dayjs(
                              employee?.professionalDetails?.confirmationDate
                            ).format("DD MMM YYYY")
                          : "-"}
                      </p>
                    </div>
                    <div>
                      <h4 className="text-slate-600">Status</h4>
                      <p className="my-1 text-lg">
                        {employee?.professionalDetails?.status || "-"}
                      </p>
                    </div>
                    <div>
                      <h4 className="text-slate-600">Probation Period</h4>
                      <p className="my-1 text-lg">
                        {employee?.professionalDetails?.probationPeriod || "-"}
                      </p>
                    </div>
                    <div>
                      <h4 className="text-slate-600">Notice Period</h4>
                      <p className="my-1 text-lg">
                        {employee?.professionalDetails?.noticePeriod || "-"}
                      </p>
                    </div>
                    <div>
                      <h4 className="text-slate-600">
                        Current Company Experience
                      </h4>
                      <p className="my-1 text-lg">
                        {employee?.professionalDetails
                          ?.currentCompanyExperience || "-"}
                      </p>
                    </div>
                    <div>
                      <h4 className="text-slate-600">Total Experience</h4>
                      <p className="my-1 text-lg">
                        {employee?.professionalDetails?.totalExperience || "-"}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="w-[95%] bg-white rounded-xl mx-auto p-3">
              <div>
                <h3 className="text-xl my-2 p-2 border-l-2 border-purple-300 font-semibold">
                  Current Position{' '}
                  <button
                    className={`mx-2 cursor-pointer ${
                      edit.currentPosition ? "hidden" : ""
                    }`}
                    onClick={() =>
                      setEdit((prevEdit) => ({
                        ...prevEdit,
                        currentPosition: true,
                      }))
                    }
                  >
                    {<EditIcon />}
                  </button>
                </h3>
                {edit.currentPosition ? (
                  <CurrentPositionInformation
                    data={employee}
                    setEmployee={setEmployee}
                    setEdit={setEdit}
                    calendar={holidayCalender}
                    department={department}
                    location={location}
                    designations={designations}
                    employeeList={employeeList}
                    save={() => handleSave()}
                    oldData={intialEmployeeData}
                    employeeId={employeeId}
                  />
                ) : (
                  <div className="grid grid-cols-4 p-2 gap-2">
                    <div>
                      <h4 className="text-slate-600">Designation</h4>
                      <p className="my-1 text-lg">
                        {formData?.professionalDetails?.designation || "-"}
                      </p>
                    </div>
                    <div>
                      <h4 className="text-slate-600">Location</h4>
                      <p className="my-1 text-lg">
                        {formData?.professionalDetails?.locationName || "-"}
                      </p>
                    </div>
                    <div>
                      <h4 className="text-slate-600">Department</h4>
                      <p className="my-1 text-lg">
                        {formData?.professionalDetails?.departmentName || "-"}
                      </p>
                    </div>
                    <div>
                      <h4 className="text-slate-600">Holiday Calender</h4>
                      <p className="my-1 text-lg">
                        {formData?.professionalDetails?.calender || "-"}
                      </p>
                    </div>
                    <div>
                      <h4 className="text-slate-600">Shift</h4>
                      <p className="my-1 text-lg">
                        {formData?.professionalDetails?.shift || "-"}
                      </p>
                    </div>
                    <div>
                      <h4 className="text-slate-600">Reporting Manager</h4>
                      <p className="my-1 text-lg">
                        {formData?.professionalDetails?.managerId || "-"}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="w-[95%] bg-white rounded-xl mx-auto p-3">
              <div>
                <h3 className="flex text-xl my-2 p-2 border-l-2 border-purple-300 font-semibold">
                  {" "}
                  Work Experience
                  <button
                    className={`mx-2 cursor-pointer ${
                      edit.workExperience ? "hidden" : ""
                    }`}
                    onClick={() =>
                      setEdit((prevEdit) => ({
                        ...prevEdit,
                        workExperience: true,
                      }))
                    }
                  >
                    {<EditIcon />}
                  </button>
                  <button
                    className={`mx-2 cursor-pointer  ${
                      edit.workExperience ? "" : "hidden"
                    }`}
                    onClick={handleAddWork}
                  >
                    {<PlusIcon className='w-7 h-7' />}
                  </button>
                </h3>
                {edit.workExperience ? (
                  <WorkExperienceInformation
                    data={employee}
                    setEdit={setEdit}
                    save={() => handleSave()}
                    oldData={intialEmployeeData}
                    setEmployee={setEmployee}
                    handleDownload={handleDownload}
                />
                ) : (
                  employee?.workExperience.map((item,i) => (
                    <div key={i + 1} className={i === 0 ? '' : 'border-t-2 border-slate-300'}>
                      <h3 className="font-medium text-xl mt-2">Company {i + 1}</h3>
                      <div className="grid grid-cols-4 p-2 gap-2">
                        <div>
                          <h4 className="text-slate-600">Company Name</h4>
                          <p className="my-1 text-lg">
                            {item.companyName || "-"}
                          </p>
                        </div>
                        <div>
                          <h4 className="text-slate-600">Designation</h4>
                          <p className="my-1 text-lg">
                            {item.designation || "-"}
                          </p>
                        </div>
                        <div>
                          <h4 className="text-slate-600">Start Date</h4>
                          <p className="my-1 text-lg">
                            {item.startDate
                              ? dayjs(item.startDate).format("DD MMM YYYY")
                              : "-"}
                          </p>
                        </div>
                        <div>
                          <h4 className="text-slate-600">End Date</h4>
                          <p className="my-1 text-lg">
                            {item.endDate
                              ? dayjs(item.endDate).format("DD MMM YYYY")
                              : "-"}
                          </p>
                        </div>
                      </div>
                      <div className='p-2'>
                        <h4 className="text-slate-600">Uploaded Documents</h4>
                        <ul>
                          {item.documents.length > 0 ? item.documents.map((file,index) => (<li key={index + 1}>{file.name}</li>)) : "-"}
                        </ul>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>

            <div className="w-[95%] bg-white rounded-xl mx-auto p-3">
              <div>
                <h3 className="flex text-xl my-2 p-2 border-l-2 border-purple-300 font-semibold">
                  {" "}
                  Documents
                  <button
                    className={`mx-2 cursor-pointer ${
                      edit.otherDocs ? "hidden" : ""
                    }`}
                    onClick={() =>
                      setEdit((prevEdit) => ({
                        ...prevEdit,
                        otherDocs : true,
                      }))
                    }
                  >
                    {<EditIcon />}
                  </button>
                </h3>
                {edit.otherDocs ? (
                  <DocsInformation
                    data={employee}
                    setEdit={setEdit}
                    save={() => handleSave()}
                    oldData={intialEmployeeData}
                    setEmployee={setEmployee}
                    handleDownload={handleDownload}
                />
                ) : (      
                    <div className='p-2'>
                      <h4 className="text-slate-600">Uploaded Documents</h4>
                      <ul>
                        {formData?.documents?.length > 0 ? formData?.documents.map((file,i) => (<li key={i + 1}>{file.name}</li>)) : "-"}
                      </ul>
                    </div>
                )}
              </div>
            </div>

            <div className="w-[95%] bg-white rounded-xl mx-auto p-3">
              <div>
                <h3 className="text-xl my-2 p-2 border-l-2 border-purple-300 font-semibold">
                  Bank Details{' '}
                  <button
                    className={`mx-2 cursor-pointer ${
                      edit.bankDetails ? "hidden" : ""
                    }`}
                    onClick={() =>
                      setEdit((prevEdit) => ({
                        ...prevEdit,
                        bankDetails: true,
                      }))
                    }
                  >
                    {<EditIcon />}
                  </button>
                </h3>
                {edit.bankDetails ? (
                  <BankDetailsInformation
                    data={employee}
                    setEmployee={setEmployee}
                    setEdit={setEdit}
                    save={() => handleSave()}
                    oldData={intialEmployeeData}
                    handleDownload={handleDownload}
                  />
                ) : (
                  <>
                  <div className="grid grid-cols-4 p-2 gap-2">
                    <div>
                      <h4 className="text-slate-600">Bank Name</h4>
                      <p className="my-1 text-lg">
                        {employee?.bankDetails?.bankName || "-"}
                      </p>
                    </div>
                    <div>
                      <h4 className="text-slate-600">Account Number</h4>
                      <p className="my-1 text-lg">
                        {employee?.bankDetails?.accountNo || "-"}
                      </p>
                    </div>
                    <div>
                      <h4 className="text-slate-600">Branch Name</h4>
                      <p className="my-1 text-lg">
                        {employee?.bankDetails?.branchName || "-"}
                      </p>
                    </div>
                    <div>
                      <h4 className="text-slate-600">IFSC code</h4>
                      <p className="my-1 text-lg">
                        {employee?.bankDetails?.ifsccode || "-"}
                      </p>
                    </div>
                  </div>
                  <div className='p-2'>
                      <h4 className="text-slate-600">Uploaded Documents</h4>
                      <ul>
                        {formData?.bankDetails?.documents?.length > 0 ? formData?.bankDetails?.documents?.map((file,i) => (<li key={i + 1}>{file.name}</li>)) : "-"}
                      </ul>
                  </div>
                  </>
                )}
              </div>
            </div>

            <div className="w-[95%] bg-white rounded-xl mx-auto p-3">
              <div>
                <h3 className="text-xl my-2 p-2 border-l-2 border-purple-300 font-semibold">
                  Education{''}
                  <button
                    className={`mx-2 cursor-pointer ${
                      edit.educationInfo ? "hidden" : ""
                    }`}
                    onClick={() =>
                      setEdit((prevEdit) => ({
                        ...prevEdit,
                        educationInfo: true,
                      }))
                    }
                  >
                    {<EditIcon />}
                  </button>
                </h3>
                {edit.educationInfo ? (
                  <EducationInformation
                    data={employee}
                    setEmployee={setEmployee}
                    setEdit={setEdit}
                    save={() => handleSave()}
                    oldData={intialEmployeeData}
                    handleDownload={handleDownload}
                  />
                ) : (
                  <>
                  <div className="grid grid-cols-4 p-2 gap-2">
                    <div>
                      <h4 className="text-slate-600">Institute</h4>
                      <p className="my-1 text-lg">
                        {employee?.academicDetails?.institute || "-"}
                      </p>
                    </div>
                    <div>
                      <h4 className="text-slate-600">Grade</h4>
                      <p className="my-1 text-lg">
                        {employee?.academicDetails?.grade || "-"}
                      </p>
                    </div>
                    <div>
                      <h4 className="text-slate-600">From Date</h4>
                      <p className="my-1 text-lg">
                        {employee?.academicDetails?.fromDate
                          ? dayjs(employee?.academicDetails?.fromDate).format(
                              "DD MMM YYYY"
                            )
                          : "-"}
                      </p>
                    </div>
                    <div>
                      <h4 className="text-slate-600">To Date</h4>
                      <p className="my-1 text-lg">
                        {employee?.academicDetails?.fromDate
                          ? dayjs(employee?.academicDetails?.toDate).format(
                              "DD MMM YYYY"
                            )
                          : "-"}
                      </p>
                    </div>
                    <div>
                      <h4 className="text-slate-600">Qualification</h4>
                      <p className="my-1 text-lg">
                        {employee?.academicDetails?.qualification || "-"}
                      </p>
                    </div>
                    <div>
                      <h4 className="text-slate-600">Remarks</h4>
                      <p className="my-1 text-lg">
                        {employee?.academicDetails?.remarks || "-"}
                      </p>
                    </div>
                  </div>
                  <div className='p-2'>
                      <h4 className="text-slate-600">Uploaded Documents</h4>
                      <ul>
                        {formData?.academicDetails?.documents?.length > 0 ? formData?.academicDetails?.documents?.map((file,i) => (<li key={i + 1}>{file.name}</li>)) : "-"}
                      </ul>
                  </div>
                  </>
                )}
              </div>
            </div>

            <div className="w-[95%] bg-white rounded-xl mx-auto p-3">
              <div>
                <h3 className="text-xl my-2 p-2 border-l-2 border-purple-300 font-semibold">
                  Present Address{''}
                  <button
                    className={`mx-2 cursor-pointer ${
                      edit.presentAddress ? "hidden" : ""
                    }`}
                    onClick={() =>
                      setEdit((prevEdit) => ({
                        ...prevEdit,
                        presentAddress: true,
                      }))
                    }
                  >
                    {<EditIcon />}
                  </button>
                </h3>
                {edit.presentAddress ? (
                  <PresentAddressInformation
                    data={employee}
                    setEmployee={setEmployee}
                    setEdit={setEdit}
                    save={() => handleSave()}
                    oldData={intialEmployeeData}
                  />
                ) : (
                  <div className="grid grid-cols-4 p-2 gap-2">
                    <div>
                      <h4 className="text-slate-600">Name</h4>
                      <p className="my-1 text-lg">
                        {employee?.currentAddress?.name || "-"}
                      </p>
                    </div>
                    <div>
                      <h4 className="text-slate-600">Address</h4>
                      <p className="my-1 text-lg">
                        {employee?.currentAddress?.address || "-"}
                      </p>
                    </div>
                    <div>
                      <h4 className="text-slate-600">City</h4>
                      <p className="my-1 text-lg">
                        {employee?.currentAddress?.city || "-"}
                      </p>
                    </div>
                    <div>
                      <h4 className="text-slate-600">State</h4>
                      <p className="my-1 text-lg">
                        {employee?.currentAddress?.state || "-"}
                      </p>
                    </div>
                    <div>
                      <h4 className="text-slate-600">Country</h4>
                      <p className="my-1 text-lg">
                        {employee?.currentAddress?.country || "-"}
                      </p>
                    </div>
                    <div>
                      <h4 className="text-slate-600">Pincode</h4>
                      <p className="my-1 text-lg">
                        {employee?.currentAddress?.pincode || "-"}
                      </p>
                    </div>
                    <div>
                      <h4 className="text-slate-600">Phone</h4>
                      <p className="my-1 text-lg">
                        {employee?.currentAddress?.phone || "-"}
                      </p>
                    </div>
                    <div>
                      <h4 className="text-slate-600">Fax</h4>
                      <p className="my-1 text-lg">
                        {employee?.currentAddress?.fax || "-"}
                      </p>
                    </div>
                    <div>
                      <h4 className="text-slate-600">Mobile Number</h4>
                      <p className="my-1 text-lg">
                        {employee?.currentAddress?.mobileNumber || "-"}
                      </p>
                    </div>
                    <div>
                      <h4 className="text-slate-600">Email</h4>
                      <p className="my-1 text-lg">
                        {employee?.currentAddress?.email || "-"}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="w-[95%] bg-white rounded-xl mx-auto p-3">
              <div>
                <h3 className="text-xl my-2 p-2 border-l-2 border-purple-300 font-semibold">
                  Permanent Address{''}
                  <button
                    className={`mx-2 cursor-pointer ${
                      edit.permanentAddress ? "hidden" : ""
                    }`}
                    onClick={() =>
                      setEdit((prevEdit) => ({
                        ...prevEdit,
                        permanentAddress: true,
                      }))
                    }
                  >
                    {<EditIcon />}
                  </button>
                </h3>
                {edit.permanentAddress ? (
                  <PermanentAddressInformation
                    data={employee}
                    setEmployee={setEmployee}
                    setEdit={setEdit}
                    save={() => handleSave()}
                    oldData={intialEmployeeData}
                  />
                ) : (
                  <div className="grid grid-cols-4 p-2 gap-2">
                    <div>
                      <h4 className="text-slate-600">Name</h4>
                      <p className="my-1 text-lg">
                        {employee?.permanentAddress?.name || "-"}
                      </p>
                    </div>
                    <div>
                      <h4 className="text-slate-600">Address</h4>
                      <p className="my-1 text-lg">
                        {employee?.permanentAddress?.address || "-"}
                      </p>
                    </div>
                    <div>
                      <h4 className="text-slate-600">City</h4>
                      <p className="my-1 text-lg">
                        {employee?.permanentAddress?.city || "-"}
                      </p>
                    </div>
                    <div>
                      <h4 className="text-slate-600">State</h4>
                      <p className="my-1 text-lg">
                        {employee?.permanentAddress?.state || "-"}
                      </p>
                    </div>
                    <div>
                      <h4 className="text-slate-600">Country</h4>
                      <p className="my-1 text-lg">
                        {employee?.permanentAddress?.country || "-"}
                      </p>
                    </div>
                    <div>
                      <h4 className="text-slate-600">Pincode</h4>
                      <p className="my-1 text-lg">
                        {employee?.permanentAddress?.pincode || "-"}
                      </p>
                    </div>
                    <div>
                      <h4 className="text-slate-600">Phone</h4>
                      <p className="my-1 text-lg">
                        {employee?.permanentAddress?.phone || "-"}
                      </p>
                    </div>
                    <div>
                      <h4 className="text-slate-600">Fax</h4>
                      <p className="my-1 text-lg">
                        {employee?.permanentAddress?.fax || "-"}
                      </p>
                    </div>
                    <div>
                      <h4 className="text-slate-600">Mobile Number</h4>
                      <p className="my-1 text-lg">
                        {employee?.permanentAddress?.mobileNumber || "-"}
                      </p>
                    </div>
                    <div>
                      <h4 className="text-slate-600">Email</h4>
                      <p className="my-1 text-lg">
                        {employee?.permanentAddress?.email || "-"}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="w-[95%] bg-white rounded-xl mx-auto p-3">
              <div>
                <h3 className="text-xl my-2 p-2 border-l-2 border-purple-300 font-semibold">
                  Emergency Contact{''}
                  <button
                    className={`mx-2 cursor-pointer ${
                      edit.emergencyContact ? "hidden" : ""
                    }`}
                    onClick={() =>
                      setEdit((prevEdit) => ({
                        ...prevEdit,
                        emergencyContact: true,
                      }))
                    }
                  >
                    {<EditIcon />}
                  </button>
                </h3>
                {edit.emergencyContact ? (
                  <EmergencyContactInformation
                    data={employee}
                    setEmployee={setEmployee}
                    setEdit={setEdit}
                    save={() => handleSave()}
                    oldData={intialEmployeeData}
                  />
                ) : (
                  <div className="grid grid-cols-4 p-2 gap-2">
                    <div>
                      <h4 className="text-slate-600">Name</h4>
                      <p className="my-1 text-lg">
                        {employee?.emergencyContactAddress?.name || "-"}
                      </p>
                    </div>
                    <div>
                      <h4 className="text-slate-600">Relationship</h4>
                      <p className="my-1 text-lg">
                        {employee?.emergencyContactAddress?.relationship || "-"}
                      </p>
                    </div>
                    <div>
                      <h4 className="text-slate-600">Address</h4>
                      <p className="my-1 text-lg">
                        {employee?.emergencyContactAddress?.address || "-"}
                      </p>
                    </div>
                    <div>
                      <h4 className="text-slate-600">City</h4>
                      <p className="my-1 text-lg">
                        {employee?.emergencyContactAddress?.city || "-"}
                      </p>
                    </div>
                    <div>
                      <h4 className="text-slate-600">State</h4>
                      <p className="my-1 text-lg">
                        {employee?.emergencyContactAddress?.state || "-"}
                      </p>
                    </div>
                    <div>
                      <h4 className="text-slate-600">Country</h4>
                      <p className="my-1 text-lg">
                        {employee?.emergencyContactAddress?.country || "-"}
                      </p>
                    </div>
                    <div>
                      <h4 className="text-slate-600">Pincode</h4>
                      <p className="my-1 text-lg">
                        {employee?.emergencyContactAddress?.pincode || "-"}
                      </p>
                    </div>
                    <div>
                      <h4 className="text-slate-600">Phone</h4>
                      <p className="my-1 text-lg">
                        {employee?.emergencyContactAddress?.phone || "-"}
                      </p>
                    </div>
                    <div>
                      <h4 className="text-slate-600">Fax</h4>
                      <p className="my-1 text-lg">
                        {employee?.emergencyContactAddress?.fax || "-"}
                      </p>
                    </div>
                    <div>
                      <h4 className="text-slate-600">Mobile Number</h4>
                      <p className="my-1 text-lg">
                        {employee?.emergencyContactAddress?.mobileNumber || "-"}
                      </p>
                    </div>
                    <div>
                      <h4 className="text-slate-600">Email</h4>
                      <p className="my-1 text-lg">
                        {employee?.emergencyContactAddress?.email || "-"}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="w-[95%] bg-white rounded-xl mx-auto p-3">
              <div>
                <h3 className="text-xl my-2 p-2 border-l-2 border-purple-300 font-semibold">
                  Background Check{''}
                  <button
                    className={`mx-2 cursor-pointer ${
                      edit.backgroundCheck ? "hidden" : ""
                    }`}
                    onClick={() =>
                      setEdit((prevEdit) => ({
                        ...prevEdit,
                        backgroundCheck: true,
                      }))
                    }
                  >
                    {<EditIcon />}
                  </button>
                </h3>
                {edit.backgroundCheck ? (
                  <BackgroundCheckInformation
                    data={employee}
                    setEmployee={setEmployee}
                    setEdit={setEdit}
                    save={() => handleSave()}
                    oldData={intialEmployeeData}
                  />
                ) : (
                  <div className="grid grid-cols-4 p-2 gap-2">
                    <div>
                      <h4 className="text-slate-600">Verification Status</h4>
                      <p className="my-1 text-lg">
                        {employee?.backgroundVerification?.verificationStatus ||
                          "-"}
                      </p>
                    </div>
                    <div>
                      <h4 className="text-slate-600">
                        Verification Completed on
                      </h4>
                      <p className="my-1 text-lg">
                        {employee?.backgroundVerification
                          ?.verificationCOmpletedOn
                          ? dayjs(
                              employee?.backgroundVerification
                                ?.verificationCOmpletedOn
                            ).format("DD MMM YYYY")
                          : "-"}
                      </p>
                    </div>
                    <div>
                      <h4 className="text-slate-600">Agency Name</h4>
                      <p className="my-1 text-lg">
                        {employee?.backgroundVerification?.completedby || "-"}
                      </p>
                    </div>
                    <div>
                      <h4 className="text-slate-600">Remarks</h4>
                      <p className="my-1 text-lg">
                        {employee?.backgroundVerification?.remarks || "-"}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default EditEmployee

export const EmployeeInformation = (props) => {
  const [error,setError] = useState({email:"",phone:"",})
  let formData = cloneDeep(props.data);
  const regex = /^[a-zA-Z0-9\s]+$/
  const handleInputChange = (e) =>{
    let newData = formData;
    if( e.target.name === "firstName" || e.target.name === "lastName"){
      if (regex.test(e.target.value) || e.target.value === "") {
        newData[e.target.name] = e.target.value;
      }
    }else if( e.target.name === "companyEmail"){
        const email = e.target.value;
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        let isValid = emailRegex.test(email);
        if(isValid === false){
          setError((prevError) => ({ ...prevError, email : "Enter valid email" }));
        }else{
          setError((prevError) => ({ ...prevError, email : "" }));
        }
        newData.professionalDetails.companyEmail = e.target.value;
    }else {
      newData.personalDetails[e.target.name] = e.target.value;
    }
    props.setEmployee(newData);
  }
  const handlePhoneInputChange = (value, name) => {
    let newData = formData;
    const phoneNumberPattern = /^\d{12,15}$/
    let isValid = phoneNumberPattern.test(value);
    if(isValid === false){
      setError((prevError) => ({ ...prevError, phone: "Invalid phone number" }));
    }else{
      setError((prevError) => ({ ...prevError, phone: "" }));
    }
    newData.personalDetails[name] = value;
    props.setEmployee(newData);
  };
  return (
    <>
      <div className="grid grid-cols-4 p-2 gap-2">
        <div>
          <h4 className="text-slate-600">Title</h4>
          <select
            name="title"
            value={formData?.personalDetails?.title || ""}
            className="border rounded-md my-2 px-2 py-1 w-[55%]"
            onChange={handleInputChange}
          >
            <option value="MR">MR</option>
            <option value="MRS">MRS</option>
            <option value="MISS">Miss</option>
            <option value="DR">DR</option>
          </select>
        </div>
        <div>
          <h4 className="text-slate-600">First Name</h4>
          <input
            type="text"
            name="firstName"
            value={formData?.firstName || ""}
            className="border rounded-md my-2 px-2 py-1"
            onChange={handleInputChange}
          />
        </div>
        <div>
          <h4 className="text-slate-600">Last Name</h4>
          <input
            type="text"
            name="lastName"
            value={formData?.lastName || ""}
            className="border rounded-md my-2 px-2 py-1"
            onChange={handleInputChange}
          />
        </div>
        <div>
          <h4 className="text-slate-600">Gender</h4>
          <select
            name="gender"
            value={formData?.personalDetails?.gender || ""}
            className="border rounded-md my-2 px-2 py-1 w-[55%]"
            onChange={handleInputChange}
          >
            <option value="MALE">MALE</option>
            <option value="FEMALE">FEMALE</option>
            <option value="DO_NOT_WANT_TO_DISCLOSE">Do not want to disclose</option>
          </select>
        </div>
        <div>
          <h4 className="text-slate-600">Mobile</h4>
          {/* <input
            type="text"
            name="primaryContactNo"
            value={formData?.personalDetails?.primaryContactNo || ""}
            className="border rounded-md my-2 px-2 py-1"
            onChange={handleInputChange}
          /> */}
          <div className="w-[55%]">
            <CustomPhoneInput
              name="primaryContactNo"
              value={formData?.personalDetails?.primaryContactNo || ""}
              onPhoneInputChange={handlePhoneInputChange}
              customPlaceholder="Primary Contact Number *"
            />
          </div>
          {error.phone !== "" && (
            <p className="text-red-500 text-xs">{error.phone}</p>
          )}
        </div>
        <div>
          <h4 className="text-slate-600">Email</h4>
          <input
            type="text"
            name="companyEmail"
            value={formData?.professionalDetails?.companyEmail || ""}
            className="border rounded-md my-2 px-2 py-1"
            onChange={handleInputChange}
          />
          {error.email !== "" && (
            <p className="text-red-500 text-xs">{error.email}</p>
          )}
        </div>
      </div>
      <div className="mx-10 flex items-end justify-end space-x-5">
        <button
          className="border px-2  rounded-md"
          onClick={props.save}
          disabled={error.email !== "" || error.phone !== ""}
        >
          Save
        </button>
        <button
          className="border px-2  rounded-md"
          onClick={() => {
            props.setEdit((prevEdit) => ({ ...prevEdit, employeeInfo: false }));
            props.setEmployee(props.oldData);
          }}
        >
          Cancel
        </button>
      </div>
    </>
  );
}

export const PersonalInformation = (props) => {
  const [error,setError] = useState({email : "",});
  let formData = cloneDeep(props.data);
  const regex = /^[a-zA-Z\s]+$/
  const handleInputChange = (e) => {
    let newData = formData;
    if(e.target.name === "email"){
      const email = e.target.value;
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      let isValid = emailRegex.test(email);
      if(isValid === false){
        setError({email : "Enter valid email",});
      }else{
        setError({email : "",});
      }
      newData.personalDetails.email = e.target.value;
    }else if(e.target.name === "dob"){
      let date = new Date(e.target.value).toISOString();
      newData.personalDetails.dob = date;
    }else if(e.target.name === "internationalEmployee" || e.target.name === "physicallyDisabled"){
      let value;
      e.target.value === "true" ? value = true : value = false;
      newData.personalDetails[e.target.name] = value;      
    }else if(e.target.name === "bloodGroup"){
       if(/^[a-zA-Z+-\s]+$/.test(e.target.value) || e.target.value === ""){
         newData.personalDetails[e.target.name] = e.target.value;
       }
    }else{
      if (regex.test(e.target.value) || e.target.value === "") {
        newData.personalDetails[e.target.name] = e.target.value;
      }
    }
    props.setEmployee(newData);
  };

  return (
    <>
      <div className="grid grid-cols-4 p-2 gap-2">
        <div>
          <h4 className="text-slate-600">DOB</h4>
          <input
            type="date"
            name="dob"
            max={new Date().toISOString().split("T")[0]}
            value={
              dayjs(formData?.personalDetails?.dob).format("YYYY-MM-DD") || ""
            }
            className="border rounded-md my-2 px-2 py-1 w-[55%]"
            onChange={handleInputChange}
          />
        </div>
        <div>
          <h4 className="text-slate-600">Blood Group</h4>
          <select
            type="text"
            name="bloodGroup"
            value={formData?.personalDetails?.bloodGroup || ""}
            className="border rounded-md my-2 px-2 py-1"
            onChange={handleInputChange}
          >
           <option value="-">Select Blood Group</option>
           <option value="A+">A+</option>
           <option value="A-">A-</option>
           <option value="B+">B+</option>
           <option value="B-">B-</option>
           <option value="AB+">AB+</option>
           <option value="AB-">AB-</option>
           <option value="O+">O+</option>
           <option value="O-">O-</option>
        </select>
        </div>
        <div>
          <h4 className="text-slate-600">Father Name</h4>
          <input
            type="text"
            name="fatherName"
            value={formData?.personalDetails?.fatherName || ""}
            className="border rounded-md my-2 px-2 py-1"
            onChange={handleInputChange}
          />
        </div>
        <div>
          <h4 className="text-slate-600">Marital Status</h4>
          <select
            name="maritalStatus"
            value={formData?.personalDetails?.maritalStatus || ""}
            className="border rounded-md my-2 px-2 py-1 w-[55%]"
            onChange={handleInputChange}
          >
            <option value="SINGLE">SINGLE</option>
            <option value="MARRIED">MARRIED</option>
            <option value="DIVORCED">DIVORCED</option>
            <option value="SEPARATED">SEPARATED</option>
            <option value="WIDOWED">WIDOWED</option>
          </select>
        </div>
         <div>
          <h4 className="text-slate-600">Spouse Name</h4>
          <input
            type="text"
            name="spouseName"
            value={formData?.personalDetails?.spouseName || ""}
            className="border rounded-md my-2 px-2 py-1"
            onChange={handleInputChange}
          />
        </div>
        <div>
          <h4 className="text-slate-600">Nationality</h4>
          <select
            name="nationality"
            value={formData?.personalDetails?.nationality|| ""}
            className="border rounded-md my-2 px-2 py-1 w-[55%]"
            onChange={handleInputChange}
          >
            <option value="INDIAN">INDIAN</option>
            <option value="OTHERS">OTHERS</option>
          </select>
        </div>
        <div>
          <h4 className="text-slate-600">International Employee</h4>
          <select
            name="internationalEmployee"
            value={formData?.personalDetails?.internationalEmployee}
            className="border rounded-md my-2 px-2 py-1 w-[55%]"
            onChange={handleInputChange}
          >
            <option value={""} disabled>--Select an option--</option>
            <option value={true}>Yes</option>
            <option value={false}>No</option>
          </select>
        </div>
        <div>
          <h4 className="text-slate-600">Physically Challenged</h4>
          <select
            name="physicallyDisabled"
            value={formData?.personalDetails?.physicallyDisabled}
            className="border rounded-md my-2 px-2 py-1 w-[55%]"
            onChange={handleInputChange}
          >
            <option value={""} disabled>--Select an option--</option>
            <option value={true}>Yes</option>
            <option value={false}>No</option>
          </select>
        </div>
        <div>
          <h4 className="text-slate-600">Personal Email</h4>
          <input
            type="email"
            name="email"
            value={formData?.personalDetails?.email || ""}
            className="border rounded-md my-2 px-2 py-1"
            onChange={handleInputChange}
          />
          {error.email !== "" && (
            <p className="text-red-500 text-xs">{error.email}</p>
          )}
        </div>
      </div>
      <div className="mx-10 flex items-end justify-end space-x-5">
        <button
          className="border px-2  rounded-md"
          onClick={props.save}
          disabled={error.email !== ""}
        >
          Save
        </button>
        <button
          className="border px-2  rounded-md"
          onClick={() => {
            props.setEdit((prevEdit) => ({ ...prevEdit, personalInfo: false }));
            props.setEmployee(props.oldData);
          }}
        >
          Cancel
        </button>
      </div>
    </>
  );
}

export const JoiningInformation = (props) => {
  let formData = cloneDeep(props.data);
  const handleInputChange = (e) => {
    let newData = formData;
    if( e.target.name === "dateofJoining" || e.target.name === "confirmationDate" ){
      let date = new Date(e.target.value).toISOString();
      newData.professionalDetails[e.target.name] = date;
    }else if(e.target.name === "probationPeriod" || e.target.name === "noticePeriod" || e.target.name === "currentCompanyExperience" || e.target.name === "totalExperience" ){
      if(e.target.value < 0) {
        e.target.value = 0;
        newData.professionalDetails[e.target.name] = e.target.value
      }else{
        newData.professionalDetails[e.target.name] = e.target.value
      }
    }
    else{
      newData.professionalDetails[e.target.name] = e.target.value;
    }
    props.setEmployee(newData);
  };
  function handleKeyPress(event) {
    const forbiddenKeyCodes = [187, 189, 190, 69, 109, 110, 107];
    if (forbiddenKeyCodes.includes(event.keyCode)) {
      event.preventDefault();
    }
  }

  return (
    <>
      <div className="grid grid-cols-4 p-2 gap-2">
        <div>
          <h4 className="text-slate-600">Joined On</h4>
          <input
            type="date"
            max={new Date().toISOString().split("T")[0]}
            name="dateofJoining"
            value={dayjs(formData?.professionalDetails?.dateofJoining).format(
              "YYYY-MM-DD"
            )}
            className="border rounded-md my-2 px-2 py-1 w-[55%]"
            onChange={handleInputChange}
          />
        </div>
        <div>
          <h4 className="text-slate-600">Confirmed Date</h4>
          <input
            type="date"
            max={new Date().toISOString().split("T")[0]}
            name="confirmationDate"
            value={dayjs(
              formData?.professionalDetails?.confirmationDate
            ).format("YYYY-MM-DD")}
            className="border rounded-md my-2 px-2 py-1 w-[55%]"
            onChange={handleInputChange}
          />
        </div>
        <div>
          <h4 className="text-slate-600">Status</h4>
          <select
            name="status"
            value={formData?.professionalDetails?.status || ""}
            className="border rounded-md my-2 px-2 py-1 w-[55%]"
            onChange={handleInputChange}
          >
            <option value="CONFIRMED">CONFIRMED</option>
            <option value="CONSULTANT">CONSULTANT</option>
            <option value="PROBATION">PROBATION</option>
          </select>
        </div>
        <div>
          <h4 className="text-slate-600">Probation Period</h4>
          <input
            type="number"
            name="probationPeriod"
            value={formData?.professionalDetails?.probationPeriod || ""}
            onKeyDown={handleKeyPress}
            className="border rounded-md my-2 px-2 py-1"
            onChange={handleInputChange}
            min={0}
          />
        </div>
        <div>
          <h4 className="text-slate-600">Notice Period</h4>
          <input
            type="number"
            name="noticePeriod"
            value={formData?.professionalDetails?.noticePeriod || ""}
            onKeyDown={handleKeyPress}
            className="border rounded-md my-2 px-2 py-1"
            onChange={handleInputChange}
            min={0}
          />
        </div>
        <div>
          <h4 className="text-slate-600">Current Company Experience</h4>
          <input
            type="number"
            name="currentCompanyExperience"
            value={formData?.professionalDetails?.currentCompanyExperience || ""}
            onKeyDown={handleKeyPress}
            className="border rounded-md my-2 px-2 py-1"
            onChange={handleInputChange}
          />
        </div>
        <div>
          <h4 className="text-slate-600">Total Experience</h4>
          <input
            type="number"
            name="totalExperience"
            value={formData?.professionalDetails?.totalExperience || ""}
            onKeyDown={handleKeyPress}
            className="border rounded-md my-2 px-2 py-1"
            onChange={handleInputChange}
          />
        </div>
      </div>
      <div className="mx-10 flex items-end justify-end space-x-5">
        <button className="border px-2  rounded-md" onClick={props.save}>
          Save
        </button>
        <button
          className="border px-2  rounded-md"
          onClick={() => {
            props.setEdit((prevEdit) => ({ ...prevEdit, joiningInfo: false }));
            props.setEmployee(props.oldData);
          }}
        >
          Cancel
        </button>
      </div>
    </>
  );
}

export const CurrentPositionInformation = (props) => {
  const [employeeDeptId,setEmployeeDeptId] = useState("");
  let formData = cloneDeep(props.data);
  useEffect(() => {
    setEmployeeDeptId(formData.professionalDetails.department);
  },[]);
  
  const handleInputChange = (e) => {
    let newData = formData;
    if( e.target.name === "department" ){
      setEmployeeDeptId(e.target.value);
      newData.professionalDetails[e.target.name] = e.target.value;
    }else{
      newData.professionalDetails[e.target.name] = e.target.value;
    }
    props.setEmployee(newData);
  };
  const checkFields = ()=>{
    if(formData?.professionalDetails?.currentDesignation === "-" ||
        formData?.professionalDetails?.workLocation ==="-" ||
        formData?.professionalDetails?.department === "-" ||
        formData?.professionalDetails?.holidayCalender === "-" ||
        formData?.professionalDetails?.shift === "-" ||
        formData?.professionalDetails?.managerId === "-"
    ){
      return true;
    }else{
      return false;
    }
  }
  return (
    <>
      <div className="grid grid-cols-4 p-2 gap-2">
        <div>
          <h4 className="text-slate-600">Designation</h4>
          <select
            className="text-lg border rounded-md my-3 px-2 py-1"
            name="currentDesignation"
            value={formData?.professionalDetails?.currentDesignation || "-"}
            onChange={handleInputChange}
          >
            <option value="-">- Select Designation -</option>
            {Object.entries(props.designations[employeeDeptId] || {}).map(
              (item) => {
                return <option value={item[1]}>{item[0]}</option>;
              }
            )}
          </select>
        </div>
        <div>
          <h4 className="text-slate-600">Location</h4>
          <select
            className="text-lg border rounded-md my-3 px-2 py-1"
            name="workLocation"
            value={formData?.professionalDetails?.workLocation || "-"}
            onChange={handleInputChange}
          >
            <option value="-">- Select Location -</option>
            {props.location
              .filter((loc) => loc.isActive)
              .map((item) => {
                return <option value={item._id}>{item.location}</option>;
              })}
          </select>
        </div>
        <div>
          <h4 className="text-slate-600">Department</h4>
          <select
            className="text-lg border rounded-md my-3 px-2 py-1"
            name="department"
            value={formData?.professionalDetails?.department || "-"}
            onChange={handleInputChange}
          >
            <option value="-">- Select Department -</option>
            {props.department
              .filter((dept) => dept.isActive)
              .map((item) => {
                return <option value={item._id}>{item.departmentName}</option>;
              })}
          </select>
        </div>
        <div>
          <h4 className="text-slate-600">Holiday Calendar</h4>
          <select
            className=" text-lg border rounded-md my-3 px-2 py-1"
            name="holidayCalender"
            value={formData?.professionalDetails?.holidayCalender || "-"}
            onChange={handleInputChange}
          >
            <option value="-">- Select Holiday Calendar -</option>
            {Object.entries(props.calendar || {}).map((item) => {
              return <option value={item[0]}>{item[1]}</option>;
            })}
          </select>
        </div>
        <div>
          <h4 className="text-slate-600">Shift</h4>
          <select
            className="text-lg border rounded-md my-3 px-2 py-1"
            name="shift"
            value={formData?.professionalDetails?.shift || "-"}
            onChange={handleInputChange}
          >
            <option value="-">- Select Shift -</option>
            <option value="GENERAL_SHIFT_1">GENERAL SHIFT 1</option>
            <option value="GENERAL_SHIFT_2">GENERAL SHIFT 2</option>
            <option value="NIGHT_SHIFT">NIGHT SHIFT</option>
          </select>
        </div>
        <div>
          <h4 className="text-slate-600">Reporting manager</h4>
          <select
            className="text-lg border rounded-md my-3 px-2 py-1"
            name="managerId"
            value={formData?.professionalDetails?.managerId || "-"}
            onChange={handleInputChange}
          >
            <option value="-">- Select manager -</option>
            {props.employeeList
              .filter((emp) => (emp.ismanager === "true" && formData?.professionalDetails?.department === emp.department) || emp.ismanager === "null")
              .map((item) => {
                return <option value={item.id}>{item.name}</option>;
              })}
          </select>
        </div>
      </div>
      <div className="mx-10 flex items-end justify-end space-x-5">
        <p className='text-red-600 font-medium'>{checkFields() && "The Above Feilds are mandatory, Please Fill All of them."}</p>
        <button className={`${checkFields()? "cursor-not-allowed": "cursor-pointer"} border px-2  rounded-md`} disabled={checkFields()} onClick={props.save}>
          Save
        </button>
        <button
          className="border px-2  rounded-md"
          onClick={() => {
            props.setEdit((prevEdit) => ({
              ...prevEdit,
              currentPosition: false,
            }));
            props.setEmployee(props.oldData);
          }}
        >
          Cancel
        </button>
      </div>
    </>
  );
}

export const BankDetailsInformation = (props) => {
  let formData = cloneDeep(props.data);

  const [error,setError] = useState({ifsccode:""})

  const handleInputChange = (e) => {
    let newData = formData;
    if(e.target.name === "branchName" || e.target.name === "bankName"){
       if(/^[a-zA-Z0-9\s]*$/.test(e.target.value) || e.target.value === ""){
        newData.bankDetails[e.target.name] = e.target.value;
      }
    }else if(e.target.name === "ifsccode"){
      newData.bankDetails[e.target.name] = e.target.value;
      if(!(/^[A-Za-z]{4}0[A-Z0-9]{6}$/.test(e.target.value))){
        setError((prevError) => ({ ...prevError, ifsccode : "Enter valid IFSC code" }));
      }else {
        setError((prevError) => ({ ...prevError, ifsccode : "" }));
      }
    }
    else{
      newData.bankDetails[e.target.name] = e.target.value;
    }
    props.setEmployee(newData);
  };

  return (
    <>
      <div className="grid grid-cols-4 p-2 gap-2">
        <div>
          <h4 className="text-slate-600">Bank Name</h4>
          <input
            type="text"
            name="bankName"
            value={formData?.bankDetails?.bankName || ""}
            className="border rounded-md my-2 px-2 py-1"
            onChange={handleInputChange}
          />
        </div>
        <div>
          <h4 className="text-slate-600">Account Number</h4>
          <input
            type="text"
            name="accountNo"
            value={formData?.bankDetails?.accountNo || ""}
            className="border rounded-md my-2 px-2 py-1"
            onChange={handleInputChange}
          />
        </div>
        <div>
          <h4 className="text-slate-600">Branch Name</h4>
          <input
            type="text"
            name="branchName"
            value={formData?.bankDetails?.branchName || ""}
            className="border rounded-md my-2 px-2 py-1"
            onChange={handleInputChange}
          />
        </div>
        <div>
          <h4 className="text-slate-600">IFSC code</h4>
          <input
            type="text"
            name="ifsccode"
            value={formData?.bankDetails?.ifsccode || ""}
            className="border rounded-md my-2 px-2 py-1"
            onChange={handleInputChange}
          />
          {error.ifsccode !== "" && (
            <p className="text-red-500 text-xs">{error.ifsccode}</p>
          )}
        </div>
      </div>
      <DocsEdit
        data={props.data}
        setEmployee={props.setEmployee}
        setEdit={props.setEdit}
        save={() => props.save()}
        oldData={props.oldData}
        handleDownload={props.handleDownload}
        component="bankDetails"
      />
      <div className="mx-10 flex items-end justify-end space-x-5">
        <button className="border px-2  rounded-md" disabled={error.ifsccode !== ""} onClick={props.save}>
          Save
        </button>
        <button
          className="border px-2  rounded-md"
          onClick={() => {
            props.setEdit((prevEdit) => ({
              ...prevEdit,
              bankDetails: false,
            }));
            props.setEmployee(props.oldData);
          }}
        >
          Cancel
        </button>
      </div>
    </>)
}

export const EducationInformation = (props) => {
  let formData = cloneDeep(props.data);
  const handleInputChange = (e) => {
    let newData = formData;
    if( e.target.name === "fromDate" || e.target.name === "toDate" ){
      let date = new Date(e.target.value).toISOString();
      newData.academicDetails[e.target.name] = date;
    }else if(e.target.name === "institute"){
       if(/^[a-zA-Z\s]*$/.test(e.target.value) || e.target.value === ""){
        newData.academicDetails[e.target.name] = e.target.value;
       }
    }else{
      newData.academicDetails[e.target.name] = e.target.value;
    }
    props.setEmployee(newData);
  };

  return (
    <>
      <div className="grid grid-cols-4 p-2 gap-2">
        <div>
          <h4 className="text-slate-600">Institute</h4>
          <input
            type="text"
            name="institute"
            value={formData?.academicDetails?.institute || ""}
            className="border rounded-md my-2 px-2 py-1"
            onChange={handleInputChange}
          />
        </div>
        <div>
          <h4 className="text-slate-600">Grade</h4>
          <input
            type="text"
            name="grade"
            value={formData?.academicDetails?.grade || ""}
            className="border rounded-md my-2 px-2 py-1"
            onChange={handleInputChange}
          />
        </div>
        <div>
          <h4 className="text-slate-600">From Date</h4>
          <input
            type="date"
            name="fromDate"
            max={formData?.academicDetails?.toDate ? (() => {
              const toDate = new Date(formData.academicDetails.toDate);
              toDate.setDate(toDate.getDate() - 1);
              return toDate.toISOString().split("T")[0];})() 
              : new Date().toISOString().split("T")[0]}
            value={dayjs(formData?.academicDetails?.fromDate).format(
              "YYYY-MM-DD"
            )}
            className="border rounded-md my-2 px-2 py-1"
            onChange={handleInputChange}
          />
        </div>
        <div>
          <h4 className="text-slate-600">To Date</h4>
          <input
            type="date"
            max={new Date().toISOString().split("T")[0]}
            min={formData?.academicDetails?.fromDate ? (() => {
                 const fromDate = new Date(formData.academicDetails.fromDate);
                  fromDate.setDate(fromDate.getDate() + 1);
                  return fromDate.toISOString().split("T")[0];
            })() : ""}
            name="toDate"
            value={dayjs(formData?.academicDetails?.toDate).format(
              "YYYY-MM-DD"
            )}
            className="border rounded-md my-2 px-2 py-1"
            onChange={handleInputChange}
          />
        </div>
        <div>
          <h4 className="text-slate-600">Qualification</h4>
          <input
            type="text"
            name="qualification"
            value={formData?.academicDetails?.qualification || ""}
            className="border rounded-md my-2 px-2 py-1"
            onChange={handleInputChange}
          />
        </div>
        <div>
          <h4 className="text-slate-600">Remarks</h4>
          <input
            type="text"
            name="remarks"
            value={formData?.academicDetails?.remarks || ""}
            className="border rounded-md my-2 px-2 py-1"
            onChange={handleInputChange}
          />
        </div>
      </div>
      <DocsEdit
        data={props.data}
        setEmployee={props.setEmployee}
        setEdit={props.setEdit}
        save={() => props.save()}
        oldData={props.oldData}
        handleDownload={props.handleDownload}
        component="academic"
      />
      <div className="mx-10 flex items-end justify-end space-x-5">
        <button className="border px-2  rounded-md" onClick={props.save}>
          Save
        </button>
        <button
          className="border px-2  rounded-md"
          onClick={() => {
            props.setEdit((prevEdit) => ({
              ...prevEdit,
              educationInfo: false,
            }));
            props.setEmployee(props.oldData);
          }}
        >
          Cancel
        </button>
      </div>
    </>
  );
}

export const WorkExperienceInformation = (props) => {
  let formData = cloneDeep(props.data);
  const [dialogOpen,setDialogOpen] = useState(false);
  const [docName,setDocName] = useState("");
  const [docFormat,setDocFormat] = useState();
  const [index,setIndex] = useState();

  const handleInputChange = (e,i) => {
     let newData = formData;
     let regex = /^[a-zA-Z0-9\s]*$/;
     if( e.target.name === "startDate" || e.target.name === "endDate" ){
      let date = new Date(e.target.value).toISOString();
      newData.workExperience[i][e.target.name] = date;
    }else{
      if(regex.test(e.target.value) || e.target.value === ""){
        newData.workExperience[i][e.target.name] = e.target.value;
      }
    }
    props.setEmployee(newData);
  };
  const handleOpen = (i) => {
    setDialogOpen(true);
    setIndex(i);
  }
  const handleClose = () => {
    setDialogOpen(false);
    setDocName()
    setIndex();
  }
  const handleDocNameChange = (evt) => {
    if(/^[a-zA-Z0-9\s]*$/.test(evt.target.value)){
      setDocName(evt.target.value);
    }
  }

  const handleDocsChange = async(evt) => {
    const file_to_upload = evt.target.files[0];
    const maxSizeInBytes = 10 * 1024 * 1024; // max 10mb

    if (file_to_upload?.size > maxSizeInBytes) {
      toast.error('File size limit is 10Mb')
      return
    }

    let s3Data;
    
    try {
      s3Data = await upload_file_to_s3(file_to_upload);
    } catch (err) {
      toast.error('Failed to upload document');
      console.error(err);
    }
    let document = {
      fileName: file_to_upload.name,
      name: docName?.trim(),
      resourceName: "", 
      uploadDate: new Date().toISOString(),
      comment: "",
      extension: file_to_upload.name.split(".").pop(),
      s3Document:s3Data?.data?.data
    };
    setDocFormat(document);
  }
  const handleSave = () => {
    if (docFormat) {
      let newData = formData;
      newData.workExperience[index].documents.push(docFormat);
      props.setEmployee(newData);
      setDocFormat();
      handleClose();
      setDocName('');
    }
  }
  const handleDocDelete = (workIndex,docIndex) => {
    let newData = formData;
    newData.workExperience[workIndex].documents.splice(docIndex,1);
    props.setEmployee(newData);
  }
  const handleWorkDelete = (index) => {
    let newData = {...formData};
    newData.workExperience.splice(index,1);
    props.setEmployee(newData);
  }
  return (
    <>
      <Dialog
        open={dialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Upload documents "}</DialogTitle>
        <DialogContent>
          <CustomText
            name="name"
            label="File Name"
            type="text"
            value={docName}
            onChange={handleDocNameChange}
            isMandatory={true}
          />
          <CustomText
            name="file"
            label="Upload file"
            type="file"
            disabled={docName?.trim() === ''}
            // value={empState.name}
            onChange={handleDocsChange}
            // isMandatory={true}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>CANCEL</Button>
          <Button onClick={handleSave} disabled={docName?.trim() === '' || Object.keys(docFormat?.s3Document ?? {}).length === 0}>SAVE</Button>
        </DialogActions>
      </Dialog>
      {formData?.workExperience.map((item, i) => (
        <div className={i === 0 ? "" : "mt-5 border-t-2 border-slate-300"}>
          <div className="w-full flex justify-between mt-5">
            <h3 className="font-medium text-xl">Company {i + 1}</h3>
            {i !== 0 ? (
              <div className='mx-5 h-6 w-6 my-auto text-red-500 cursor-pointer' onClick={() => handleWorkDelete(i)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="grid grid-cols-4 p-2 gap-2">
            <div>
              <h4 className="text-slate-600">Company Name</h4>
              <input
                type="text"
                name="companyName"
                value={item.companyName || ""}
                className="border rounded-md my-2 px-2 py-1"
                onChange={(e) => handleInputChange(e, i)}
              />
            </div>
            <div>
              <h4 className="text-slate-600">Designation</h4>
              <input
                type="text"
                name="designation"
                value={item.designation || ""}
                className="border rounded-md my-2 px-2 py-1"
                onChange={(e) => handleInputChange(e, i)}
              />
            </div>
            <div>
              <h4 className="text-slate-600">Start date</h4>
              <input
                type="date"
                name="startDate"
                max={item.endDate ? (() => {
                  const toDate = new Date(item.endDate);
                  toDate.setDate(toDate.getDate() - 1);
                  return toDate.toISOString().split("T")[0];
                })() : new Date().toISOString().split("T")[0]}
                value={dayjs(item.startDate).format("YYYY-MM-DD")}
                className="border rounded-md my-2 px-2 py-1"
                onChange={(e) => handleInputChange(e, i)}
              />
            </div>
            <div>
              <h4 className="text-slate-600">End Date</h4>
              <input
                type="date"
                name="endDate"
                min={item.startDate ? (() => {
                  const fromDate = new Date(item.startDate);
                  fromDate.setDate(fromDate.getDate() + 1);
                  return fromDate.toISOString().split("T")[0];
                })() : ""}
                max={new Date().toISOString().split("T")[0]}
                value={dayjs(item.endDate).format("YYYY-MM-DD")}
                className="border rounded-md my-2 px-2 py-1"
                onChange={(e) => handleInputChange(e, i)}
              />
            </div>
          </div>
          <div className='mx-2'>
            <h4>Documents</h4>
            <div className="grid grid-flow-col auto-cols-max p-2 gap-5">
              {item.documents.map((doc, docIndex) => (
                <div className="bg-slate-100 rounded-lg p-2 w-56 flex justify-between">
                  <div className="flex">
                    <div className="text-blue-800 my-auto">
                      <DocumentIcon className="w-7 h-7" />
                    </div>
                    <div class="text-blue-500 mx-2 w-fit cursor-pointer" onClick={() => props.handleDownload(doc)}>
                      <p className="text-lg">
                        {doc?.name?.length > 15
                          ? doc?.name?.substring(0, 15) + "..."
                          : doc?.name}
                      </p>
                      <p className="text-xs">
                        {doc?.fileName?.length > 19
                          ? doc?.fileName?.substring(0, 19) + "..."
                          : doc?.fileName}
                      </p>
                    </div>
                  </div>
                  <div
                    className="h-6 w-6 my-auto text-red-500 cursor-pointer"
                    onClick={() => handleDocDelete(i, docIndex)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </div>
                </div>
              ))}
              <div
                className="bg-slate-100 rounded-lg my-auto p-4 text-blue-800 cursor-pointer"
                onClick={() => handleOpen(i)}
              >
                <PlusIcon className="w-7 h-7" />
              </div>
            </div>
            <p className='text-xs text-red-400'>Note:Click on the file name to download.</p>
          </div>
        </div>
      ))}

      <div className="mx-10 mt-5 flex items-end justify-end space-x-5">
        <button className="border px-2  rounded-md" onClick={props.save}>
          Save
        </button>
        <button
          className="border px-2  rounded-md"
          onClick={() => {
            props.setEmployee(props.oldData);
            props.setEdit((prevEdit) => ({
              ...prevEdit,
              workExperience: false,
            }));
          }}
        >
          Cancel
        </button>
      </div>
    </>
  );
}

export const DocsInformation = (props) => {
  let formData = cloneDeep(props.data);
  const [dialogOpen,setDialogOpen] = useState(false);
  const [docName,setDocName] = useState("");
  const [docFormat,setDocFormat] = useState();

  const handleOpen = (i) => {
    setDialogOpen(true);
  }
  const handleClose = () => {
    setDialogOpen(false);
    setDocName()
  }
  const handleDocNameChange = (evt) => {
    if(/^[a-zA-Z0-9\s]*$/.test(evt.target.value)){
      setDocName(evt.target.value);
    }
  }

  const handleDocsChange = async(evt) => {


    const file_to_upload = evt.target.files[0];
    const maxSizeInBytes = 10 * 1024 * 1024; // max 10mb

    if (file_to_upload?.size > maxSizeInBytes) {
      toast.error('File size limit is 10Mb')
      return
    }

    let s3Data;
    try {
      s3Data = await upload_file_to_s3(file_to_upload);
      
    } catch (err) {
      toast.error('Failed to upload the file')
      console.error(err);
    }
    let document = {
      fileName: file_to_upload.name,
      name: docName.trim(),
      resourceName: "", 
      uploadDate: new Date().toISOString(),
      comment: "",
      extension: file_to_upload.name.split(".").pop(),
      s3Document: s3Data?.data?.data
    };
    setDocFormat(document);
  }
  const handleSave = () => {


    if (docFormat) {
      let newData = formData;
      newData.documents.push(docFormat);
      props.setEmployee(newData);
      setDocFormat();
      handleClose();
      setDocName('');
    }
  }
  const handleDocDelete = (docIndex) => {
    let newData = formData;
    newData.documents.splice(docIndex,1);
    props.setEmployee(newData);
  }

  return (
    <>
      <Dialog
        open={dialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Upload documents "}</DialogTitle>
        <DialogContent>
          <CustomText
            name="name"
            label="File Name"
            type="text"
            value={docName}
            onChange={handleDocNameChange}
            isMandatory={true}
          />
          <CustomText
            name="file"
            label="Upload file"
            type="file"
            disabled={docName?.trim() === ''}
            // value={empState.name}
            onChange={handleDocsChange}
          // isMandatory={true}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>CANCEL</Button>
          <Button onClick={handleSave} disabled={docName?.trim() === ''|| Object.keys(docFormat?.s3Document ?? {}).length === 0}>SAVE</Button>
        </DialogActions>
      </Dialog>
      <div className='mx-2'>
            <h4>Documents</h4>
            <div className="flex flex-wrap p-2 gap-5">
              {formData.documents.map((doc, docIndex) => (
                <div key={docIndex + 1} className="bg-slate-100 rounded-lg p-2 w-56 flex justify-between">
                  <div className="flex">
                    <div className="text-blue-800 my-auto">
                      <DocumentIcon className="w-7 h-7" />
                    </div>
                    <div class="text-blue-500 mx-2 w-fit cursor-pointer" onClick={() => props.handleDownload(doc)}>
                      <p className="text-lg">
                        {doc.name.length > 15
                          ? doc.name.substring(0, 15) + "..."
                          : doc.name}
                      </p>
                      <p className="text-xs">
                        {doc.fileName.length > 19
                          ? doc.fileName.substring(0, 19) + "..."
                          : doc.fileName}
                      </p>
                    </div>
                  </div>
                  <div
                    className="h-6 w-6 my-auto text-red-500 cursor-pointer"
                    onClick={() => handleDocDelete(docIndex)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </div>
                </div>
              ))}
              <div
                className="bg-slate-100 rounded-lg my-auto p-4 text-blue-800 cursor-pointer"
                onClick={() => handleOpen()}
              >
                <PlusIcon className="w-7 h-7" />
              </div>
            </div>
            <p className='text-xs text-red-400'>Note:Click on the file name to download.</p>
            <div className="mx-10 mt-5 flex items-end justify-end space-x-5">
              <button className="border px-2  rounded-md" onClick={props.save}>
                Save
              </button>
              <button
                className="border px-2  rounded-md"
                onClick={() => {
                  props.setEmployee(props.oldData);
                  props.setEdit((prevEdit) => ({
                    ...prevEdit,
                    otherDocs: false,
                  }));
                }}
              >
                Cancel
              </button>
            </div>
          </div>
    </>
  )
}

export const PresentAddressInformation = (props) => {
  const [error,setError] = useState({email:"",phone:"",})
  let formData = cloneDeep(props.data);
  let regex = /^[a-zA-Z0-9\s]*$/
  const handleInputChange = (e) => {
    let newData = formData;
    if(e.target.name === "email"){
      const email = e.target.value;
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      let isValid = emailRegex.test(email);
      if(isValid === false){
        setError((prevError) => ({ ...prevError, email : "Enter valid email" }));
      }else{
        setError((prevError) => ({ ...prevError, email : "" }));
      }
      newData.currentAddress.email = e.target.value;
    }else{
      newData.currentAddress[e.target.name] = e.target.value;
    }
    props.setEmployee(newData);
  };
  const handlePhoneInputChange = (value, name) => {
    let newData = formData;
    const phoneNumberPattern = /^\d{12,15}$/
    let isValid = phoneNumberPattern.test(value);
    if(isValid === false){
      setError((prevError) => ({ ...prevError, phone: "Invalid phone number" }));
    }else{
      setError((prevError) => ({ ...prevError, phone: "" }));
    }
    newData.currentAddress[name] = value;
    props.setEmployee(newData);
  };
 return (
   <>
     <div className="grid grid-cols-4 p-2 gap-2">
       <div>
         <h4 className="text-slate-600">Name</h4>
         <input
           type="text"
           name="name"
           value={formData?.currentAddress?.name || ""}
           className="border rounded-md my-2 px-2 py-1"
           onChange={handleInputChange}
         />
       </div>
       <div>
         <h4 className="text-slate-600">Address</h4>
         <input
           type="text"
           name="address"
           value={formData?.currentAddress?.address || ""}
           className="border rounded-md my-2 px-2 py-1"
           onChange={handleInputChange}
         />
       </div>
       <div>
         <h4 className="text-slate-600">City</h4>
         <input
           type="text"
           name="city"
           value={formData?.currentAddress?.city || ""}
           className="border rounded-md my-2 px-2 py-1"
           onChange={handleInputChange}
         />
       </div>
       <div>
         <h4 className="text-slate-600">State</h4>
         <input
           type="text"
           name="state"
           value={formData?.currentAddress?.state || ""}
           className="border rounded-md my-2 px-2 py-1"
           onChange={handleInputChange}
         />
       </div>
       <div>
         <h4 className="text-slate-600">Country</h4>
         <input
           type="text"
           name="country"
           value={formData?.currentAddress?.country || ""}
           className="border rounded-md my-2 px-2 py-1"
           onChange={handleInputChange}
         />
       </div>
       <div>
         <h4 className="text-slate-600">Pincode</h4>
         <input
           type="text"
           name="pincode"
           value={formData?.currentAddress?.pincode || ""}
           className="border rounded-md my-2 px-2 py-1"
           onChange={handleInputChange}
         />
       </div>
       <div>
         <h4 className="text-slate-600">Phone</h4>
         <input
           type="text"
           name="phone"
           value={formData?.currentAddress?.phone || ""}
           className="border rounded-md my-2 px-2 py-1"
           onChange={handleInputChange}
         />
       </div>
       <div>
         <h4 className="text-slate-600">Fax</h4>
         <input
           type="text"
           name="fax"
           value={formData?.currentAddress?.fax || ""}
           className="border rounded-md my-2 px-2 py-1"
           onChange={handleInputChange}
         />
       </div>
       <div>
         <h4 className="text-slate-600">Mobile Number</h4>
         {/* <input
            type="text"
            name="mobileNumber"
            value={formData?.currentAddress?.mobileNumber || ""}
            className='border rounded-md my-2 px-2 py-1'
            onChange={handleInputChange}
          /> */}
         <div className="w-[55%]">
           <CustomPhoneInput
             name="mobileNumber"
             value={formData?.currentAddress?.mobileNumber || ""}
             onPhoneInputChange={handlePhoneInputChange}
             customPlaceholder="Phone"
           />
         </div>
       </div>
       <div>
         <h4 className="text-slate-600">Email</h4>
         <input
           type="email"
           name="email"
           value={formData?.currentAddress?.email || ""}
           className="border rounded-md my-2 px-2 py-1"
           onChange={handleInputChange}
         />
         {error.email !== "" && (
           <p className="text-red-500 text-xs">{error.email}</p>
         )}
       </div>
     </div>
     <div className="mx-10 flex items-end justify-end space-x-5">
       <button
         className="border px-2  rounded-md"
         onClick={props.save}
         disabled={error.email !== "" || error.phone !== ""}
       >
         Save
       </button>
       <button
         className="border px-2  rounded-md"
         onClick={() => {
           props.setEdit((prevEdit) => ({
             ...prevEdit,
             presentAddress: false,
           }));
           props.setEmployee(props.oldData);
         }}
       >
         Cancel
       </button>
     </div>
   </>
 );
}

export const PermanentAddressInformation = (props) => {
  const [error,setError] = useState({email:"",phone:"",})
  let formData = cloneDeep(props.data);
  const handleInputChange = (e) => {
    let newData = formData;
    if(e.target.name === "email"){
      const email = e.target.value;
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      let isValid = emailRegex.test(email);
      if(isValid === false){
        setError((prevError) => ({ ...prevError, email : "Enter valid email" }));
      }else{
        setError((prevError) => ({ ...prevError, email : "" }));
      }
      newData.permanentAddress.email = e.target.value;
    }else{
      newData.permanentAddress[e.target.name] = e.target.value;
    }
    props.setEmployee(newData);
  };
  const handlePhoneInputChange = (value, name) => {
    let newData = formData;
    const phoneNumberPattern = /^\d{12,15}$/
    let isValid = phoneNumberPattern.test(value);
    if(isValid === false){
      setError((prevError) => ({ ...prevError, phone: "Invalid phone number" }));
    }else{
      setError((prevError) => ({ ...prevError, phone: "" }));
    }
    newData.permanentAddress[name] = value;
    props.setEmployee(newData);
  };
 return (
   <>
     <div className="grid grid-cols-4 p-2 gap-2">
       <div>
         <h4 className="text-slate-600">Name</h4>
         <input
           type="text"
           name="name"
           value={formData?.permanentAddress?.name || ""}
           className="border rounded-md my-2 px-2 py-1"
           onChange={handleInputChange}
         />
       </div>
       <div>
         <h4 className="text-slate-600">Address</h4>
         <input
           type="text"
           name="address"
           value={formData?.permanentAddress?.address || ""}
           className="border rounded-md my-2 px-2 py-1"
           onChange={handleInputChange}
         />
       </div>
       <div>
         <h4 className="text-slate-600">City</h4>
         <input
           type="text"
           name="city"
           value={formData?.permanentAddress?.city || ""}
           className="border rounded-md my-2 px-2 py-1"
           onChange={handleInputChange}
         />
       </div>
       <div>
         <h4 className="text-slate-600">State</h4>
         <input
           type="text"
           name="state"
           value={formData?.permanentAddress?.state || ""}
           className="border rounded-md my-2 px-2 py-1"
           onChange={handleInputChange}
         />
       </div>
       <div>
         <h4 className="text-slate-600">Country</h4>
         <input
           type="text"
           name="country"
           value={formData?.permanentAddress?.country || ""}
           className="border rounded-md my-2 px-2 py-1"
           onChange={handleInputChange}
         />
       </div>
       <div>
         <h4 className="text-slate-600">Pincode</h4>
         <input
           type="text"
           name="pincode"
           value={formData?.permanentAddress?.pincode || ""}
           className="border rounded-md my-2 px-2 py-1"
           onChange={handleInputChange}
         />
       </div>
       <div>
         <h4 className="text-slate-600">Phone</h4>
         <input
           type="text"
           name="phone"
           value={formData?.permanentAddress?.phone || ""}
           className="border rounded-md my-2 px-2 py-1"
           onChange={handleInputChange}
         />
       </div>
       <div>
         <h4 className="text-slate-600">Fax</h4>
         <input
           type="text"
           name="fax"
           value={formData?.permanentAddress?.fax || ""}
           className="border rounded-md my-2 px-2 py-1"
           onChange={handleInputChange}
         />
       </div>
       <div>
         <h4 className="text-slate-600">Mobile Number</h4>
         {/* <input
           type="text"
           name="mobileNumber"
           value={formData?.permanentAddress?.mobileNumber || ""}
           className="border rounded-md my-2 px-2 py-1"
           onChange={handleInputChange}
         /> */}
         <div className="w-[55%]">
           <CustomPhoneInput
             name="mobileNumber"
             value={formData?.permanentAddress?.mobileNumber || ""}
             onPhoneInputChange={handlePhoneInputChange}
             customPlaceholder="Mobile Number"
           />
         </div>
         {error.phone !== "" && (
           <p className="text-red-500 text-xs">{error.phone}</p>
         )}
       </div>
       <div>
         <h4 className="text-slate-600">Email</h4>
         <input
           type="email"
           name="email"
           value={formData?.permanentAddress?.email || ""}
           className="border rounded-md my-2 px-2 py-1"
           onChange={handleInputChange}
         />
         {error.email !== "" && (
           <p className="text-red-500 text-xs">{error.email}</p>
         )}
       </div>
     </div>
     <div className="mx-10 flex items-end justify-end space-x-5">
       <button
         className="border px-2  rounded-md"
         onClick={props.save}
         disabled={error.email !== "" || error.phone !== ""}
       >
         Save
       </button>
       <button
         className="border px-2  rounded-md"
         onClick={() => {
           props.setEdit((prevEdit) => ({
             ...prevEdit,
             permanentAddress: false,
           }));
           props.setEmployee(props.oldData);
         }}
       >
         Cancel
       </button>
     </div>
   </>
 );
}

export const EmergencyContactInformation = (props) => {
  const [error,setError] = useState({email:"",phone:"",})
  let formData = cloneDeep(props.data);
  const handleInputChange = (e) => {
    let newData = formData;
    if(e.target.name === "email"){
      const email = e.target.value;
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      let errorData = {...error}
      let isValid = emailRegex.test(email);
      if(isValid === false){
        errorData.email = "Invalid Email"
      }else{
        errorData.email = ""
      }
      setError(errorData);
      newData.emergencyContactAddress.email = e.target.value;
    }else{
    newData.emergencyContactAddress[e.target.name] = e.target.value;
    }
    props.setEmployee(newData);
  };
  const handlePhoneInputChange = (value, name) => {
    let newData = formData;
    let errorData = {...error}
    const phoneNumberPattern = /^\d{12,15}$/
    let isValid = phoneNumberPattern.test(value);
    if(isValid === false){
      errorData.phone = "Invalid phone number";
    }else{
      errorData.phone = "";
    }
    setError(errorData);
    newData.emergencyContactAddress[name] = value;
    props.setEmployee(newData);
  };
 return (
   <>
     <div className="grid grid-cols-4 p-2 gap-2">
       <div>
         <h4 className="text-slate-600">Name</h4>
         <input
           type="text"
           name="name"
           value={formData?.emergencyContactAddress?.name || ""}
           className="border rounded-md my-2 px-2 py-1"
           onChange={handleInputChange}
         />
       </div>
       <div>
         <h4 className="text-slate-600">Relationship</h4>
         <input
           type="text"
           name="relationship"
           value={formData?.emergencyContactAddress?.relationship || ""}
           className="border rounded-md my-2 px-2 py-1"
           onChange={handleInputChange}
         />
       </div>
       <div>
         <h4 className="text-slate-600">Address</h4>
         <input
           type="text"
           name="address"
           value={formData?.emergencyContactAddress?.address || ""}
           className="border rounded-md my-2 px-2 py-1"
           onChange={handleInputChange}
         />
       </div>
       <div>
         <h4 className="text-slate-600">City</h4>
         <input
           type="text"
           name="city"
           value={formData?.emergencyContactAddress?.city || ""}
           className="border rounded-md my-2 px-2 py-1"
           onChange={handleInputChange}
         />
       </div>
       <div>
         <h4 className="text-slate-600">State</h4>
         <input
           type="text"
           name="state"
           value={formData?.emergencyContactAddress?.state || ""}
           className="border rounded-md my-2 px-2 py-1"
           onChange={handleInputChange}
         />
       </div>
       <div>
         <h4 className="text-slate-600">Country</h4>
         <input
           type="text"
           name="country"
           value={formData?.emergencyContactAddress?.country || ""}
           className="border rounded-md my-2 px-2 py-1"
           onChange={handleInputChange}
         />
       </div>
       <div>
         <h4 className="text-slate-600">Pincode</h4>
         <input
           type="text"
           name="pincode"
           value={formData?.emergencyContactAddress?.pincode || ""}
           className="border rounded-md my-2 px-2 py-1"
           onChange={handleInputChange}
         />
       </div>
       <div>
         <h4 className="text-slate-600">Phone</h4>
         <input
           type="text"
           name="phone"
           value={formData?.emergencyContactAddress?.phone || ""}
           className="border rounded-md my-2 px-2 py-1"
           onChange={handleInputChange}
         />
       </div>
       <div>
         <h4 className="text-slate-600">Fax</h4>
         <input
           type="text"
           name="fax"
           value={formData?.emergencyContactAddress?.fax || ""}
           className="border rounded-md my-2 px-2 py-1"
           onChange={handleInputChange}
         />
       </div>
       <div>
         <h4 className="text-slate-600">Mobile Number</h4>
         {/* <input
            type="text"
            name="mobileNumber"
            value={formData?.emergencyContactAddress?.mobileNumber || ""}
            className='border rounded-md my-2 px-2 py-1'
            onChange={handleInputChange}
          /> */}
         <div className="w-[55%]">
           <CustomPhoneInput
             name="mobileNumber"
             value={formData?.emergencyContactAddress?.mobileNumber || ""}
             onPhoneInputChange={handlePhoneInputChange}
             customPlaceholder="Mobile Number"
           />
         </div>
         {error.phone !== "" && (
           <p className="text-red-500 text-xs">{error.phone}</p>
         )}
       </div>
       <div>
         <h4 className="text-slate-600">Email</h4>
         <input
           type="email"
           name="email"
           value={formData?.emergencyContactAddress?.email || ""}
           className="border rounded-md my-2 px-2 py-1"
           onChange={handleInputChange}
         />
         {error.email !== "" && (
           <p className="text-red-500 text-xs">{error.email}</p>
         )}
       </div>
     </div>
     <div className="mx-10 flex items-end justify-end space-x-5">
       <button
         className="border px-2  rounded-md"
         onClick={props.save}
         disabled={error.email !== "" || error.phone !== ""}
       >
         Save
       </button>
       <button
         className="border px-2  rounded-md"
         onClick={() => {
           props.setEdit((prevEdit) => ({
             ...prevEdit,
             emergencyContact: false,
           }));
           props.setEmployee(props.oldData);
         }}
       >
         Cancel
       </button>
     </div>
   </>
 );
}

export const BackgroundCheckInformation = (props) => {
  let formData = cloneDeep(props.data);
  let regex = /^[a-zA-Z0-9\s]*$/;
  const handleInputChange = (e) => {
    let newData = formData;
    if(e.target.name === "verificationCOmpletedOn"){
      let date = new Date(e.target.value).toISOString();
      newData.backgroundVerification.verificationCOmpletedOn = date;
    }else {
      if(regex.test(e.target.value) || e.target.value === ""){
         newData.backgroundVerification[e.target.name] = e.target.value;
      }
    }
    props.setEmployee(newData);
  };
  return (
    <div>
      <div className="grid grid-cols-4 p-2 gap-2">
        <div>
          <h4 className="text-slate-600">Verification Status</h4>
          <input
            type="text"
            name="verificationStatus"
            value={formData?.backgroundVerification?.verificationStatus || ""}
            className="border rounded-md my-2 px-2 py-1"
            onChange={handleInputChange}
          />
        </div>
        <div>
          <h4 className="text-slate-600">Verification Completed on</h4>
          <input
            type="date"
            name="verificationCOmpletedOn"
            max={new Date().toISOString().split("T")[0]}
            value={dayjs(
              formData?.backgroundVerification?.verificationCOmpletedOn
            ).format("YYYY-MM-DD")}
            className="border rounded-md my-2 px-2 py-1"
            onChange={handleInputChange}
          />
        </div>
        <div>
          <h4 className="text-slate-600">Agency Name</h4>
          <input
            type="text"
            name="completedby"
            value={formData?.backgroundVerification?.completedby || ""}
            className="border rounded-md my-2 px-2 py-1"
            onChange={handleInputChange}
          />
        </div>
        <div>
          <h4 className="text-slate-600">Remarks</h4>
          <input
            type="text"
            name="remarks"
            value={formData?.backgroundVerification?.remarks || ""}
            className="border rounded-md my-2 px-2 py-1"
            onChange={handleInputChange}
          />
        </div>
      </div>
      <div className="mx-10 flex items-end justify-end space-x-5">
        <button className="border px-2  rounded-md" onClick={props.save}>
          Save
        </button>
        <button
          className="border px-2  rounded-md"
          onClick={() => {
            props.setEdit((prevEdit) => ({
              ...prevEdit,
              backgroundCheck: false,
            }));
            props.setEmployee(props.oldData);
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  );
}

export const CustomPhoneInput = ({
  name,
  value,
  onPhoneInputChange,
  customPlaceholder,
}) => {
  return (
    <PhoneInput
      inputClass="border border-gray-300 rounded-2 p-2 focus:outline-none focus:border-blue-500"
      country={"in"}
      value={value}
      onChange={(value) => onPhoneInputChange(value, name)}
      placeholder={customPlaceholder}
      containerStyle={{width:"12.5rem",marginTop:".5rem"}}
      inputStyle={{height:35}}
      dropdownStyle={{width:"15rem"}}
    />
  );
}