import usaEmployee from "../../pages/addUsaEmployee/usaEmployee.json";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  intialUsEmployee : usaEmployee,
  feildsDisabled : false,
};

export const usaEmployeeSlice = createSlice({
    name: 'usaEmployee',
    initialState,
    reducers: {
        updatePersonalDetails: (state, action) => {
            const { name, value } = action.payload;
            state.intialUsEmployee.personalDetails[name] = value;
          },
          updateDetails: (state, action) => {
            const { name, value , directory} = action.payload;
            state.intialUsEmployee[directory][name] = value;
          },
          updateEmployeeWorkExperience: (state, action) => {
            const { value, path ,index} = action.payload;
            state.intialUsEmployee.workExperience[index][path] = value;
          },
          updateEmployeeDetails: (state, action) => {
            const { value, path } = action.payload;
            const pathSegments = path.split(".");
            for (const pathSegment of pathSegments) {
              if (pathSegment === pathSegments[pathSegments.length - 1]) {
                state.intialUsEmployee[pathSegment] = value;
              } else {
                state.intialUsEmployee = state[pathSegment];
              }
            }
            //state.employeeDetails["name"]=action.payload;
          },
          addWorkExperience:(state,action)=>{
            let workExperience={
              "companyName": "",
              "designation": "",
              "startDate": "",
              "endDate": "",
              "documents": []
            }
            state.intialUsEmployee.workExperience.push(workExperience);
          },
          updateUSAEmployeeJson: (state,action) => {
            const temp = action.payload;
            state.intialUsEmployee = temp;
          },
          documentsMandatory: (state, action)=>{
            const temp = action.payload;
            state.intialUsEmployee.documents = temp;
            console.log(temp);
          },
          setImmigrationDocumentsList: (state, action)=>{
            const temp = action.payload;
            state.intialUsEmployee.immigrationDocumentsList = temp;
            console.log(temp , "immigrationDocumentsList");
          },
          resetState: (state) => {
            return initialState;
          },
          feildsDisabled :(state , action)=>{
            console.log(action.payload , "filedDisabled");
            state.feildsDisabled = action.payload;
          }

    }
})

export const { addEmployee, removeEmployee ,  updateEmployeeDetails,
  updateEmployeeWorkExperience,
  addWorkExperience,updateUSAEmployeeJson } = usaEmployeeSlice.actions;
export default usaEmployeeSlice.reducer;