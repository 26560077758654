import React, { useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';


const CheckMail = () => {
  const navigate = useNavigate()

  const redirectt = () => {
    toast.success("Check your Mail and set the Password before Login");
    navigate("/login");

  };
    return (
        <div className="flex min-h-screen items-center justify-center">
          <div className="text-center text-2xl text-blue-500">
            <p>Form Submitted Successfully!</p>
            <p>Please Check Your Mail to set the Password</p>
            <button className='bg-blue-400 rounded-md p-[1%] m-[2%] text-white hover:bg-blue-500' onClick={redirectt}>
              Login</button>
          </div>
        </div>
      );
}

export default CheckMail
