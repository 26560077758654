import React, { useEffect, useState } from "react";
import { upload_file_to_s3 } from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import FileUploadComponent from "../../Components/customComponents/customUpload";
import Select from "../../Components/customComponents/customSelect";
import { usaEmployeeSlice } from "../../store/reducers/usaEmployeeSlice";

const UsaDocuments = ({ onchange, isfilled }) => {
  const documents = useSelector((state) => state.usaEmployee.intialUsEmployee.documents);
  const immigrationDocumentsList = useSelector((state) => state.usaEmployee.intialUsEmployee.immigrationDocumentsList);
  const feildDisabled = useSelector((state) => state.usaEmployee.feildsDisabled);
  const Originaldocuments = useSelector((state) => state.usaEmployee.intialUsEmployee.documents);
  const [documentType, setDocumentype]= useState("") 
  const dispatch = useDispatch()
  const handleUpload = async (new_file, label) => {
    const filename = new_file.target.name;
    const file = new_file.target.files[0];
    
    try {
      const maxSizeInBytes = 10 * 1024 * 1024; // max 10mb
      if (file?.size > maxSizeInBytes) {
        return;
      }
      const s3path = await upload_file_to_s3(file);
      const date = new Date();
      const formattedDate = date.toISOString();
      const documentValue = {
        fileName: new_file.target.name,
        name: file.name,
        resourceName: label,
        uploadDate: formattedDate,
        comment: "",
        extension: "",
        s3Document: {
          ...s3path.data.data,
          originalFileName: new_file.target.name,
        },
      };
      onchange(
        { target: { name: filename, value: documentValue } },
        "documents"
      );
    } catch (e) {
      console.error(e, "erororr345");
    }
  };
  const decideDocuments = (e) => {
    const { value, name } = e.target;
    dispatch(usaEmployeeSlice.actions.setImmigrationDocumentsList(value))
    setDocumentype(value)
    }

    useEffect(()=>{
      if(immigrationDocumentsList!==""){
        setDocumentype(immigrationDocumentsList)
      }
    },[])
  return (
    <div>
      <div className="flex">
      <p className="font-medium">OTHER DOCUMENTS:</p>
      <Select
              name={"immigrationDocumentsList"}
              onChange={(e)=>decideDocuments(e)}
              value={documentType}
              disabled={feildDisabled}
              options={[
            { value: "f1Student", label: "F1-Student" },
            { value: "optcpt", label: "OPT/CPT" },
            { value: "h1b", label: "H1B" },
            { value: "other", label: "other" },
          ]}
        />
      {documentType===""&&<p className="text-red-600 mx-3">Please Select a option</p>}
      </div>
      <div className="flex flex-col p-6 w-[60%]">
        {(documentType ==="f1Student" ||documentType==="other") && <FileUploadComponent
          name="offerLetter"
          label="Offer Letter"
          handleUploade={e=>handleUpload(e,"Offer Letter")}
          isMandatory={true}
          isDisabled={feildDisabled}
          Originaldocuments={Originaldocuments.offerLetter?.name}
          className={`${
            !documents.offerLetter?.uploadDate &&
            isfilled && immigrationDocumentsList !=="other" &&
            "true"
          }`}
        />}
        {(documentType==="h1b"||documentType==="other") &&<FileUploadComponent
          name="agreementLetter"
          label="Agreement Letter"
          handleUploade={e=>handleUpload(e,"Agreement Letter")}
          isMandatory={true}
          isDisabled={feildDisabled}
          Originaldocuments={Originaldocuments.agreementLetter?.name}
          className={`${
            !documents.agreementLetter?.uploadDate &&
            isfilled && immigrationDocumentsList !=="other" &&
            "true"
          }`}
        />}
        {(documentType==="h1b"||documentType==="other") &&<FileUploadComponent
          name="employmentDocument"
          label="Employment Document"
          handleUploade={e=>handleUpload(e,"Employment Document")}
          isMandatory={true}
          isDisabled={feildDisabled}
          Originaldocuments={Originaldocuments.employmentDocument?.name}
          className={`${
            !documents.employmentDocument?.uploadDate &&
            isfilled && immigrationDocumentsList !=="other" &&
            "true"
          }`}
        />}
        {(documentType==="h1b"||documentType==="other") &&<FileUploadComponent
          name="h1BDocuments"
          label="H-1B Document"
          handleUploade={e=>handleUpload(e,"H-1B Document")}
          isDisabled={feildDisabled}
          Originaldocuments={Originaldocuments.h1BDocuments?.name}
        />}
        {(documentType==="h1b"||documentType==="other") &&<FileUploadComponent
          name="f1VisaDocuments"
          label="F1 VISA Document"
          handleUploade={e=>handleUpload(e,"F1 VISA Document")}
          isMandatory={true}
          isDisabled={feildDisabled}
          Originaldocuments={Originaldocuments.f1VisaDocuments?.name}
          className={`${
            !documents.f1VisaDocuments?.uploadDate &&
            isfilled && immigrationDocumentsList !=="other" &&
            "true"
          }`}
        />}
        {(documentType==="optcpt"||documentType==="other") &&<FileUploadComponent
          name="opt"
          label="OPT Document"
          handleUploade={e=>handleUpload(e,"OPT Document")}
          isMandatory={true}
          isDisabled={feildDisabled}
          Originaldocuments={Originaldocuments.opt?.name}
          className={`${
            !documents.opt?.uploadDate && isfilled && immigrationDocumentsList !=="other" && "true"
          }`}
        />}
        {(documentType==="h1b"||documentType==="other") &&<FileUploadComponent
          name="ead"
          label="EAD Document"
          handleUploade={e=>handleUpload(e,"EAD Document")}
          isMandatory={true}
          isDisabled={feildDisabled}
          Originaldocuments={Originaldocuments.ead?.name}
          className={`${
            !documents.ead?.uploadDate &&
            isfilled && immigrationDocumentsList !=="other" &&
            "true"
          }`}
        />}
        {(documentType!=="h1b" && documentType!=="" && documentType!==undefined) &&<FileUploadComponent
          name="i20Form"
          label="I-20"
          handleUploade={e=>handleUpload(e,"I-20")}
          isMandatory={true}
          isDisabled={feildDisabled}
          Originaldocuments={Originaldocuments.i20Form?.name}
          className={`${
            !documents.i20Form?.uploadDate && isfilled && immigrationDocumentsList !=="other" && "true"
          }`}
        />}
        {(documentType!=="" && documentType!==undefined)&&<FileUploadComponent
          name="educationalDocuments"
          label="Educational Documnets"
          handleUploade={e=>handleUpload(e,"Educational Documnets")}
          isDisabled={feildDisabled}
          Originaldocuments={Originaldocuments.educationalDocuments?.name}
        />}
      </div>
    </div>
  );
};

export default UsaDocuments;
