import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TextField from "../../Components/customComponents/customTextField";
import { usaEmployeeSlice } from "../../store/reducers/usaEmployeeSlice";
import { set } from "lodash";
import Select from "../../Components/customComponents/customSelect";

const UsaPersonalDetails = ({
  personalDetailsError,
  setPersonalDetailsError,
  change,
  isfilled,
}) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.usaEmployee.intialUsEmployee.personalDetails);
  const feildsDisabled = useSelector(state=> state.usaEmployee.feildsDisabled)
  const [errorMessageState, setErrorMessageState] = useState(false);

  const onchange = (e, directory) => {
    let { name, value } = e.target;
    if (name === "ssn") {
      if (/[^0-9-]/.test(value) && value !== "") return; // block non-numeric input
      value = formatSSN(value);
      if (value.length < 9 && value.length != 0) {
        const temp = {...personalDetailsError};
        temp[name] = "SSN should be 9 digits";
        setPersonalDetailsError(temp);
      } else {
        const temp = {...personalDetailsError};
        temp[name] = "";
        setPersonalDetailsError(temp);
      }
    } else if (name === "passportNumber") {
      if (/[^0-9-]/.test(value) && value !== "") return; // block non-numeric input
      if (value.length > 9) return;
      
      const error =
          value.length < 9 && value.length!==0
          ?"Passport Number should be 9 digits"
          :""  ;
        const temp = {...personalDetailsError};
        temp[name] = error;
        setPersonalDetailsError(temp);
    } else if (name === "dob") {
      const dateOfJoining = value;
      const date = new Date(dateOfJoining);
      value = date.toISOString();
    } else if (name === "email") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(value) && value !== "") {
        setPersonalDetailsError({
          ...personalDetailsError,
          [name]: "Invalid email address",
        });
      } else {
        setPersonalDetailsError({ ...personalDetailsError, [name]: "" });
      }
    }
    dispatch(
      usaEmployeeSlice.actions.updateDetails({ name, value, directory })
    );
  };
  const formatSSN = (value) => {
    // Remove non-numeric characters
    let numericValue = value.replace(/\D/g, "");
    if (numericValue.length > 9) {
      numericValue = numericValue.slice(0, 9); // Limit to 9 digits
    }
    return numericValue.replace(/(\d{3})(\d{3})(\d{3})/, "$1-$2-$3");
  };
  return (
    <div className="ml-10 mb-9">
      <p className="font-medium">PERSONAL DETAILS</p>
      <div className="flex flex-col justify-evenly p-6 items-end justify-self-start">
        <TextField
          label={"Email"}
          type={"email"}
          isMandatory={true}
          onChange={(e) => onchange(e, "personalDetails")}
          disabled={feildsDisabled}
          name={"email"}
          value={state.email}
          error={personalDetailsError.email}
          min={0}
          className={`${!state.email && isfilled && "border border-red-500"}`}
        />
        <TextField
          label={"SSN(Social Security Number)"}
          type={"text"}
          isMandatory={true}
          onChange={(e) => onchange(e, "personalDetails")}
          disabled={feildsDisabled}
          name={"ssn"}
          value={state.ssn}
          error={personalDetailsError.ssn}
          min={0}
          className={`${!state.ssn && isfilled && "border border-red-500"}`}
        />
        <TextField
          label={"Driving License"}
          type={"text"}
          isMandatory={true}
          onChange={(e) => onchange(e, "personalDetails")}
          disabled={feildsDisabled}
          name={"drivingLicense"}
          value={state.drivingLicense}
          className={`${
            !state.drivingLicense && isfilled && "border border-red-500"
          }`}
        />
        <TextField
          label={"Passport Number"}
          type={"text"}
          isMandatory={true}
          onChange={(e) => onchange(e, "personalDetails")}
          disabled={feildsDisabled}
          name={"passportNumber"}
          value={state.passportNumber}
          error={personalDetailsError.passportNumber}
          min={0}
          className={`${
            !state.passportNumber && isfilled && "border border-red-500"
          }`}
        />
        <Select
              label={"Gender"}
              isMandatory={true}
              name={"gender"}
              value={state.gender}
              onChange={(e) => onchange(e, "personalDetails")}
              options={[
                { label: "MALE", value: "MALE" },
                { label: "FEMALE", value: "FEMALE" },
                { label: "DO NOT WANT TO DISCLOSE", value: "DO_NOT_WANT_TO_DISCLOSE" },
              ]}
              disabled={feildsDisabled}
              className={`${
                !state.gender &&
                 isfilled &&
                 "border border-red-500"
               }`}
            />
        <TextField
          label={"Date Of Birth"}
          type={"date"}
          isMandatory={true}
          onChange={(e) => onchange(e, "personalDetails")}
          disabled={feildsDisabled}
          name={"dob"}
          value={state.dob ? state.dob.slice(0, 10) : ""}
          className={`${!state.dob && isfilled && "border border-red-500"}`}
          max={new Date().toISOString()?.split("T")[0]}
        />

        <TextField
          label={"Emergency Contact Name"}
          type={"text"}
          isMandatory={true}
          onChange={(e) => onchange(e, "personalDetails")}
          disabled={feildsDisabled}
          name={"emergencyContactName"}
          value={state.emergencyContactName}
          className={`${
            !state.emergencyContactName && isfilled && "border border-red-500"
          }`}
        />

        <TextField
          label={"Relation"}
          type={"text"}
          isMandatory={true}
          onChange={(e) => onchange(e, "personalDetails")}
          disabled={feildsDisabled}
          name={"emergencyContactRelation"}
          value={state.emergencyContactRelation}
          className={`${
            !state.emergencyContactRelation &&
            isfilled &&
            "border border-red-500"
          }`}
        />

        <TextField
          label={"Address"}
          type={"text"}
          isMandatory={true}
          onChange={(e) => onchange(e, "personalDetails")}
          disabled={feildsDisabled}
          name={"emergencyContactAddress"}
          value={state.emergencyContactAddress}
          className={`${
            !state.emergencyContactAddress &&
            isfilled &&
            "border border-red-500"
          }`}
        />
      </div>
    </div>
  );
};

export default UsaPersonalDetails;
