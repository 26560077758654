import React, { useState, useEffect } from "react";
import TextField from "../customComponents/customTextField";
//import Button from "../customComponents/customButton"
import { Snackbar, Alert, Dialog, DialogTitle, Button, DialogActions  } from "@mui/material";
import { useCreateTenantUpdateMutation } from "../../api/tenantApi";
import { genericHeaders, serviceUrl } from "../../utils/apiConfig";
import Loader from "../../Components/customComponents/customLoader";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "../../css/countrycodedropdown.css";
import { validateMobileNumber } from "../../utils";
import axios from "axios";
import Select from "../customComponents/customSelect";

const TenantForm = () => {
  const [loading, setLoading] = useState(false);
  const [names, setNames] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState({
    open: false,
    error: false,
    message: "",
  });
  const [formData, setFormData] = useState({
    tenantName: "",
    description: "",
    email: "",
    tenantCountry : "",
    phoneNumber: "",
    logoUrl: "",
    createdDate: "",
    address: "",
  });
  const [error, setError] = useState({
    tenantName: "",
    email: "",
    country : "",
    phoneNumber: "",
    address: "",
  });
  const [createTenantUpdate] = useCreateTenantUpdateMutation();

  useEffect(() => {
    getNames();
  }, []);

  const getNames = async () => {
    const url = `${serviceUrl}/api/tenant/getTenantNames`;
    try {
      let response = await axios.get(url, { headers: genericHeaders() });
      if (response.status === 200) {
        let newData = response.data;
        setNames(newData);
      }
    } catch (err) {
      let blocker = { ...error };
      blocker.tenantName = "Something Went Wrong";
      setError(blocker);
      console.error(err);
    }
  };

  const handleChange = (evt) => {
    if (evt.target) {
      let newData = { ...formData };
      newData[evt.target.name] = evt.target.value;
      setFormData(newData);
    }
  };

  const handleCheckError = async (evt) => {
    let newData = { ...error };
    if (evt.target.value === "") {
      if (evt.target.name === "tenantName") {
        newData.tenantName = "Please Enter Tenant Name";
      } else if (evt.target.name === "email") {
        newData.email = "Please enter your email";
      } else if (evt.target.name === "address") {
        newData.address = "Please Enter Full Address";
      }
    } else if (evt.target.name === "tenantName") {
      let tenantNames = [...names];
      tenantNames.every((item) => {
        if (item.toLowerCase() === evt.target.value.toLowerCase()) {
          newData.tenantName = "Name is already in use";
          return false;
        } else {
          newData.tenantName = "";
        }
        return true;
      });
    } else if (
      evt.target.name === "email" &&
      !emailPattern.test(evt.target.value)
    ) {
      newData.email = "Please enter a valid email address";
    } else {
      newData.email = "";
    }
    setError(newData);
  };

  const handlePhoneCheck = (value) => {
    let newData = { ...error };
    const validMobile = validateMobileNumber(value);
    if (value === "") {
      newData.phoneNumber = "Please enter mobile number";
    } else {
      if (!validMobile) {
        newData.phoneNumber = "Please enter vaild mobile number";
      } else {
        newData.phoneNumber = "";
      }
    }
    console.log(newData);
    setError(newData);
  };

  const handleSuccessSnackOpen = (open, error, message) => {
    let snackData = { ...successSnackbarOpen };
    snackData.open = open;
    snackData.error = error;
    snackData.message = message;
    setSuccessSnackbarOpen(snackData);
  };
  const handleSucessSnackbarClose = () => {
    setSuccessSnackbarOpen(false);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };
  const handleSubmit = async () => {
    try {
      setLoading(true);
      const response = await createTenantUpdate({ payload: formData });
      if (response.error.originalStatus === 201) {
        console.log(isDialogOpen, "DIALOGUEOPEN");

        handleSuccessSnackOpen(true, false, "Tenant Created successfully");
        setIsDialogOpen(true);
        setFormData({
          tenantName: "",
          description: "",
          email: "",
          tenantCountry : "",
          phoneNumber: "",
          logoUrl: "",
          createdDate: "",
          address: "",
        });
      } else {
        handleSuccessSnackOpen(true, true, "Failed to update tenant");
      }
    } catch (error) {
      console.error("Error updating tenant:", error);
      handleSuccessSnackOpen(
        true,
        true,
        "An error occurred while updating tenant"
      );
    } finally {
      setLoading(false);
    }
  };

  const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

  return (
    <>
      {loading && <Loader />}
      <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>
          A verification link is send to the provided email address. Clicking on
          this link enables the tenant to create a password. Upon password
          creation, redirection to the login page occurs.{" "}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleCloseDialog}>CLOSE</Button>
        </DialogActions>
      </Dialog>
      <div className="flex flex-col items-end mt-24 w-[50%] h-full">
        <TextField
          name="tenantName"
          label="Tenant Name"
          type="text"
          value={formData.tenantName}
          onChange={handleChange}
          onBlur={(e) => handleCheckError(e)}
          error={error.tenantName}
          isMandatory={true}
        />
        <TextField
          name="description"
          label="Description"
          type="text"
          value={formData.description}
          onChange={handleChange}
        />
        <TextField
          name="email"
          label="Email"
          type="text"
          value={formData.email}
          onChange={handleChange}
          onBlur={(e) => handleCheckError(e)}
          error={error.email}
          isMandatory={true}
        />

        <Select
          name="tenantCountry"
          label="Country"
          options={[
            { value: "INDIA", label: "INDIA" },
            { value: "USA", label: "USA" },
            { value: "OTHER", label: "OTHER" },
          ]}
          value={formData.tenantCountry}
          onChange={handleChange}
          isMandatory={true}
        />

        <div className="flex">
          <p className="text-sm font-medium text-gray-700 ml-[1.4rem] mt-1">
            Phone Number
            <span className="text-red-500 text-xl mr-[0.5rem]">*</span>
          </p>
          <CustomPhoneInput
            name="phoneNumber"
            value={formData.phoneNumber}
            onPhoneInputChange={handleChange}
            customPlaceholder="Enter Phone Number"
            isMandatory={true}
            onBlurEvent={handlePhoneCheck}
            error={error.phoneNumber}
          />
        </div>

        <TextField
          name="logoUrl"
          label="Logo URL"
          type="text"
          value={formData.logoUrl}
          onChange={handleChange}
        />
        <div className=" mr-32 text-red-400 text-xs mb-6">
          Note:Please give the AWS URL
        </div>
        <div className="mb-4 flex items-center">
          <label
            htmlFor="address"
            className="block text-sm font-medium text-gray-700 mr-5"
          >
            Address <span className="text-red-500 text-xl">*</span>
          </label>
          <div className="flex flex-col">
            <textarea
              className="border border-gray-400 w-60 px-3 py-2 rounded-md shadow-sm focus:ring-blue-500"
              name="address"
              id="address"
              type="text"
              value={formData.address}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="text-red-400 text-xs mr-32">
          Note:Please enter full address
        </div>
        <button
          onClick={handleSubmit}
          className={` w-[11.5rem] my-4 py-2  font-bold ${
            formData.tenantName !== "" &&
            formData.email !== "" &&
            formData.phoneNumber !== "" &&
            formData.address !== "" &&
            formData.tenantCountry!== "" &&
            error.email === "" &&
            error.phoneNumber === "" &&
            error.tenantName === "" &&
            error.address === ""
              ? "text-white  bg-blue-500 rounded-[4px] border border-blue-500 "
              : "text-black border border-gray-500  bg-gray-500 rounded-[4px] "
          }`}
          disabled={
            formData.tenantName === "" ||
            formData.address === "" ||
            formData.email === "" ||
            formData.phoneNumber === "" ||
            formData.tenantCountry === "" ||
            error.email !== "" ||
            error.phoneNumber !== "" ||
            error.tenantName !== ""
          }
        >
          Submit
        </button>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          open={successSnackbarOpen.open}
          autoHideDuration={2000}
          onClose={handleSucessSnackbarClose}
        >
          <Alert
            severity={successSnackbarOpen.error ? "error" : "success"}
            variant="filled"
            onClose={handleSucessSnackbarClose}
          >
            {successSnackbarOpen.message}
          </Alert>
        </Snackbar>
      </div>
    </>
  );
};

export default TenantForm;

export const CustomPhoneInput = ({
  name,
  value,
  onPhoneInputChange,
  customPlaceholder,
  onBlurEvent,
  error,
}) => {
  
  return (
    <div className="flex flex-col items-end">
      <PhoneInput
        inputClass="border border-gray-300 rounded-2 p-2 focus:outline-none focus:border-blue-500"
        className="mb-4 ml-2"
        containerStyle={{ width: "15rem" }}
        inputStyle={{ height: 40 }}
        dropdownStyle={{ width: "15rem" }}
        country={"in"}
        value={value}
        onChange={(formattedValue) =>
          onPhoneInputChange({ target: { name: name, value: formattedValue } })
        }
        placeholder={customPlaceholder}
        onBlur={(e) => onBlurEvent(e.target.value)}
      />
      {error !== "" ? (
        <p className="text-red-500 text-sm italic mr-10 -mt-3 mb-4">{error}</p>
      ) : (
        <></>
      )}
    </div>
  );
};
