import React, { useState } from "react";
import arrow from "../../src/Icons/arrow.svg";
import arrowHovered from "../../src/Icons/arrowHovered.svg";
import { useNavigate } from "react-router-dom";

import MonthDisplay from "./Months";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { get_file_from_s3 } from "../utils";

const PaySlipEmployee = ({ isError }) => {
  const [showSalary, setShowSalary] = useState(false);
  const [hovered, setHovered] = useState(false);
  const navigate = useNavigate();
  const toggleSalaryVisibility = () => {
    setShowSalary(!showSalary);
  };
  const { paySlipData } = useSelector((state) => state.payroll);
  console.log(paySlipData, "PAYSLIPDATA");
  const handleDownload = async (file) => {
    try {
      const res = await get_file_from_s3(file?.fileName);
      const s3Url = res;
      if (!s3Url) {
        toast.error("Failed to fetch the file");
        return;
      }

      const response = await fetch(s3Url);
      const blob = await response.blob();
      const downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(blob);
      const urlParts = s3Url.split("/");
      const fullFileName = urlParts[urlParts.length - 1]; //need to change as per filename
      const [filename] = fullFileName.split("?");
      downloadLink.setAttribute("download", filename);
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  return (
    <div>
    {Object.keys(paySlipData?.data || {}).length !== 0 ? (
        <div className="border border-black p-6 rounded-lg shadow-md hover:shadow-lg transition-transform transform hover:scale-105 w-[200%] bg-blue-50 ml-6">
          <div className="flex flex-col gap-4 ">
            <div className="flex justify-between">
              <p className="text-lg text-black text-center font-serif  font-bold">
                PaySlip:
              </p>
              <button
                onClick={() => {
                  navigate("/payslip");
                }}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
              >
                <img
                  className=" mt-[-26px]"
                  src={!hovered ? arrow : arrowHovered}
                  alt="arrow"
                />
              </button>
            </div>
            <h1 className="text-blue-500 font-bold text-2xl m-auto flex items-center">
              <span className="mr-2">
                <MonthDisplay monthNumber={paySlipData?.data?.payrollMonth} />
              </span>
              {paySlipData?.data?.payrollYear}
            </h1>
            <div className="flex flex-col justify-center items-center">
              <div className="flex gap-4 w-full max-w-2xl">
                <p className="text-lg text-black text-center font-serif font-semibold w-1/3">
                  Gross Pay :
                </p>
                <p className="text-lg text-black font-serif font-semibold w-2/3 text-right ">
                  {!showSalary ? (
                    "*****"
                  ) : (
                    <>
                      {"₹"}
                      {paySlipData?.data?.totalGrossPay}
                    </>
                  )}
                </p>
              </div>
              <div className="flex gap-4 w-full max-w-2xl">
                <p className="text-lg text-black text-center font-serif font-semibold w-1/3 ">
                  Deduction:
                </p>
                <p className="text-lg text-black  font-serif font-semibold w-2/3 text-right">
                  {!showSalary ? (
                    "*****"
                  ) : (
                    <>
                      {"₹"}
                      {paySlipData?.data?.totalDeduction}
                    </>
                  )}
                </p>
              </div>
              <div className="flex gap-4 w-full max-w-2xl">
                <p className="text-lg text-black text-center font-serif font-semibold w-1/3 ">
                  Net Pay :
                </p>
                <p className="text-lg text-black  font-serif font-semibold w-2/3 text-right">
                  {!showSalary ? (
                    "*****"
                  ) : (
                    <>
                      {"₹"}
                      {paySlipData?.data?.totalNetPay}
                    </>
                  )}
                </p>
              </div>
            </div>
            <div className="flex justify-between">
              <button
                className="text-blue-500 font-bold text-xl text-right"
                onClick={() =>
                  handleDownload(
                    paySlipData?.data?.paySlipDetails?.s3Document
                  )
                }
              >
                Download
              </button>
              {!showSalary ? (
                <button
                  className="text-blue-500 font-bold text-xl text-right"
                  onClick={() => {
                    toggleSalaryVisibility();
                  }}
                >
                  Show Salary
                </button>
              ) : (
                <button
                  className="text-blue-500 font-bold text-xl text-right"
                  onClick={() => {
                    toggleSalaryVisibility();
                  }}
                >
                  Hide Salary
                </button>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="flex justify-center items-center border border-black p-6 rounded-lg shadow-md hover:shadow-lg transition-transform transform hover:scale-105 w-[150%] bg-blue-50 ml-6 h-[200px]">
          <h1 className="text-center font-bold text-lg">
            {" "}
            { paySlipData?.message ??
               "No results found"}
          </h1>
        </div>
      )}
    </div>
  );
};

export default PaySlipEmployee;
