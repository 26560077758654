import React, { useEffect, useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Select, MenuItem, Checkbox, ListItemText, InputLabel, Snackbar, Alert} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useGetEmployeeListQuery } from '../../api/addEmployeeApi';
import { cloneDeep } from 'lodash';
import { useGetAllProjectsQuery,useGetAllEmpProjectsQuery } from '../../api/projectsApi';
import { genericHeaders, serviceUrl } from '../../utils/apiConfig';
import axios from 'axios';

const ProjectsAssignForManager = () => {

  const [dialogOpen,setDialogOpen] = useState(false);
  const [employees,setEmployees] = useState([]);
  const [employeeData,setEmployeeData] = useState([]);
  const [projectIds,setProjectIds] = useState([]);
  const [selectedProjects,setSelectedProjects] = useState([]);
  const [employeeProjects,setEmployeeProjects] = useState([]);
  const [snack, setSnack] = useState({
    open: false,
    error: false,
    message: "",
  });

  const {data,isSuccess} = useGetEmployeeListQuery();
  const {data:projectData,isSuccess:projectSuccess} = useGetAllProjectsQuery({}, {refetchOnMountOrArgChange:true});
  const {data:empProjects,isSuccess:empProjectsSuccess,refetch: isRefetchEmpProjects} = useGetAllEmpProjectsQuery({}, {refetchOnMountOrArgChange:true});

  useEffect(() => {
    if(isSuccess){
      let newData = cloneDeep(data);
      setEmployeeData(newData);
    }
    if(projectSuccess){
      let projects = cloneDeep(projectData);
      setProjectIds(projects);
    }
  },[isSuccess,projectSuccess,projectData])
  useEffect(() => {
    if(empProjectsSuccess){
      let newData = cloneDeep(empProjects);
      setEmployeeProjects(newData);
    }
  },[empProjectsSuccess,empProjects])
  const handleSnackOpen = (open,error,message) => {
    let snackData = {...snack};
    snackData.open = open;
    snackData.error = error;
    snackData.message = message;
    setSnack(snackData);
  };
  const handleSnackClose = () => {
    let data = {
      open: false,
      error: false,
      message: "",
    }
    setSnack(data);
  }
  const  handleDialogOpen = () => {
    setDialogOpen(true);
  }
  const handleDialogClose = () => {
    setDialogOpen(false);
    setEmployees([]);
    setSelectedProjects([]);
  }
  const handleEmployeeToggle = (employeeId) => () => {
    const currentIndex = employees.indexOf(employeeId);
    const newEmployees = [...employees];
    if (currentIndex === -1) {
      newEmployees.push(employeeId);
    } else {
      newEmployees.splice(currentIndex, 1);
    }
    setEmployees(newEmployees);
  };
  const handleProjectToggle = (projectId)  => () => {
    const currentIndex = selectedProjects.indexOf(projectId);
    const newProject = [...selectedProjects];
    if (currentIndex === -1) {
      newProject.push(projectId);
    } else {
      newProject.splice(currentIndex, 1);
    }
    setSelectedProjects(newProject);
  }
  const handleEmployees = (evt) => {
    const selectedValues = Array.isArray(evt.target.value) ? evt.target.value : [evt.target.value];
    setEmployees(selectedValues); 
  }
  const handleProjects = (evt) => {
    const selectedValues = Array.isArray(evt.target.value) ? evt.target.value : [evt.target.value];
    setSelectedProjects(selectedValues); 
  }
  const handleSave = async () => {
    let payload = {
      "employeeIds": employees,
      "projectIds": selectedProjects
    }
     try{
      let url = `${serviceUrl}/api/project/projectallocation`
      let response = await axios.post(url,payload,{headers : genericHeaders()})
      if(response.status === 200){
        handleSnackOpen(true,false,"Data Updated Successfully")
        handleDialogClose();
        isRefetchEmpProjects();
      }
     }catch (err){
       console.error(err);
     }
  };
  const columns = [
    { field: 'id', headerName: 'S no.', width: 100, sortable: false },
    { field: 'employeeIds', headerName: 'Employee', width: 200, sortable: false },
    { field: 'projectIds', headerName: 'Projects', flex: 1, sortable: false, renderCell: (params) => (
        <div>
          {params.row.projectIds.map((item, i) => (
            <span key={i}>{i === params.row.projectIds.length - 1 ? item : `${item}, `}</span>
          ))}
        </div>
      )
    }
  ]
  const rowData = employeeProjects.map((row, index) => ({
    id: index + 1,
    employeeIds: row.employeeIds[0],
    projectIds: row.projectIds
}))

  return (
    <div className='mt-28'>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={snack.open}
        autoHideDuration={2000}
        onClose={handleSnackClose}
      >
        <Alert
          severity={snack.error ? "error" : "success"}
          variant="filled"
          onClose={handleSnackClose}
        >{snack.message}
        </Alert>
      </Snackbar>
      <Dialog open={dialogOpen} onClose={handleDialogClose} maxWidth={'xs'} fullWidth>
            <DialogTitle>Assign Projects</DialogTitle>
            <DialogContent>
              <div className='flex flex-col mt-3 space-y-5'>
              <FormControl className="w-full">
                <InputLabel htmlFor="employees">Employee</InputLabel>
                <Select
                  id='employees'
                  label="Empolyee"
                  name="employeeIds"
                  className="w-full"
                  multiple
                  value={employees}
                  onChange={handleEmployees}
                  MenuProps={{
                    sx: {
                      '& .MuiList-root': {
                        maxHeight: 300,
                      },
                      "& .MuiInputBase-root": {
                        height: 50
                      },
                    },
                  }}
                  renderValue={(selected) => {
                    return selected
                      .map((value) => {
                        const selectedItem = employeeData.find(
                          (item) => item.id === value
                        );
                        return selectedItem ? selectedItem.name : "";
                      })
                      .join(", ");
                  }}
                >
                  {employeeData.map((item) => {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        <Checkbox
                          checked={employees.indexOf(item.id) !== -1}
                          onChange={handleEmployeeToggle(item.id)}
                        />
                        <ListItemText primary={item.name} />
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl className="w-full">
                <InputLabel htmlFor="projects">Projects</InputLabel>
                <Select
                  id='projects'
                  label="Projects"
                  name="projectId"
                  className="w-full"
                  multiple
                  value={selectedProjects}
                  onChange={handleProjects}
                  MenuProps={{
                    sx: {
                      '& .MuiList-root': {
                        maxHeight: 300,
                      },
                      "& .MuiInputBase-root": {
                        height: 50
                      },
                    },
                  }}
                  renderValue={(selected) => {
                    return selected
                      .map((value) => {
                        const selectedItem = projectIds.find(
                          (item) => item._id === value
                        );
                        return selectedItem ? selectedItem.project : "";
                      })
                      .join(", ");
                  }}
                >
                  {projectIds.map((item) => {
                    return (
                      <MenuItem key={item._id} value={item._id}>
                        <Checkbox
                          checked={selectedProjects.indexOf(item._id) !== -1}
                          onChange={handleProjectToggle(item._id)}
                        />
                        <ListItemText primary={item.project} />
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDialogClose}>Close</Button>
                <Button onClick={handleSave} disabled={employees.length === 0 || selectedProjects.length === 0}>Save</Button>
            </DialogActions>
      </Dialog> 
      <div className='w-[90%] mx-auto flex justify-end'>
         <Button color='primary' variant='contained' onClick={handleDialogOpen}>Assign Projects</Button>
      </div>
      <div className='h-[70vh] w-[90%] mx-auto mt-5'>
        <DataGrid
          rows={rowData}
          columns={columns}
          hideFooterPagination
          rowHeight={50}
          disableColumnMenu
          disableColumnFilter
          hideFooter
          rowSelection={false}
          sx={{
            fontSize: 16,
            fontFamily: 'sans-serif',
            "& .MuiDataGrid-columnHeaderTitle": {
              overflow: "visible",
              lineHeight: "1.43rem",
              whiteSpace: "normal",
              fontSize: '1.35rem'
            }
          }}
          columnHeaderHeight={60}
        />
      </div>
    </div>
  )
}

export default ProjectsAssignForManager