import React, { useEffect, useState } from 'react'
import { updateUSAEmployeeJson, usaEmployeeSlice } from '../../store/reducers/usaEmployeeSlice'
import { useSelector, useDispatch } from 'react-redux'
import UsaProfessionalDetails from './usaProfessionalDetails'
import UsaPersonalDetails from './usaPersonalDetails'
import UsaBankDetails from './usaBankDetails'
import UsaDocuments from './usaDocuments'
import { Step, StepLabel, Stepper } from '@material-ui/core'
import { toast } from 'react-toastify'
import { getToken } from '../../services/Token'
import jwtDecode from 'jwt-decode'
import usaPayload from './usaPayload.json'
import { genericHeaders, serviceUrl } from '../../utils/apiConfig'
import axios from 'axios'
import Experience from './usaExperience'
import { useNavigate, useParams } from 'react-router-dom'
import { useLazyCreateProfileQuery } from '../../api/profileApi'
import Loader from '../../Components/customComponents/customLoader'

const AddUsaEmployee = () => {
    const dispatch = useDispatch()
    const state = useSelector((state) => state.usaEmployee.intialUsEmployee);
    const [errorMessageState , setErrorMessageState] = useState([false, false, false, false]);
    const [stepperNumber , setStepperumber] = useState([0,3]);
    const [completed, setCompleted] = useState([false, false, false, false]);
    const [personalDetailsError, setPersonalDetailsError] = useState({email:"",ssn:"",passportNumber:"" })
    const { empId } = useParams();
    const [isLoading , setIsLoading] = useState(false)
    const [createProfile, employeeData] = useLazyCreateProfileQuery();
    const feildDisabled = useSelector((state) => state.usaEmployee.feildsDisabled);
    const [professionalEmailValidation , setProfessionalEmailValidation] = useState(false)
    const navigate = useNavigate();

    const [steps , setSteps] = useState([
      "PROFESSIONAL DETAILS",
      "PERSONAL DETAILS",
      "BANK DETAILS",
      "OTHER DOCUMENTS",
    ])
    let token = getToken('Token');
    const decoded = jwtDecode(token);
    useEffect(() => {
      return () => {
        dispatch(usaEmployeeSlice.actions.resetState());
      };
    }, []);
    const onchange = (e, directory) => {
        let { name, value } = e.target
        if(name === "dateofJoining" || name === "confirmationDate"){
          const dateOfJoining = value;
          const date = new Date(dateOfJoining);
          value = date.toISOString();          
        }
        if(name === "totalExperience"){
          if( value > 0 && value !==""){
            setErrorMessageState([false, false,false, false, false])
            setCompleted([false, false,false, false, false])
            setStepperumber(prev=> [prev[0], 4])
            setSteps(["PROFESSIONAL DETAILS",
        "PERSONAL DETAILS",
        "BANK DETAILS",
        "EXPERIENCE",
        "OTHER DOCUMENTS",])
          }else {
            setStepperumber(prev=> [prev[0], 3])
            setErrorMessageState([false, false, false, false])
            setCompleted([false, false, false, false])
            setSteps(["PROFESSIONAL DETAILS",
        "PERSONAL DETAILS",
        "BANK DETAILS",
        "OTHER DOCUMENTS",])
          }
          }
        dispatch(usaEmployeeSlice.actions.updateDetails({ name,value,directory }))
    }
    const getMandatoryProfessionalDetails =()=>{
      let {companyEmail, currentCompanyExperience ,referedBy,totalExperience , ...mandatory } = state.professionalDetails
      return mandatory;
    }
    const getDocumentsMandatory = () => {
      if (state.immigrationDocumentsList === "f1Student") {
        const { offerLetter, i20Form, ...rest } = state.documents;
        const documentDates = Object.keys({ offerLetter, i20Form }).map(key => state.documents[key]?.uploadDate);
        return documentDates;
      } else if (state.immigrationDocumentsList === "optcpt") {
        const { opt, i20Form, ...rest } = state.documents;
        const documentDates = Object.keys({ opt, i20Form }).map(key => state.documents[key]?.uploadDate);
        return documentDates;
      } else if (state.immigrationDocumentsList === "h1b") {
        const { h1BDocuments, educationalDocuments,i20Form,opt,offerLetter,  ...rest } = state.documents;
        const documentDates = Object.keys(rest).map(key => state.documents[key]?.uploadDate);
        return documentDates;
      } else if (state.immigrationDocumentsList === "other") {
        return { filename: "true" };
      } else {
        const { h1BDocuments, educationalDocuments, ...mandatory } = state.documents;
        const documentDates = Object.keys(mandatory).map(key => state.documents[key]?.uploadDate);
        return documentDates.length ? documentDates : null;
        
      }      
    }

    const getCurrentStepperObject = () => {
      const MandatoryProfessionalDetails = getMandatoryProfessionalDetails();
      const documentsMandatory = getDocumentsMandatory();
      
      const personalValidation = Object.values(personalDetailsError).every(value => value === "") ? state.personalDetails : {stop:""}
      if (state.professionalDetails.totalExperience !== 0 && state.professionalDetails.totalExperience !=="") {
        switch (stepperNumber[0]) {
          case 0:
            return MandatoryProfessionalDetails;
          case 1:
            return personalValidation;
          case 2:
            return state.bankDetails;
          case 3:
            return true;
          case 4:
            return documentsMandatory;
          default:
            return null;
        }
      } else {
        switch (stepperNumber[0]) {
          case 0:
            return MandatoryProfessionalDetails;
          case 1:
            return personalValidation;
          case 2:
            return state.bankDetails;
          case 3:
            return documentsMandatory;
          default:
            return null;
        }
      }
    }
    useEffect(() => {
      if (empId) {
        createProfile({ employeeId: empId });
      }
    }, [empId]);
    useEffect(() => {
      if (employeeData.isSuccess) {
        dispatch(usaEmployeeSlice.actions.feildsDisabled(true))
        let empData = employeeData;
        let {additionalInformation , ...restProfessionalDetails} = empData?.data?.professionalDetails;
        let {dob,email,gender} = empData?.data?.personalDetails;
        let {firstName, lastName, offerLetter} = empData?.data
        let {accountNumber, bankName, checkingAccount, currentResidentialAddress,
           drivingLicense, emergencyContactAddress, emergencyContactName, 
           emergencyContactRelation, passportNumber, routingNumber, 
           savingsAccount, ssn, startDate,immigrationDocumentsList, ...restDocuments} = empData?.data?.usDetailsInfo
        const Experience = empData?.data?.workExperience;
        const editPayload = {
          "immigrationDocumentsList": immigrationDocumentsList,
            "personalDetails": {
              "gender": gender,
              "email": email,
              "ssn": ssn,
              "drivingLicense": drivingLicense,
              "passportNumber": passportNumber,
              "dob": dob,
              "emergencyContactName": emergencyContactName,
              "emergencyContactRelation": emergencyContactRelation,
              "emergencyContactAddress": emergencyContactAddress
            },
            "professionalDetails": {
              "firstName":firstName,
              "lastName":lastName,
              ...restProfessionalDetails,
            },
            "bankDetails": {
              "bankName": bankName,
              "accountNumber": accountNumber,
              "routingNumber": routingNumber,
              "startDate": startDate,
              "currentResidentialAddress": currentResidentialAddress,
              "checkingAccount": checkingAccount,
              "savingsAccount": savingsAccount
            },
            "workExperience": [
              ...Experience,
        ],
            "documents":{
              "offerLetter": offerLetter,
              ...restDocuments
            }
        }
        dispatch(updateUSAEmployeeJson(editPayload));
        if(editPayload.professionalDetails.totalExperience>0){
          setStepperumber([0,4])
          setErrorMessageState([false, false, false, false, false])
          setCompleted([false, false, false, false, false])
          renderStepperContent();
          getCurrentStepperObject();
          setSteps(["PROFESSIONAL DETAILS",
            "PERSONAL DETAILS",
            "BANK DETAILS",
            "EXPERIENCE",
            "OTHER DOCUMENTS",])
        }
      }
    }, [employeeData]);

    const setFinalPayload = async()=>{
      setIsLoading(true)
      const {firstName , lastName, isManager,currentCompanyExperience,totalExperience,noticePeriod,probationPeriod, ...restProfessionalDetails} = state.professionalDetails
      const {offerLetter, ...restDocuments} = state.documents
      const {dob,email,gender, ...restPersonalDetails} = state.personalDetails
      
      let finalPayload = {
        ...usaPayload,
        "firstName": firstName,
        "lastName": lastName,
        "isManager": isManager,
        "offerLetter": offerLetter,
        "workExperience": state.workExperience,
        "personalDetails":{
          ...usaPayload.personalDetails,
          "dob" : dob,
          "email": email,
          "gender":gender
        },
        "professionalDetails":{
          ...usaPayload.professionalDetails,
          currentCompanyExperience: Number(currentCompanyExperience),
          totalExperience: Number(totalExperience),
          noticePeriod: Number(noticePeriod),
          probationPeriod: Number(probationPeriod),
          ...restProfessionalDetails
        },
        "usDetailsInfo":{
          "immigrationDocumentsList": state.immigrationDocumentsList,
          ...restPersonalDetails,
          ...state.bankDetails,
          ...restDocuments
        }
      }
        sendUsaEmployeeData(finalPayload)
    }
    const sendUsaEmployeeData = async (finalPayload)=>{
      try {
        let url = `${serviceUrl}/api/employee/create`;
        let response = await axios.post(url, finalPayload, { headers: genericHeaders() });
        if (response.status === 201) {
          resetComponent()
        }
      } catch (err) {
        console.error(err);
        setIsLoading(false)
      }
    }
    const checkNextStep = (stepperNumber) => {
      if (stepperNumber[0]===stepperNumber[1] && completed.every((value) => value===true)) {
        setFinalPayload();
      }
        const professionalEmail = state.professionalDetails.companyEmail;
        if(professionalEmailValidation){
          let cloneError = [...errorMessageState]
          cloneError[stepperNumber[0]]= true
          setErrorMessageState(cloneError)
          toast.error("Please enter Valid Email")
          return
        }
        let currentStepperObject = getCurrentStepperObject(stepperNumber[0]) 
        let isallFilled = Object.values(currentStepperObject).every((value) => value !== "");
        
        if (isallFilled) {
          const temp = completed
          temp[stepperNumber[0]] = true
          setCompleted(temp)
          let cloneError = [...errorMessageState]
          cloneError[stepperNumber[0]]= false
          setErrorMessageState(cloneError)
          if (stepperNumber[0] !== stepperNumber[1]) {
            setStepperumber(prev => [prev[0] + 1, prev[1]]);
            }
        }else{
          let cloneError = [...errorMessageState]
          cloneError[stepperNumber[0]]= true
          setErrorMessageState(cloneError)
          toast.error("Please fill all the Mandatory Details")
        } 
    }
    
    const renderStepperContent = () => {
      const totalExperience = state.professionalDetails.totalExperience 
      if(totalExperience !==0 && totalExperience !==""){
        switch (stepperNumber[0]) {
          case 0:
            return <UsaProfessionalDetails emailValid={professionalEmailValidation} setEmailValid={setProfessionalEmailValidation} isfilled={errorMessageState[0]} onchange={onchange}/>;
          case 1:
            return <UsaPersonalDetails isfilled={errorMessageState[1]} setPersonalDetailsError={setPersonalDetailsError} personalDetailsError={personalDetailsError} onchange={onchange}/>;
          case 2:
            return <UsaBankDetails isfilled={errorMessageState[2]} onchange={onchange}/>;
          case 3:
            return <Experience/>;
          case 4:
            return <UsaDocuments isfilled={errorMessageState[4]} onchange={onchange}/>;
          default:
            return null;
        }
      }else{
        switch (stepperNumber[0]) {
          case 0:
            return <UsaProfessionalDetails emailValid={professionalEmailValidation}  setEmailValid={setProfessionalEmailValidation} isfilled={errorMessageState[0]} onchange={onchange}/>;
          case 1:
            return <UsaPersonalDetails isfilled={errorMessageState[1]} setPersonalDetailsError={setPersonalDetailsError} personalDetailsError={personalDetailsError} onchange={onchange}/>;
          case 2:
            return <UsaBankDetails isfilled={errorMessageState[2]} onchange={onchange}/>;
          case 3:
            return <UsaDocuments isfilled={errorMessageState[3]} onchange={onchange}/>;
          default:
            return null;
        }
      }
    };
    const resetComponent = ()=>{
      setStepperumber([0,3])
          setErrorMessageState([false, false, false, false])
          setCompleted([false, false, false, false])
          toast.success("Form Submitted") 
          dispatch(usaEmployeeSlice.actions.resetState())
          setIsLoading(false)
          setSteps(["PROFESSIONAL DETAILS",
            "PERSONAL DETAILS",
            "BANK DETAILS",
            "OTHER DOCUMENTS",])
            if(feildDisabled){
              dispatch(usaEmployeeSlice.actions.feildsDisabled(false))
              navigate("/manage");
            }
    }
    const isFinalStep = ()=>{
      if(stepperNumber[0]===stepperNumber[1]){
        return true
      }else{
        return false
      }
    }
  return (
    <div>
    {isLoading && (<Loader/>)}
      <div className="mt-24 ml-10 m-10">
    <div className='w-screen font-bold text-blue-400 text-xl my-5'>ADD EMPLOYEE</div>
      <Stepper activeStep={stepperNumber[0]} alternativeLabel className="mb-10">
          {steps.map((label, index) => (
            <Step key={label} completed={completed[index]}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

      {renderStepperContent()}
      <button 
      disabled={stepperNumber[0]===0? true: false} 
      onClick={()=> setStepperumber(prev=>[prev[0]-1, prev[1]])}
      className='bg-blue-600 p-3 mx-3 rounded-md text-slate-50'
        >Previous</button>
      <button 
      onClick={()=> checkNextStep(stepperNumber)}
      className={`bg-blue-600 p-3 mx-3 rounded-md text-slate-50 hover:bg-blue-700 ${isFinalStep() && feildDisabled ? "cursor-not-allowed": "cursor-pointer "}`}
      disabled={isFinalStep() && feildDisabled ? true: false}
      >{isFinalStep() ? "Submit":"Next"}</button>
      {/* {errorMessageState[stepperNumber] && (<span className='text-red-500'>Please fill all the Mandatory Details</span>)} */}
      </div>
    </div>
  )
}

export default AddUsaEmployee
