import api from "../services/index";

const TrainingModule = api.injectEndpoints({
  
 endpoints: (builder) => ({
  getAllTrainingModules: builder.query({
   query: () => ({
     url: `/api/trainingModule/getalltrainingrecords`,
     method: "get",
   }),
 }),
}),
});

export default TrainingModule;
export const { useGetAllTrainingModulesQuery } = TrainingModule;