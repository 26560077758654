import React from "react";
import TextField from "../../Components/customComponents/customTextField";
import { useSelector, useDispatch } from "react-redux";
import {
  updateEmployeeDetails,
  updateErrorState,
  setIsUploadDocumentsDialogOpen,
  deleteDoc,
} from "../../store/reducers/addEmployeeSlice";
import DocumentUploadDialog from "./DocumentUploadDialog";
import DocList from "./DocList";
import { updateErrorMessage } from "../../store/reducers/errorMessageSlice";

const StatutoryInfo = ({isInvite}) => {
  const empState = useSelector((state) => state.addEmployee.employeeDetails);
  const {documents} = empState.academicDetails
  const errorState = useSelector((state) => state.addEmployee.errorState);
  const feildDisabled = useSelector(
    (state) => state.addEmployee.feildsDisabled
  );
  const errorMessageState = useSelector(
    (state) => state.errorMessage.errorMessage
  );
  const dispatch = useDispatch();
  const handleChange = (e) => {
    if (
      e.target.name == "employeeDetails.personalDetails.dob" ||
      e.target.name == "employeeDetails.professionalDetails.dateofJoining" ||
      e.target.name == "employeeDetails.professionalDetails.confirmationDate" ||
      e.target.name == "employeeDetails.academicDetails.fromDate" ||
      e.target.name == "employeeDetails.academicDetails.toDate"
    ) {
      dispatch(
        updateEmployeeDetails({
          value: new Date(e.target.value).toISOString(),
          path: e.target.name,
        })
      );
    } else if (
      e.target.name == "employeeDetails.personalDetails.aadharNumber"
    ) {
      if (/^\d+$/.test(e.target.value) && e.target.value.length < 13) {
        dispatch(
          updateEmployeeDetails({ value: e.target.value, path: e.target.name })
        );
      }
      if (e.target.value.length == 0) {
        dispatch(
          updateEmployeeDetails({ value: e.target.value, path: e.target.name })
        );
      }
    } else {
      dispatch(
        updateEmployeeDetails({ value: e.target.value, path: e.target.name })
      );
    }
  };
  const removeDoc=(index)=>{
    dispatch(deleteDoc({index,component:"academic"}))
  }
  return (
    <>
      <DocumentUploadDialog fromComponent="academic" />
      <h1 className="text-2xl mb-10">Step 3: ACADEMIC DETAILS</h1>
      <div className="flex flex-col justify-center items-end w-[40%]">
        <TextField
          name="employeeDetails.academicDetails.institute"
          label="College Name"
          type="text"
          onChange={handleChange}
          isMandatory={true}
          value={empState.academicDetails.institute}
          error={errorState.step2.institute ? errorState.step2.institute : ""}
          disabled={feildDisabled}
          onBlur={() => {
            dispatch(updateErrorState({ activeTab: 2, field: "institute" }));
          }}
          className={`${
            empState.academicDetails.institute === "" &&
            errorMessageState === true &&
            "border border-red-500"
          }`}
        />
        <TextField
          name="employeeDetails.academicDetails.qualification"
          label="Qualification"
          type="text"
          onChange={handleChange}
          isMandatory={true}
          value={empState.academicDetails.qualification}
          disabled={feildDisabled}
          className={`${
            empState.academicDetails.qualification === "" &&
            errorMessageState === true &&
            "border border-red-500"
          }`}
        />
        <TextField
          name="employeeDetails.academicDetails.grade"
          label="Grade"
          type="text"
          onChange={handleChange}
          isMandatory={true}
          value={empState.academicDetails.grade}
          disabled={feildDisabled}
          className={`${
            empState.academicDetails.grade === "" &&
            errorMessageState === true &&
            "border border-red-500"
          }`}
        />
        <TextField
          name="employeeDetails.academicDetails.fromDate"
          label="From Date"
          type="date"
          onChange={handleChange}
          isMandatory={true}
          value={
            empState.academicDetails.fromDate
              ? empState.academicDetails.fromDate.split("T")[0]
              : ""
          }
          max={empState.academicDetails.toDate ? (() => {
            const toDate = new Date(empState.academicDetails.toDate);
            toDate.setDate(toDate.getDate() - 1);
            return toDate.toISOString().split("T")[0];
          })()
            : new Date().toISOString().split("T")[0]}
          disabled={feildDisabled}
          className={`${
            empState.academicDetails.fromDate === "" &&
            errorMessageState === true &&
            "border border-red-500"
          }`}
        />
        <TextField
          name="employeeDetails.academicDetails.toDate"
          label="To Date"
          type="date"
          onChange={handleChange}
          isMandatory={true}
          value={
            empState.academicDetails.toDate
              ? empState.academicDetails.toDate.split("T")[0]
              : ""
          }
          max={new Date().toISOString().split("T")[0]}
          min={
            empState.academicDetails.fromDate
              ? (() => {
                  const fromDate = new Date(empState.academicDetails.fromDate);
                  fromDate.setDate(fromDate.getDate() + 1);
                  return fromDate.toISOString().split("T")[0];
                })()
              : ""
          }
          disabled={feildDisabled}
          className={`${
            empState.academicDetails.toDate === "" &&
            errorMessageState === true &&
            "border border-red-500"
          }`}
        />  
      </div>
      <div>
        {!feildDisabled&&<button
            className="text-xl font-medium block mx-auto my-3"
            onClick={() => {
              dispatch(setIsUploadDocumentsDialogOpen(true));
            }}
          >
            <div className="w-8 h-8 mx-auto">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="size-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9 8.25H7.5a2.25 2.25 0 0 0-2.25 2.25v9a2.25 2.25 0 0 0 2.25 2.25h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25H15m0-3-3-3m0 0-3 3m3-3V15"
              />
            </svg>
          </div>
            upload documents
        </button>}
        </div>
      <div className="w-2/3 py-5 mx-auto flex flex-wrap">
        <DocList
            documents={documents}
            deleteDoc={removeDoc}
            feildDisabled={feildDisabled}
        />
      </div>
    </>
  );
};

export default StatutoryInfo;
