import React from "react";
import TextField from "../../Components/customComponents/customTextField";
import { useSelector, useDispatch } from "react-redux";
const UsaBankDetails = ({ onchange, isfilled }) => {
  let bankDetails = useSelector((state) => state.usaEmployee.intialUsEmployee.bankDetails);
  const feildsDisabled = useSelector(state=> state.usaEmployee.feildsDisabled)
  return (
    <div className="ml-10 mb-9">
      <p className="font-medium">BANK DETAILS</p>
      <div className="flex flex-col justify-evenly p-6 items-end justify-self-start">
        <TextField
          label={"Bank Name"}
          type={"text"}
          isMandatory={true}
          onChange={(e) => onchange(e, "bankDetails")}
          disabled={feildsDisabled}
          name={"bankName"}
          value={bankDetails.bankName}
          className={`${
            !bankDetails.bankName && isfilled && "border border-red-500"
          }`}
        />
        <TextField
          label={"Account Number"}
          type={"text"}
          isMandatory={true}
          onChange={(e) => onchange(e, "bankDetails")}
          disabled={feildsDisabled}
          name={"accountNumber"}
          value={bankDetails.accountNumber}
          className={`${
            !bankDetails.accountNumber && isfilled && "border border-red-500"
          }`}
        />
        <TextField
          label={"Routing Number"}
          type={"text"}
          isMandatory={true}
          onChange={(e) => onchange(e, "bankDetails")}
          disabled={feildsDisabled}
          name={"routingNumber"}
          value={bankDetails.routingNumber}
          className={`${
            !bankDetails.routingNumber && isfilled && "border border-red-500"
          }`}
        />
        <TextField
          label={"Start Date"}
          type={"date"}
          isMandatory={true}
          onChange={(e) => onchange(e, "bankDetails")}
          disabled={feildsDisabled}
          name={"startDate"}
          value={bankDetails.startDate}
          className={`${
            !bankDetails.startDate && isfilled && "border border-red-500"
          }`}
        />
        <TextField
          label={"Current Residential Address"}
          type={"text"}
          isMandatory={true}
          onChange={(e) => onchange(e, "bankDetails")}
          disabled={feildsDisabled}
          name={"currentResidentialAddress"}
          value={bankDetails.currentResidentialAddress}
          className={`${
            !bankDetails.currentResidentialAddress &&
            isfilled &&
            "border border-red-500"
          }`}
        />
        <TextField
          label={"Checking Account"}
          type={"text"}
          isMandatory={true}
          onChange={(e) => onchange(e, "bankDetails")}
          disabled={feildsDisabled}
          name={"checkingAccount"}
          value={bankDetails.checkingAccount}
          className={`${
            !bankDetails.checkingAccount && isfilled && "border border-red-500"
          }`}
        />
        <TextField
          label={"Savings Account"}
          type={"text"}
          isMandatory={true}
          onChange={(e) => onchange(e, "bankDetails")}
          disabled={feildsDisabled}
          name={"savingsAccount"}
          value={bankDetails.savingsAccount}
          className={`${
            !bankDetails.savingsAccount && isfilled && "border border-red-500"
          }`}
        />
      </div>
    </div>
  );
};

export default UsaBankDetails;
