import React, { useEffect, useState } from 'react'
import { useGetAllTrainingModulesQuery } from '../api/TrainingModule';
import Loader from "../Components/customComponents/customLoader"
import downloadIcon from "../Icons/downloadIcon.svg"
import deleteIcon from "../Icons/deleteIcon.svg"
import { get_file_from_s3 } from '../utils';
import { toast } from 'react-toastify';
import { upload_file_to_s3 } from '../utils';
import { genericHeaders, serviceUrl } from '../utils/apiConfig';
import axios from 'axios';
import { getToken } from '../services/Token';


const TrainingModules = () => {

    const [trainingData,setTrainingData] = useState();
    const [loading,setLoading] = useState(true);
    const { data, isSuccess , isError , refetch} = useGetAllTrainingModulesQuery({},{refetchOnMountOrArgChange:true});
    const role = getToken("roles");
    const isAdmin = role ? role.includes("ADMIN"):false;
    

    useEffect(() => {
        if(isSuccess){
            setTrainingData(data);
            setLoading(false);
        }
        if(isError){
           setLoading(false); 
        }
    },[isSuccess,isError]);

    const handleDownload = async (file) => {
      try {
        const res = await get_file_from_s3(file?.fileName);
        const s3Url = res;

        if (!s3Url) {
          toast.error("Failed to fetch the file");
          return;
        }

        const response = await fetch(s3Url);
        const blob = await response.blob();

        const downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(blob);

        const urlParts = s3Url.split("/");
        const fullFileName = urlParts[urlParts.length - 1]; //need to change as per filename
        const [filename] = fullFileName.split("?");

        downloadLink.setAttribute("download", filename);
        document.body.appendChild(downloadLink);
        downloadLink.click();

        document.body.removeChild(downloadLink);
      } catch (error) {
        console.error("Error downloading file:", error);
      }
    };

    const upload_module = async (s3path, originalFileName)=>{
      setLoading(true)
      try {
      let url = `${serviceUrl}/api/trainingModule/createtrainingmodule`
      let payload = {
        fileName: s3path.data.data.fileName,
        folderPath: s3path.data.data.folderPath,
        timeStamp: s3path.data.data.timeStamp,
        bucketName: s3path.data.data.bucketName,
        originalFileName: originalFileName,
        region: s3path.data.data.region,
      }
      let response = await axios.post(url, payload , {headers:genericHeaders()} )
      if(response.status===201){
        toast.success("Module Added Successfully")
        refetch()
        setLoading(false)
      }
      } catch (e) {
        toast.success("Failed to a Module")
        setLoading(false)
        
      }
    }

    const handle_file_upload = async (new_file) => {
      const file = new_file.target.files[0];
      try {
        const maxSizeInBytes = 10 * 1024 * 1024; // max 10mb
  
        if (file?.size > maxSizeInBytes) {
          return
        }
        const s3path = await upload_file_to_s3(file);
        const public_url = s3path.location;
        await upload_module(s3path , file.name)
      } catch (e) {
        console.log(e, "erororr345");
      }
    };
    const handle_delete_module = async (e)=>{
      setLoading(true)
      try {
        let url = `${serviceUrl}/api/trainingModule/deletetrainingmodule?trainingModuleId=${e._id}`
        let response = await axios.delete(url, {headers:genericHeaders()})
        if(response.status===200){
          toast.success('Module deleted Successfully')
          refetch()
          setLoading(false)
        }
      } catch (e) {
        toast.error("Failed to Upload Module")
        setLoading(false)
      }
    }
  return (
    <div className='mt-28'>
        {loading && <Loader/>}
        <input id="addFile" type="file" onChange={(e)=>handle_file_upload(e)} className="opacity-0 w-full h-full cursor-pointer ml-[90%]"/>
        {isAdmin && (<label for="addFile" className="bg-blue-500 text-white py-2 px-4 rounded cursor-pointer hover:bg-blue-600 ml-[80%]">
        Add New Module
        </label>)}
        <h3 className='m-5 text-2xl font-semibold'>To download the file, click on the respective link provided.</h3>
        <div className="my-auto mx-5 space-y-5">
        {data?.data?.map( (item,i) => {
            return (
              <div
                className="justify-between text-blue-500 font-semibold text-lg lg:w-3/4 w-11/12 px-3 py-2 border border-blue-500 rounded-md flex">
                {item?.originalFileName}
                <span className='flex'>
                <img className="w-6 ml-2 cursor-pointer" title='Download' src={downloadIcon} alt="ICON" onClick={() => handleDownload(item)}/>
                {isAdmin && (<img className='w-6 ml-2 cursor-pointer' title='Delete' src={deleteIcon} alt='delete Icon' onClick={()=>handle_delete_module(item)}/>)}
                </span>
              </div>
            );
        })}
        </div> 
        
    </div>
  )
}

export default TrainingModules

