import React from "react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { Menu } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import { clearCahce, getToken } from "../services/Token";
// import { useSelector } from 'react-redux';
// import { selectUsername } from '../store/reducers/loginSlice';

function LoginEmail(props) {
  // const username = useSelector(selectUsername);
  const email = getToken("professionalEmail");
  const userId = getToken("employeeId")
  const navigate = useNavigate();
  return (
    <>
      <div className="flex flex-row">
        <Menu as="div" className="relative ml-3 mr-9">
          <div>
            <Menu.Button className="flex max-w-xs items-center rounded-full shadow bg-white text-sm focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 lg:rounded-md lg:p-2 lg:hover:bg-gray-50">
              <div className="ml-3 hidden text-sm font-medium text-gray-700 lg:block ">
                {userId}
              </div>
              <ChevronDownIcon
                className="ml-1 hidden h-5 w-5 flex-shrink-0 text-gray-400 lg:block"
                aria-hidden="true"
              />
            </Menu.Button>
          </div>
          <Menu.Items className="cursor-pointer absolute z-100 right-0 z-10 mt-2 w-fit origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5">
            { !props.isAdmin ? (<Menu.Item>
              {({ active }) => (
                <a
                  className={
                    (active ? "bg-gray-100" : "",
                    "block px-4 py-2 text-sm text-gray-700")
                  }
                  onClick={() => {
                    navigate("/profile");
                  }}
                >
                  Profile
                </a>
              )}
            </Menu.Item>) : ""}
            <Menu.Item>
              {({ active }) => (
                <a
                  className={
                    (active ? "bg-gray-100" : "",
                    "block px-4 py-2 text-sm text-gray-700")
                  }
                  onClick={() => {    
                    clearCahce();
                    navigate("/login");
                  }}
                >
                  Logout
                </a>
                
              )}
            </Menu.Item>
          </Menu.Items>
        </Menu>
      </div>
    </>
  );
}
export default LoginEmail;
