import React, { useEffect, useState } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import TextField from '../../Components/customComponents/customTextField'
import { genericHeaders, serviceUrl } from '../../utils/apiConfig'
import axios from 'axios'
import { updateEmployeeJson } from '../../store/reducers/addEmployeeSlice'
import { useDispatch } from 'react-redux'
import { getToken, setToken } from '../../services/Token'
import addEmployeeJson from "../addEmployee/addEmployee.json";


const LoginEmlpoyeeInvite = () => {
  const [email, setemail] = useState("")
  const [userId, setUserId] = useState("")
  const [decoded , setDecoded] = useState([])
  const [errorMessage , setErrorMessage] = useState("")
  let inviteEmployee = JSON.parse(JSON.stringify(addEmployeeJson)); 
  let [linkExpire, setLinkExpire] = useState("")
  

  let dispatch = useDispatch()
  const emailPattern = /^[a-zA-Z0-9][^\s@]*@[^\s@]+\.[a-zA-Z]+$/;
 
  let navigate = useNavigate()
    let {encripted} = useParams()

    let submitForm = async ()=>{
       if(email.trim()===decoded[0] && userId===decoded[1]){
          setToken("inviteEmployeeEncripted", encripted)
          await navigateUser()
       }else{
        setErrorMessage("Pelese enter Correct email and UserId")
       }
    }

    useEffect(()=>{
      try{
         let decoded = atob(encripted);
         let split = decoded.split("::")
         setDecoded(split)
        }catch(error){
      console.error(error);     
    }
    },[])

    const handleChange = (e)=>{
      const {name, value} = e.target
      if(name==="email"){
        setemail(prev=> value)        
      }
      if(name==="userId"){
        setUserId(prev=>value)
      }
    }

    const navigateUser = async ()=>{
      try {
        let url = `${serviceUrl}/api/sendinvitation/onboarding/details?encodedString=${encripted}`
        let response = await axios.get(url)
        if(response.status===200){     
          inviteEmployee.get_id = response.data.data._id;
          inviteEmployee.professionalDetails.employeeId = response.data.data.professionalDetails.employeeId
          inviteEmployee.firstName = response.data.data.firstName
          inviteEmployee.lastName = response.data.data.lastName
          inviteEmployee.isManager = response.data.data.isManager
          inviteEmployee.professionalDetails.status = response.data.data.professionalDetails.status
          inviteEmployee.professionalDetails.dateofJoining = response.data.data.professionalDetails.dateofJoining
          inviteEmployee.professionalDetails.noticePeriod = response.data.data.professionalDetails.noticePeriod
          inviteEmployee.professionalDetails.shift = response.data.data.professionalDetails.shift
          inviteEmployee.professionalDetails.probationPeriod = response.data.data.professionalDetails.probationPeriod
          inviteEmployee.professionalDetails.confirmationDate = response.data.data.professionalDetails.confirmationDate
          inviteEmployee.professionalDetails.totalExperience = response.data.data.professionalDetails.totalExperience
          inviteEmployee.professionalDetails.currentCompanyExperience = response.data.data.professionalDetails.currentCompanyExperience
          inviteEmployee.professionalDetails.referedBy = response.data.data.professionalDetails.referedBy
          inviteEmployee.personalDetails.email = response.data.data.personalDetails.email
          inviteEmployee.personalDetails.dob = response.data.data.personalDetails.dob
          inviteEmployee.professionalDetails.department = response.data.data.professionalDetails.department
          inviteEmployee.professionalDetails.currentDesignation = response.data.data.professionalDetails.currentDesignation
          inviteEmployee.professionalDetails.holidayCalender = response.data.data.professionalDetails.holidayCalender
          inviteEmployee.professionalDetails.employeeType = response.data.data.professionalDetails.employeeType
          inviteEmployee.professionalDetails.managerId = response.data.data.professionalDetails.managerId
          inviteEmployee.professionalDetails.workLocation = response.data.data.professionalDetails.workLocation
          inviteEmployee.professionalDetails.companyEmail = response.data.data.professionalDetails.companyEmail
          setLinkExpire(false)
          dispatch(updateEmployeeJson(inviteEmployee))
          navigate("/employeeinvite")
        }
      } catch (error) {
        console.error(error);
        setLinkExpire(true)         
      }
    }
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <div className=" gap-5 shadow-xl w-[40%] h-auto p-10 flex flex-col items-center justify-center rounded-lg">
        <h2 className="text-2xl font-serif text-purple-800 mt-3 ml-3 cursor-default my-5">
          <span className="font-extrabold">work</span>
          <span className="font-extrabold">force</span>
          <span className="text-3xl font-extrabold text-blue-600">HR</span>
        </h2>
        <div className="flex flex-col items-end">
          <TextField
            label={"E-mail"}
            type={"email"}
            name={"email"}
            value={email}
            placeHolder={"Enter the Email"}
            onChange={(e) => handleChange(e)}
          />

          <TextField
            label={"Employee ID"}
            type={"text"}
            name={"userId"}
            value={userId.toUpperCase()}
            placeHolder={"Enter the ID"}
            onChange={(e) => handleChange(e)}
          />
        </div>

        {linkExpire && (
          <p className="text-red-600  font-medium">Link Expired</p>
        )}
        {errorMessage && (
          <p className="text-red-600  font-medium">{errorMessage}</p>
        )}
        <button
          className="ml-[30%] bg-blue-400 text-white p-2 rounded-md hover:bg-blue-500"
          onClick={() => submitForm()}
        >
          Submit
        </button>
      </div>
    </div>
  );
}

export default LoginEmlpoyeeInvite
