import React, { useEffect, useState } from 'react'
import addEmployeeJson from "../../pages/addEmployee/addEmployee.json";
import TextField from '../../Components/customComponents/customTextField';
import Select from '../../Components/customComponents/customSelect';
import { genericHeaders, serviceUrl } from '../../utils/apiConfig';
import axios from 'axios';
import { toast } from 'react-toastify';
import Loader from '../../Components/customComponents/customLoader';

const InviteEmployee = () => {
  const emailPattern = /^[a-zA-Z0-9][^\s@]*@[^\s@]+\.[a-zA-Z]+$/;
  const [inviteEmployee, setInviteEmployee] = useState(addEmployeeJson);
  const [department , setDepartment] = useState([])
  const [designation , setDesignation] = useState([{value:"", label:"Select Department for Options"}])
  const [designationData, setDesignationData] = useState([])
  const [manager , setManager] = useState([])
  const [holidayCalender , setHolidayCalender] = useState([])
  const [employeeTpe, setEmployeeType]  = useState([])
  const [errorMessageState , seterrorMessageState] = useState(false)
  const [emailError, setEmailError] = useState("")
  const [isMailValid, setIsMailValid]= useState(false)
  const [location, setLocation] = useState([])
  const [isLoading, setIsloading] = useState(false)
  const [error, setError] = useState({
    firstName: false,
    lastName: false,
    employeeId:false,
    location:false,
    workLocation:false,
    department:false,
    currentDesignation:false,
    managerId:false,
    isManager:false,
    email:false,
    dob:false,
  });
  const [duplicateUserError, setDuplicateUserError] = useState("")
//


const getDepartment = async()=>{
  try{
    let url = `${serviceUrl}/api/department/getAll`
    let response = await axios.get(url, {headers: genericHeaders()})
    if(response.status===200){
      setDepartment(response.data.filter((department) => department.isActive)
      .map((item) => {
        return { value: item._id, label: item.departmentName };
      }))  
      
    }
  }catch(error){
    console.log(error);   
  }
}

const getManager = async()=>{
  try{
    let url = `${serviceUrl}/api/employee/getEmployeesList`
    let response = await axios.get(url, {headers: genericHeaders()})
    if(response.status===200){
      setManager(response.data.filter((manager) => manager.ismanager!= "false")
      .map((item) => {
        return { value: item.id, label: item.name };
      }))  
    }
  }catch(error){
    console.log(error);   
  }
}

const getEmployeeTypes = async ()=>{
  try {
    let url = `${serviceUrl}/api/employeeType/getAllEmployeeTypes`
     let response = await axios.get(url, {headers:genericHeaders()})
     if(response.status===200){
       let temp= response.data.filter((type) => type.isActive)
      .map((item) => {
          return { value: item._id, label: item.employeeCategory };
        })
        setEmployeeType(temp);
      }
   } catch (error) {
     console.log(error);
     
   }
  }

const getHolydayCategory = async ()=>{
  try {
    let url = `${serviceUrl}/api/holidayCalender/getAllHolidayCalenderMap` 
    let response = await axios.get(url , {headers:genericHeaders()})
    if(response.status===200){
      // setHolidayCalender(response.data.filter(date)=>)
        let temp = (Object.keys(response.data).map((holidayId) => {
          return { value: holidayId, label: response.data[holidayId] };
        }))
        setHolidayCalender(temp);    
      }
  } catch (error) {
    console.log(error);
    
  }
}

 const getDesignation = async()=>{
  try{
    let url = `${serviceUrl}/api/designation/getAllDesignations`
    let response = await axios.get(url, {headers: genericHeaders()})
    if(response.status ===200){
      setDesignationData(response.data)
      // setDesignation((response.data).filter(designation=> designation.isActive))
    }
  }catch(error){
    console.log(error);
    
  }
}

const getLocation= async()=>{
try {
  let url = `${serviceUrl}/api/workLocation/getAllWorkLocations`
let response = await axios.get(url, {headers: genericHeaders()})
if(response.status===200){
  setLocation(response.data.filter((location) => location.isActive===true)
      .map((item) => {
        return { value: item._id, label: item.location };
      })) 
  
}
} catch (error) {
  console.log(error);
  
}
}


useEffect(() => {
  getDepartment();
  getManager();
  getHolydayCategory()
  getEmployeeTypes()
  getDesignation()
  getLocation()
}, []);

function onchange(e, directory){
  let temp = {...inviteEmployee}
  let {value, name} = e.target
  if(directory===""){
    temp= {...temp, [name]:value}
  }else{
    temp[directory] = {...temp[directory],[name]:value}
  }
  if(name === "email"){
    if (value === "") {
      setEmailError("");
    } else if (emailPattern.test(value)) {
      setEmailError("");
      setIsMailValid(true)
      setInviteEmployee(prev=>({
        ...prev,
        personalDetails:{
          ...prev.personalDetails,
          [name] : value.trim()
        }
      }))
    } else {
      setEmailError("Please enter a valid email");
      setIsMailValid(false)
    }
  }
  if(e.target.type==="date"){
const dateOfJoining = value;
const date = new Date(dateOfJoining);
const isoString = date.toISOString();
temp[directory] = {...temp[directory],[name]:isoString}

  }
  
  setInviteEmployee(temp)
  if(name==="department"){
    if(e.target.value!= ""){
      let a = designationData[value]
      let temp = Object.keys(a).map((each)=>{
        return {value : a[each] , label : each} 
      })
      setDesignation(temp)
    }
  }
}  
function handleBlur(e){ 
  let {value, name} = e.target
   setError((prevError) => {
    if(name === "email"){
      if (value === "") {
        setEmailError("");
      } else if (emailPattern.test(value)) {
        setEmailError("");
        setIsMailValid(true)
      } else {
        setEmailError("Please enter a valid email");
        setIsMailValid(false)
      }
    }
     if (value === "") {

       return {
         ...prevError,
         [name]: true,
       };
     } else {
       return {
         ...prevError,
         [name]: false,
       };
     }
   });
  }

  async function sendFormdata(){
    try {
      let url = `${serviceUrl}/api/sendinvitation/onboarding/invite`
      let response = await axios.post(url,{
          "employeeId": inviteEmployee.professionalDetails.employeeId.toUpperCase(),
          "firstName": inviteEmployee.firstName,
          "lastName": inviteEmployee.lastName,
          "companyEmail": inviteEmployee.professionalDetails.companyEmail.trim(),
          "workLocation": inviteEmployee.professionalDetails.workLocation,
          "department": inviteEmployee.professionalDetails.department,
          "designation": inviteEmployee.professionalDetails.currentDesignation,
          "holidayCalender": inviteEmployee.professionalDetails.holidayCalender,
          "managerId": inviteEmployee.professionalDetails.managerId,
          "employeeType": inviteEmployee.professionalDetails.employeeType,
          "isManager": inviteEmployee.isManager,
          "status": inviteEmployee.professionalDetails.status,
          "dateofJoining": inviteEmployee.professionalDetails.dateofJoining,
          "probationPeriod": +inviteEmployee.professionalDetails.probationPeriod,
          "confirmationDate": inviteEmployee.professionalDetails.confirmationDate,
          "noticePeriod": +inviteEmployee.professionalDetails.noticePeriod,
          "shift": inviteEmployee.professionalDetails.shift,
          "totalExperience": +inviteEmployee.professionalDetails.totalExperience,
          "currentCompanyExperience": +inviteEmployee.professionalDetails.currentCompanyExperience,
          "referedBy": inviteEmployee.professionalDetails.referedBy,
          "personalEmail": inviteEmployee.personalDetails.email,
          "dob": inviteEmployee.personalDetails.dob
        
      }, {headers: genericHeaders()})
      if(response.status===200){
        setIsloading(false)
        setInviteEmployee(addEmployeeJson)
        toast.success("Invite Sent Sucessfully")
      }
    } catch (error) {
      setIsloading(false)
      console.log(error);
      toast.error("Form submission Failed")
    }
  }

  async function checkDuplcateUser(){
    setIsloading(true)
    try {
      const url = `${serviceUrl}/api/employee/checkduplicateuser`
      const payload = {
        employeeNo: inviteEmployee.professionalDetails.employeeId,
        professionalEmailId: inviteEmployee.professionalDetails.companyEmail,
        personalEmailId: inviteEmployee.personalDetails.email
      }
      const response = await axios.post(url, payload, {headers: genericHeaders()})

      if(response.status===200){
        sendFormdata()
      }
    } catch (e) {
      setIsloading(false)
      console.error(e.response.data.message)
      toast.error(e.response.data.message)
    }
  }
  function submitForm(){
    seterrorMessageState(true)
    if(inviteEmployee.firstName &&
      inviteEmployee.lastName &&
      inviteEmployee.professionalDetails.employeeId &&
      inviteEmployee.professionalDetails.workLocation &&
      inviteEmployee.professionalDetails.department &&
      inviteEmployee.professionalDetails.currentDesignation &&
      inviteEmployee.professionalDetails.managerId &&
      inviteEmployee.professionalDetails.holidayCalender &&
      inviteEmployee.professionalDetails.employeeType &&
      inviteEmployee.professionalDetails.status &&
      inviteEmployee.professionalDetails.dateofJoining &&
      inviteEmployee.professionalDetails.probationPeriod &&
      inviteEmployee.professionalDetails.confirmationDate &&
      inviteEmployee.professionalDetails.noticePeriod &&
      inviteEmployee.professionalDetails.shift &&
      isMailValid &&
      inviteEmployee.personalDetails.dob 
    ){
      checkDuplcateUser()
      seterrorMessageState(false)
    }
  else{ 
    console.log(" Fill all mandatory details"); 
   }
   
  }

  return (
    <>
      <div className="mt-24 ml-10">
      {isLoading && (<Loader/>)}
        <div className='w-screen font-bold text-blue-400 text-xl my-5'>INVITE EMPLOYEE</div>
        <h1 className="font-medium">PROFESSIONAL DETAILS:</h1>
        <div className="flex justify-evenly p-5">
          <div className="flex flex-col items-end">
            <TextField
              label={"First Name"}
              type={"text"}
              isMandatory={true}
              name={"firstName"}
              value={inviteEmployee.firstName}
              onChange={(e) => onchange(e, "")}
              onBlur={(e) => handleBlur(e)}
              error={error.firstName ? "This field is required": ""}
              className={`${
                !inviteEmployee.firstName &&
                 errorMessageState &&
                 "border border-red-500"
               }`}
            />

            <TextField
              label={"Last Name"}
              type={"text"}
              isMandatory={true}
              name={"lastName"}
              value={inviteEmployee.lastName}
              onChange={(e) => onchange(e, "")}
              onBlur={(e) => handleBlur(e)}
              error={error.lastName ? "This field is required": ""}
              className={`${
                !inviteEmployee.lastName &&
                 errorMessageState &&
                 "border border-red-500"
               }`}
            />

            <TextField
              label={"Employee Number"}
              type={"text"}
              isMandatory={true}
              name={"employeeId"}
              value={inviteEmployee.professionalDetails.employeeId.toUpperCase()}
              onChange={(e) => onchange(e, "professionalDetails")}
              onBlur={(e) => handleBlur(e)}
              error={error.employeeId ? "This field is required": ""}
              className={`${
                !inviteEmployee.professionalDetails.employeeId &&
                 errorMessageState &&
                 "border border-red-500"
               }`}
            />

            <TextField
              label={"Company Email"}
              type={"email"}
              isMandatory={false}
              name={"companyEmail"}
              value={inviteEmployee.professionalDetails.companyEmail}
              onChange={(e) => onchange(e, "professionalDetails")}
            />

            <Select
              label={"Location"}
              type={"text"}
              isMandatory={true}
              name={"workLocation"}
              value={inviteEmployee.professionalDetails.workLocation}
              onChange={(e) => onchange(e, "professionalDetails")}
              options={location}
              error={error.workLocation ? "This field is required": ""}
              className={`${
                !inviteEmployee.professionalDetails.workLocation &&
                 errorMessageState &&
                 "border border-red-500"
               }`}
            />

            <Select
              label={"Department"}
              isMandatory={true}
              name={"department"}
              value={inviteEmployee.professionalDetails.department}
              onChange={(e) => onchange(e, "professionalDetails")}
              options={department}
              className={`${
                !inviteEmployee.professionalDetails.department &&
                 errorMessageState &&
                 "border border-red-500"
               }`}
            />

            <Select
              label={"Designation"}
              isMandatory={true}
              name={"currentDesignation"}
              value={inviteEmployee.professionalDetails.currentDesignation}
              onChange={(e) => onchange(e, "professionalDetails")}
              options={designation}
              className={`${
                !inviteEmployee.professionalDetails.currentDesignation &&
                 errorMessageState &&
                 "border border-red-500"
               }`}
            />
            
            <Select
              label={"Reporting Manager"}
              isMandatory={true}
              name={"managerId"}
              value={inviteEmployee.professionalDetails.managerId}
              onChange={(e) => onchange(e, "professionalDetails")}
              options={manager}
              className={`${
                !inviteEmployee.professionalDetails.managerId &&
                 errorMessageState &&
                 "border border-red-500"
               }`}
            />

            <Select
              label={"Manager"}
              isMandatory={true}
              name={"isManager"}
              value={inviteEmployee.isManager}
              onChange={(e) => onchange(e, "")}
              options={[
                { value: false, label: "No" },
                { value: true, label: "Yes" },
              ]}
            />

            <Select
              label={"Holiday Category"}
              isMandatory={true}
              name={"holidayCalender"}
              value={inviteEmployee.professionalDetails.holidayCalender}
              onChange={(e) => onchange(e, "professionalDetails")}
              options={holidayCalender}
              className={`${
                !inviteEmployee.professionalDetails.holidayCalender &&
                 errorMessageState &&
                 "border border-red-500"
               }`}
            />
          </div>
          <div className="flex flex-col items-end">
            <Select
              label={"Employee Type"}
              isMandatory={true}
              name={"employeeType"}
              value={inviteEmployee.professionalDetails.employeeType}
              onChange={(e) => onchange(e, "professionalDetails")}
              options={employeeTpe}
              className={`${
                !inviteEmployee.professionalDetails.employeeType &&
                 errorMessageState &&
                 "border border-red-500"
               }`}
            />

            <Select
              label={"Status"}
              isMandatory={true}
              name={"status"}
              value={inviteEmployee.professionalDetails.status}
              onChange={(e) => onchange(e, "professionalDetails")}
              options={[
                { value: "CONFIRMED", label: "CONFIRMED" },
                { value: "CONSULTANT", label: "CONSULTANT" },
                { value: "PROBATION", label: "PROBATION" },
              ]}
              className={`${
                !inviteEmployee.professionalDetails.status &&
                 errorMessageState &&
                 "border border-red-500"
               }`}
            />

            <TextField
              label={"Date Of Joining"}
              type={"date"}
              isMandatory={true}
              name={"dateofJoining"}
              value={inviteEmployee.professionalDetails.dateofJoining ? inviteEmployee.professionalDetails.dateofJoining.slice(0, 10) : ""}
              onChange={(e) => onchange(e, "professionalDetails")}
              className={`${
                !inviteEmployee.professionalDetails.dateofJoining &&
                 errorMessageState &&
                 "border border-red-500"
               }`}
            />

            <TextField
              label={"Probation Period"}
              type={"number"}
              isMandatory={true}
              name={"probationPeriod"}
              value={inviteEmployee.professionalDetails.probationPeriod}
              onChange={(e) => onchange(e, "professionalDetails")}
              onBlur={(e) => handleBlur(e)}
              error={error.probationPeriod ? "This field is required": ""}
              min={0}
              className={`${
                !inviteEmployee.professionalDetails.probationPeriod &&
                 errorMessageState &&
                 "border border-red-500"
               }`}
            />

            <TextField
              label={"Cofirmation Date"}
              type={"date"}
              isMandatory={true}
              name={"confirmationDate"}
              value={inviteEmployee.professionalDetails.confirmationDate ? inviteEmployee.professionalDetails.confirmationDate.slice(0, 10) : ""}

              onChange={(e) => onchange(e, "professionalDetails")}
              className={`${
                !inviteEmployee.professionalDetails.confirmationDate &&
                 errorMessageState &&
                 "border border-red-500"
               }`}
            />

            <TextField
              label={"Notice Period"}
              type={"number"}
              isMandatory={true}
              name={"noticePeriod"}
              value={inviteEmployee.professionalDetails.noticePeriod}
              onChange={(e) => onchange(e, "professionalDetails")}
              onBlur={(e) => handleBlur(e)}
              error={error.probationPeriod ? "This field is required": ""}
              min={0}
              className={`${
                !inviteEmployee.professionalDetails.noticePeriod &&
                 errorMessageState &&
                 "border border-red-500"
               }`}
            />

            <Select
              label={"Shift"}
              isMandatory={true}
              name={"shift"}
              value={inviteEmployee.professionalDetails.shift}
              onChange={(e) => onchange(e, "professionalDetails")}
              options={[
                { value: "GENERAL_SHIFT_1", label: "GENERAL SHIFT 1" },
                { value: "GENERAL_SHIFT_2", label: "GENERAL SHIFT 2" },
                { value: "NIGHT_SHIFT", label: "NIGHT SHIFT" },
              ]}
              className={`${
               !inviteEmployee.professionalDetails.shift &&
                errorMessageState &&
                "border border-red-500"
              }`}
            />

            <TextField
              label={"Total Experiance"}
              type={"number"}
              isMandatory={false}
              name={"totalExperience"}
              value={inviteEmployee.professionalDetails.totalExperience}
              onChange={(e) => onchange(e, "professionalDetails")}
            />

            <TextField
              label={"Curent Company Experiance"}
              type={"number"}
              isMandatory={false}
              name={"currentCompanyExperience"}
              value={
                inviteEmployee.professionalDetails.currentCompanyExperience
              }
              onChange={(e) => onchange(e, "professionalDetails")}
            />

            <TextField
              label={"Reffered By"}
              type={"text"}
              isMandatory={false}
              name={"referedBy"}
              value={
                inviteEmployee.name
              }
              onChange={(e) => onchange(e, "professionalDetails")}
            />
          </div>
        </div>
      </div>
      {/* <hr /> */}
      <div className="ml-10 mb-9">
        <p className="font-medium">PERSONAL DETAILS</p>
        <div className="flex justify-evenly p-6">
          <TextField
            label={"Email"}
            type={"email"}
            isMandatory={true}
            onChange={(e) => onchange(e, "personalDetails")}
            name={"email"}
            value={inviteEmployee.personalDetails.email}
            onBlur={(e) => handleBlur(e)}
            error={emailError}
            className={`${
              !inviteEmployee.personalDetails.email &&
               errorMessageState &&
               "border border-red-500"
             }`}
          />

          <TextField
            label={"Date Of Birth"}
            type={"date"}
            isMandatory={true}
            onChange={(e) => onchange(e, "personalDetails")}
            name={"dob"}
            value={inviteEmployee.personalDetails.dob ? inviteEmployee.personalDetails.dob.slice(0, 10) : ""}
            className={`${
              !inviteEmployee.personalDetails.dob &&
               errorMessageState &&
               "border border-red-500"
             }`
            }
            max={new Date().toISOString()?.split("T")[0]}
          />
        </div>
        <button className={`rounded-md p-3 text-white ml-[80%] bg-blue-500`} onClick={()=>submitForm()} >Submit</button>
      </div>
    </>
  );
};

export default InviteEmployee