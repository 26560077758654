import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

const FileUploadComponent = ({name ,label, handleUploade,isDisabled ,className , isMandatory,Originaldocuments}) => {
  const [documents, setDocuments] = useState("");
  let pointer = isDisabled ? "cursor-not-allowed" : "cursor-pointer"
  let hover = className==="true" ? "bg-red-500" : "bg-slate-500";
  const handleUpload = (e) => {
    handleUploade(e)
    const file = e.target.files[0];
    if (file) {
      const fileName = file.name;
      if (fileName?.length > 20) {
        setDocuments(fileName.substring(0, 20) + "...");
      } else {
        setDocuments(fileName);
      }
    }
  };

  useEffect(() => {
    if (Originaldocuments!=="" && Originaldocuments!==null) {
      if (Originaldocuments?.length > 20) {
        setDocuments(Originaldocuments.substring(0, 20) + "...");
      } else {
        setDocuments(Originaldocuments);
      }
    }
    return () => {
      setDocuments("");
    };
  }, [Originaldocuments]);

  return (
    <div className=" flex w-full items-center gap-5 space-y-4 my-1">
      <div className="flex w-1/2 flex-col items-end">
        <div className="flex gap-2">
           <div className=" text-sm font-medium text-gray-700 ml-3">
          {label}{" "}
          {isMandatory && <span className="text-red-500 text-xl">*</span>}
        </div>
        <div className={` flex gap-2 relative`}>
          <input
            id={name}
            name={name}
            type="file"
            onChange={handleUpload}
            disabled={isDisabled}
            className={`absolute inset-0 opacity-0 ${hover}`}
          />
          <label
            htmlFor={name}
            className={`block text-white py-2 px-4 rounded-md border ${hover} ${pointer} transition-colors duration-300 ease-in-out ${className}`}
          >
            Upload file
          </label>
        </div>
        </div>
       
      </div>
      <div className="flex w-1/2 justify-self-start">
       {documents && (
        <p className="text-sm text-gray-600">
          Selected file: {documents}
        </p>
      )} 
      </div>
      
    </div>
  );
};

export default FileUploadComponent;
