import React, { useEffect, useState } from 'react'
import AddEmployee from './addEmployee'
import { updateEmployeeJson } from '../store/reducers/addEmployeeSlice'
import addEmployeeJson from "../pages/addEmployee/addEmployee.json";



const EmployeeInviteForm = () => {
  return (
      <AddEmployee isInvite={true}/>
  )
}

export default EmployeeInviteForm
