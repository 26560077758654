import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Select from '../../Components/customComponents/customSelect';
import TextField from '../../Components/customComponents/customTextField';
import { useGetAllDapartmentQuery , useGetAllDesignationsIdQuery, useGetAllDesignationsQuery, useGetAllEmployeeTypesQuery, useGetAllHolidayCalenderQuery, useGetWorkLocationsQuery} from '../../api/addEmployeeApi';


import { useGetEmployeesListQuery } from '../../api/getEmployeeList';
import { cloneDeep } from 'lodash';
const UsaProfessionalDetails = ({onchange, emailValid, isfilled, setEmailValid}) => {
    const dispatch = useDispatch()
    const state = useSelector((state) => state.usaEmployee.intialUsEmployee);
    const [designations,setDesignations] = useState([{value:"", label:"Select Department for Options"}]);
    let designationEdit
    const feildsDisabled = useSelector(state=> state.usaEmployee.feildsDisabled)
    const { data: employeeListData, isSuccess: employeeListSuccess } = useGetEmployeesListQuery();
    const { data: allDeapartments, isSuccess: isSuccessAllDepartments} = useGetAllDapartmentQuery();
    const { data: workLocations, isSuccess: isSuccessWorkLocation} = useGetWorkLocationsQuery();
    const { data: allDesignation,isSuccess:isSuccessAllDesignation} = useGetAllDesignationsQuery();
    const { data: allHolidayCalender,isSuccess: isSuccessAllHolidayCalender} = useGetAllHolidayCalenderQuery();
    const { data: designationIdData,isSuccess : isSuccessDesignationsId } = useGetAllDesignationsIdQuery();
    const { data: AllEmployeeTypes,isSuccess: isSuccessEmployeeTypes}= useGetAllEmployeeTypesQuery();

    const getdesignations = (e)=>{
      if(isSuccessDesignationsId && isSuccessAllDesignation){
      const {value, name} = e.target
      if(e.target.value!= ""){
        let a = allDesignation[value]
        let temp = Object.keys(a).map((each)=>{
          return {value : a[each] , label : each} 
        })
        return temp;
      }
      }else{
        return [{value:"", label:"Select Department for Options"}]
      }
    }
    const displayDepartments = () => {
      if (allDeapartments) {
        return allDeapartments.map((department) => ({
          value: department._id,
          label: department.departmentName,
        }));
      }
      return [];
    };
    const displayAllLocations = () => {
      if (isSuccessWorkLocation) {
        return workLocations.map((location) => ({
          value: location._id,
          label: location.location,
        }));
      }
      return [];
    };
    const getAllrReportingManager = ()=>{
      if(employeeListSuccess){
        return employeeListData.filter((manager) => manager.ismanager!= "false")
        .map((item) => {
          return { value: item.id, label: item.name };
        })
      }
      return [];
    }
    const displayAllHolidayCalender = ()=>{
      if(isSuccessAllHolidayCalender){
        let holidayCalendarLables = Object.values(allHolidayCalender)
        let temp = Object.keys(allHolidayCalender).map((each , index)=>{
          return {
            value : each,
            label : holidayCalendarLables[index]  
          }
        })
        return temp;
      }
      return [];
    }
    const displayAllEmployeeType = ()=>{
      if(isSuccessEmployeeTypes){
        let temp = AllEmployeeTypes.map((each)=>{
          return {
            value : each._id,
            label : each.employeeCategory
          }
        })
        return temp;
      }
      return [];
    }
    displayAllEmployeeType();
    const changeDepartment = (e, section) => {
      let newDesignations = getdesignations(e);
      onchange(e, section);
      setDesignations(newDesignations);
    };
    if(feildsDisabled){
      const e= {
        target : {
          name : "currentDesignation",
          value : state.professionalDetails.department
        }
      }
      designationEdit = getdesignations(e)
    }

    const checkEmailValidation = (e)=>{
      onchange(e, "professionalDetails")
      const professionalEmail = e.target.value;
      if(professionalEmail !== ""){
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(professionalEmail)) {
          setEmailValid(true);
        } else {
          setEmailValid(false);
        }
      }else{
        setEmailValid(false);
      }
    }
  return (
    <>
        <h1 className="font-medium">PROFESSIONAL DETAILS:</h1>
        <div className="flex justify-evenly p-5">
          <div className="flex flex-col items-end">
            <TextField
              label={"First Name"}
              type={"text"}
              isMandatory={true}
              name={"firstName"}
              value={state.professionalDetails.firstName}
              onChange={(e) => onchange(e, "professionalDetails")}
              disabled={feildsDisabled}
              className={`${
                !state.professionalDetails.firstName &&
                 isfilled &&
                 "border border-red-500"
               }`}
            />

            <TextField
              label={"Last Name"}
              type={"text"}
              isMandatory={true}
              name={"lastName"}
              value={state.professionalDetails.lastName}
              onChange={(e) => onchange(e, "professionalDetails")}
              disabled={feildsDisabled}
              className={`${
                !state.professionalDetails.lastName &&
                 isfilled &&
                 "border border-red-500"
               }`}
            />

            <TextField
              label={"Employee Number"}
              type={"text"}
              isMandatory={true}
              name={"employeeId"}
              value={state.professionalDetails.employeeId}
              onChange={(e) => onchange(e, "professionalDetails")}
              disabled={feildsDisabled}
              className={`${
                !state.professionalDetails.employeeId &&
                 isfilled &&
                 "border border-red-500"
               }`}
            />

            <TextField
              label={"Company Email"}
              type={"email"}
              isMandatory={false}
              name={"companyEmail"}
              error={emailValid && "Please Enter Valid Email"}
              value={state.professionalDetails.companyEmail}
              onChange={(e) => checkEmailValidation(e)}
              disabled={feildsDisabled}
            />

            <Select
              label={"Location"}
              type={"text"}
              isMandatory={true}
              name={"workLocation"}
              value={state.professionalDetails.workLocation}
              onChange={(e) => onchange(e, "professionalDetails")}
              disabled={feildsDisabled}
              options={displayAllLocations()}
              className={`${
                !state.professionalDetails.workLocation &&
                 isfilled &&
                 "border border-red-500"
               }`}
            />

            <Select
              label={"Department"}
              isMandatory={true}
              name={"department"}
              value={state.professionalDetails.department}
              onChange={(e) => changeDepartment(e, "professionalDetails")}
              disabled={feildsDisabled}
              options={displayDepartments()}
              className={`${
                !state.professionalDetails.department &&
                 isfilled &&
                 "border border-red-500"
               }`}
            />

            <Select
              label={"Designation"}
              isMandatory={true}
              name={"currentDesignation"}
              value={state.professionalDetails.currentDesignation}
              onChange={(e) => onchange(e, "professionalDetails")}
              options={feildsDisabled ? designationEdit : designations}
              disabled={feildsDisabled}
              className={`${
                !state.professionalDetails.currentDesignation &&
                 isfilled &&
                 "border border-red-500"
               }`}
            />
            
            <Select
              label={"Reporting Manager"}
              isMandatory={true}
              name={"managerId"}
              value={state.professionalDetails.managerId}
              onChange={(e) => onchange(e, "professionalDetails")}
              disabled={feildsDisabled}
              options={getAllrReportingManager()}
              className={`${
                !state.professionalDetails.managerId &&
                 isfilled &&
                 "border border-red-500"
               }`}
            />

            <Select
              label={"Manager"}
              name={"isManager"}
              value={state?.professionalDetails?.isManager}
              onChange={(e) => onchange(e, "professionalDetails")}
              disabled={feildsDisabled}
              isMandatory={true}
              options={[
                { value: true, label: "Yes" },
                { value: false, label: "No" },
              ]}
            />

            <Select
              label={"Holiday Category"}
              isMandatory={true}
              name={"holidayCalender"}
              value={state.professionalDetails.holidayCalender}
              onChange={(e) => onchange(e, "professionalDetails")}
              disabled={feildsDisabled}
              options={displayAllHolidayCalender()}
              className={`${
                !state.professionalDetails.holidayCalender &&
                 isfilled &&
                 "border border-red-500"
               }`}
            />
          </div>
          <div className="flex flex-col items-end">
            <Select
              label={"Employee Type"}
              isMandatory={true}
              name={"employeeType"}
              value={state.professionalDetails.employeeType}
              onChange={(e) => onchange(e, "professionalDetails")}
              disabled={feildsDisabled}
              options={displayAllEmployeeType()}
              className={`${
                !state.professionalDetails.employeeType &&
                 isfilled &&
                 "border border-red-500"
               }`}
            />

            <Select
              label={"Status"}
              isMandatory={true}
              name={"status"}
              value={state.professionalDetails.status}
              onChange={(e) => onchange(e, "professionalDetails")}
              disabled={feildsDisabled}
              options={[
                { value: "CONFIRMED", label: "CONFIRMED" },
                { value: "CONSULTANT", label: "CONSULTANT" },
                { value: "PROBATION", label: "PROBATION" },
              ]}
              className={`${
                !state.professionalDetails.status &&
                 isfilled &&
                 "border border-red-500"
               }`}
            />

            <TextField
              label={"Date Of Joining"}
              type={"date"}
              isMandatory={true}
              name={"dateofJoining"}
              value={state.professionalDetails.dateofJoining ? state.professionalDetails.dateofJoining.slice(0, 10) : ""}
              onChange={(e) => onchange(e, "professionalDetails")}
              disabled={feildsDisabled}
              className={`${
                !state.professionalDetails.dateofJoining &&
                 isfilled &&
                 "border border-red-500"
               }`}
            />

            <TextField
              label={"Probation Period"}
              type={"number"}
              isMandatory={true}
              name={"probationPeriod"}
              value={state.professionalDetails.probationPeriod}
              onChange={(e) => onchange(e, "professionalDetails")}
              disabled={feildsDisabled}
              min={0}
              className={`${
                !state.professionalDetails.probationPeriod &&
                 isfilled &&
                 "border border-red-500"
               }`}
            />

            <TextField
              label={"Cofirmation Date"}
              type={"date"}
              isMandatory={true}
              name={"confirmationDate"}
              value={state.professionalDetails.confirmationDate ? state.professionalDetails.confirmationDate.slice(0, 10) : ""}
              onChange={(e) => onchange(e, "professionalDetails")}
              disabled={feildsDisabled}
              className={`${
                !state.professionalDetails.confirmationDate &&
                 isfilled &&
                 "border border-red-500"
               }`}
            />

            <TextField
              label={"Notice Period"}
              type={"number"}
              isMandatory={true}
              name={"noticePeriod"}
              value={state.professionalDetails.noticePeriod}
              onChange={(e) => onchange(e, "professionalDetails")}
              disabled={feildsDisabled}
              min={0}
              className={`${
                !state.professionalDetails.noticePeriod &&
                 isfilled &&
                 "border border-red-500"
               }`}
            />

            <Select
              label={"Shift"}
              isMandatory={true}
              name={"shift"}
              value={state.professionalDetails.shift}
              onChange={(e) => onchange(e, "professionalDetails")}
              disabled={feildsDisabled}
              options={[
                { value: "GENERAL_SHIFT_1", label: "GENERAL SHIFT 1" },
                { value: "GENERAL_SHIFT_2", label: "GENERAL SHIFT 2" },
                { value: "NIGHT_SHIFT", label: "NIGHT SHIFT" },
              ]}
              className={`${
               !state.professionalDetails.shift &&
                isfilled &&
                "border border-red-500"
              }`}
            />

            <TextField
              label={"Total Experiance"}
              type={"number"}
              isMandatory={false}
              name={"totalExperience"}
              value={state.professionalDetails.totalExperience}
              onChange={(e) => onchange(e, "professionalDetails")}
              disabled={feildsDisabled}
              min={0}
            />

            <TextField
              label={"Curent Company Experiance"}
              type={"number"}
              isMandatory={false}
              name={"currentCompanyExperience"}
              value={
                state.professionalDetails.currentCompanyExperience
              }
              onChange={(e) => onchange(e, "professionalDetails")}
              disabled={feildsDisabled}
              min={0}
            />

            <TextField
              label={"Reffered By"}
              type={"text"}
              isMandatory={false}
              name={"referedBy"}
              value={
                state.referedBy
              }
              onChange={(e) => onchange(e, "professionalDetails")}
              disabled={feildsDisabled}
            />
          </div>
        </div>
        </>
  )
}

export default UsaProfessionalDetails